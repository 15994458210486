import {Component, ElementRef, Input, OnInit, OnChanges} from '@angular/core';

@Component({
  selector: 'avatar',
  template: `
            <div *ngIf="props" [style.background-color]="props.background" [style.width] = "props.size" [style.line-height]='props.lineheight' [style.height] = 'props.size' [style.font-size] = 'props.fontSize'  [style.border-radius] = 'props.borderradius' [style.text-align] ="props.textalign"> 
            <div [style.color]='fontColor'>{{props.letter}}</div>
            </div>
            `,
})

export class LetterAvatarDirective implements OnInit{
  @Input('text') text: any;
  @Input('customeSize') customeSize: number;
  fontSize: number = 49;
  padding: number = 28;
  letter: string = "?";
  size: number = 36;
  fontColor: string = '#FFFFFF';
  props: Object = null;
  private _el: HTMLElement;

  constructor(el: ElementRef) {
    this._el = el.nativeElement;
  }
  test() {
    this.generateLetter();
  }
  generateLetter() {
    if (!this.text) {
      this.text = '';
    }
    var letter = this.text.substr(0, 1).toUpperCase();
    if(this.customeSize){
      this.size = this.customeSize;
    }
    this.fontSize = (39 *  this.size) / 100;
    this.padding = (28 *  this.size) / 100;
    this.letter = letter;
    this.props = new Object();
    this.props['size'] = this.size +'px';
    this.props['lineheight'] =this.size + 'px';
    this.props['letter'] = letter;
    this.props['fontSize'] = this.fontSize+ 'px';
    this.props['borderradius'] = '50%';
    this.props['textalign'] = 'center';
    this.props['background'] = this.colorize(letter); 
 
  };
   ngOnInit() {
    this.generateLetter();
  }

  colorize(str) {
    for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash));
    var color = Math.floor(Math.abs((Math.sin(hash) * 10000) % 1 * 16777216)).toString(16);
    var generateColor = '#' + Array(6 - color.length + 1).join('0') + color;
    if( generateColor == '#c8d8ea'){
      return '#ff9933';
    }
    if(generateColor == '#d0fc94'){
      return '#ff264a';
      
    }
    if(generateColor == '#e2f43c'){
      return '#166a77';
    }
    else{
      return '#' + Array(6 - color.length + 1).join('0') + color;
    }
}

 
}