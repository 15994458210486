import {Component, TemplateRef} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserListService} from './user-list.service';
import { Observable} from 'rxjs';
import * as _ from "lodash";
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { map, startWith} from 'rxjs/operators';
import {Message} from 'primeng/api';
import {utils, writeFile} from 'xlsx';
import {BsModalService} from 'ngx-bootstrap';
import {InvoiceModalComponent} from '../common/invoice/invoice-modal.component';


@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent{
    allRoles: any = [
        {
            name: "Sales", value: 2
        },
        {
            name: "Sourcing", value: 3
        },
        {
            name: "Operations", value: 4
        },
        {
            name: "Accounts", value: 5
        },
        {
            name: "Customer", value: 6
        },
        {
            name: "Transporter", value: 7
        },
        {
            name: "Broker", value: 8
        }
    ]
    customer;
    customerId;
    userDetail;
     customerOrders = {
        orders:[],
        total_orders :0,
        total_payment:0,
        average_actual_credit_days:0,
        average_invoice_credit_days:0

    };
    salesPersonList = [];
    order = false;
    detail = true;
    isSelected = false;
    selectedItems: any = [];
    successMsg: Message[] = [];
    modalRef;
    invoiceDetail;
    selectInvoiceList
    select;
    invoiceId;
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    constructor(private router: Router,
                private route: ActivatedRoute, private service: UserListService, private modalService: BsModalService){
       this.route.params.subscribe(
           res => {
               console.log('routes res',res);
               this.customerId = res.id;
           },err => {
               console.log('err',err);
           }
       )
   }

   ngOnInit(){
       this.getSalesPersonList();
       this.getCustomer();
       this.userOrders();
   }


    getCustomer(){
       let that = this;
        this.customer =  new FormGroup( {
            _id: new FormControl(),
            name: new FormControl(),
            phone: new FormControl(),
            email: new FormControl(),
            role: new FormControl(),
            company: new FormControl(),
            gst: new FormControl(),
            gst_paid_reverse_charge: new FormControl(),
            address: new FormControl(),
            payment_terms: new FormControl(''),
            sales_person: new FormControl(''),
            consigner : new FormArray([
            ]),
            consignee: new FormArray([
            ]),

        });
        this.service.getUser(this.user.token, this.customerId).subscribe(res =>{
            if(res.response){
                console.log(res);
               that.userDetail = res.data;
                if(that.userDetail.consigner && that.userDetail.consigner.length){
                    let count = that.userDetail.consigner.length;
                    while(count>0){
                        that.addConsigner();
                        count --;
                    }
                }
                if(that.userDetail.consignee && that.userDetail.consignee.length) {
                    let count = that.userDetail.consignee.length;
                    while (count > 0) {
                        that.addConsignee();
                        count--;
                    }
                }
                that.customer.patchValue({
                    _id: that.userDetail._id,
                    name: that.userDetail.name,
                    phone: that.userDetail.phone,
                    email: that.userDetail.email,
                    role: that.userDetail.role,
                    company: that.userDetail.company,
                    gst: that.userDetail.gst,
                    gst_paid_reverse_charge: that.userDetail.gst_paid_reverse_charge,
                    address: that.userDetail.address,
                    payment_terms: that.userDetail.payment_terms ? that.userDetail.payment_terms : null,
                    sales_person: that.userDetail.sales_person ? that.userDetail.sales_person : null,
                    consigner : that.userDetail.consigner,
                    consignee:  that.userDetail.consignee,
                });
            }
        },error1 => {
            console.log(error1);
        })


    }

    addConsigner(){
        let items =  this.customer.get('consigner') as FormArray;
        items.push(this.newItem());
    }
    removeConsigner(index){
        let items =  this.customer.get('consigner') as FormArray;
        items.removeAt(index)
    }


    addConsignee(){
        let items =  this.customer.get('consignee') as FormArray;
        items.push(this.newItem());
    }
    removeConsignee(index){
        let items =  this.customer.get('consignee') as FormArray;
        items.removeAt(index);
    }

    newItem() {
        return new FormGroup({
            name: new FormControl(''),
            address: new FormControl(''),
            gst: new FormControl('')

        });
    }

    userOrders(){
       let that = this;
       this.service.getUserOrders(this.user.token, this.customerId).subscribe(
           res =>{
               that.customerOrders = res['data'];

       })
    }

    updateUser(){
        console.log("here");
        this.service.userUpdate(this.user.token, this.customer.value).subscribe(
            res =>{
                if (res.response) {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message});
                } else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
                }
            },
            error1 => {
                this.successMsg = [];
                this.successMsg.push({severity: 'error', summary: 'Error Message', detail: error1.message});
            }
        )
    }
    onCheckboxClick(event, orderId) {
        if (event) {
            this.selectedItems.push(orderId);
            }
         else {
            this.selectedItems = this.selectedItems.filter(data => data !== orderId);
        }
        console.log(this.selectedItems);
    }

    createInvoice(){
     let that = this;
     that.service.saveInvoice(this.user.token,{customer: this.customerId,orders: this.selectedItems}).subscribe(res =>{
         console.log(res);
         if(res.response){
             that.selectedItems = [];
             this.successMsg = [];
             this.successMsg.push({severity: 'success', summary: 'Success Message', detail: 'Invoice created'});
             this.userOrders();
         }
         else{
             this.successMsg = [];
             this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
         }
     },error1 =>{
         console.log(error1);
     })

    }
    dateFormating(dateValue){
        if(dateValue){
            let date = new Date(dateValue);
            return date.getDate() +'.' +(date.getMonth()+1) + '.'  +date.getFullYear()
        }

        return '';

    }
    createMIS(){
        let that = this;
        this.service.misDetail(this.user.token, {orderIds:this.selectedItems}).subscribe(res => {
          if (res.response) {
                  that.selectedItems = [];
                  console.log(res);
                  let count = 1;
                  let items = [];
                  _.each(res.data,function (item) {
                      let intransit = item.order_mis_id.in_transit;
                      const mis = {
                          'SR': count ,
                          'CUSTOMER NAME': item.customer && item.customer.customer_id && item.customer.customer_id.company,
                          'DATE':that.dateFormating(item.order_date),
                          'LR.NO':item.order_number,
                          'ORIGIN': item.customer && item.customer.from ,
                          'DESTINATION': item.customer && item.customer.to,
                          'VEH REPORTING DATE': item.order_mis_id && that.dateFormating(item.order_mis_id.reporting_date),
                          'VEH RELEASE DATE': item.order_mis_id && that.dateFormating(item.order_mis_id.loading_date),
                          'EXPECTED DELY DATE': item.order_mis_id && that.dateFormating(item.order_mis_id.expected_reporting_date),
                          'VEHICLE TYPE': item.transporter && item.transporter.vehicle_details && item.transporter.vehicle_details.vehicle_type,
                          'VEHICLE NO.': item.transporter && item.transporter.vehicle_details && item.transporter.vehicle_details.vehicle_number,
                          'REPORTING DATE': item.order_mis_id && that.dateFormating(item.order_mis_id.actual_reporting_date),
                          'DAY 1':intransit && intransit.length>0?intransit[0].status:null,
                          'DAY 2':intransit && intransit.length>1?intransit[1].status:null,
                          'DAY 3':intransit && intransit.length>2?intransit[2].status:null,
                          'DAY 4':intransit && intransit.length>3?intransit[3].status:null,
                          'DAY 5':intransit && intransit.length>4?intransit[4].status:null,
                          'DAY 6':intransit && intransit.length>5?intransit[5].status:null,
                          'DAY 7':intransit && intransit.length>6?intransit[6].status:null,
                          'DAY 8':intransit && intransit.length>7?intransit[7].status:null,
                          'DAY 9':intransit && intransit.length>8?intransit[8].status:null,
                          'DELIVERY DATE': item.order_mis_id && that.dateFormating(item.order_mis_id.unloading_date),
                          'DETENTION': null,
                          'REMARKS': ''
                      };
                      items.push(mis);
                      count ++;
                  })
                  if (items && items.length) {
                      const wb = utils.book_new();
                      const ws = utils.aoa_to_sheet([['','','','','DELAYED','DELIVERED','DETAINED','INTRANSIT']])
                      utils.sheet_add_json(ws,items,{dateNF: 'MM.DD.YYYY',origin:'A3'});
                      const wscols = [{wch:5},
                          {wch:25}, {wch:20}, {wch:20}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:20},
                          {wch:20}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:25}, {wch:20},
                          {wch:25}, {wch:25}, {wch:20}

                      ];
                      const wsrows = [];
                      ws['!cols'] = wscols;
                      utils.book_append_sheet(wb, ws );
                      writeFile(wb, items[0]['CUSTOMER NAME'] + '.xlsx');
                      this.successMsg = [];
                      this.successMsg.push({severity: 'success', summary: 'Success Message', detail: 'Mis downloaded'});

                  }
                  else{
                      this.successMsg = [];
                      this.successMsg.push({severity: 'error', summary: 'Error Message', detail: 'Mis detail missing'});
                  }
          } else {
              this.successMsg = [];
              this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
          }
      },error1 => {

      });
    }

    orderDetail(orderId){
        this.router.navigate(['order/detail/'+ orderId])
    }
    showInvoice(invoiceId){
        let that = this;
        this.service.getInvoiceDetail(this.user.token, invoiceId).subscribe(
            res =>{
                let invoiceDetail = res.data;
                const initialState = {
                    invoiceId: invoiceId,
                    invoiceDetail : invoiceDetail
                };
                console.log(initialState);
                let modalref = this.modalService.show(InvoiceModalComponent,{class:'modal-lg',initialState});
            }
        )

    }

    back(){
        this.router.navigate(['user-list'])
    }
    selectInvoiceModal(template){
        let that = this;
        this.service.invoiceList(this.user.token,this.customerId).subscribe(
            res =>{
                that.selectInvoiceList =  res.data;
                this.modalRef = this.modalService.show(
                    template, {class: 'modal-md'}
                );
            }
        )
    }

    addOrdersToInvoice(){
        let that = this;
        this.service.addOrdersToinvoice(this.user.token,{invoice_id: this.invoiceId, orders:this.selectedItems}).subscribe(
            res =>{
                if(res.response) {
                    that.selectedItems = [];
                    this.userOrders();
                    that.modalRef.hide();
                    that.successMsg = [];
                    that.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message})
                }
            }
        )

    }

    getSalesPersonList(){
        this.service.salesPersonList(this.user.token).subscribe(res =>{
            console.log(res);
            if(res.response){
                this.salesPersonList = res.data;
            }
        },error1 => {
            console.log(error1);
        })
    }


}
