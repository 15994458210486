import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
const url = '/api/v1/';

@Injectable()
export class UserAddressService {

  constructor(private http: Http) { }

  getUserAddress(token: string,responseData: any) {
    console.log(".....responseData",responseData);
    return this.http.post(url+'user/get/addresses?token='+token, responseData)
      .map((response: Response) =>response.json());
  }
   getQuery(token: string,queryId: any) {
    return this.http.post(url+'tp/queries/transporters/details?token='+token, queryId)
      .map((response: Response) =>response.json());
  }

}
