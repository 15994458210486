import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
const url = '/api/v1/';


@Injectable()
export class ListVehicleService {

    
    constructor(private http: Http) {}


getVehicleTypes() {
    return this.http.get(url+'transporter/vehicle/types')
        .map((response: Response) =>response.json());
}

getVehicles(token,body){
        return this.http.get(url + 'search/vehicles?token='+token+'&from='+body.from+'&to='+body.to+'&vehicleType='+body.vehicleType+'&latFrom='+body.latFrom + '&longFrom='+body.longFrom)
            .map((response: Response) =>response.json());
}
}
