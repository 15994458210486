import {Component, EventEmitter, Output, TemplateRef} from '@angular/core';
import {AccountsService} from '../accounts.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {TdsPaymentsModalComponent} from './tds-payments.modal.component';
import {PlatformLocation} from '@angular/common';

@Component({
    selector:'transporter-paid-payments',
    templateUrl:'./transporter-paid-payments.component.html',
    styleUrls:['./transporter-payments.css']
})
export class TransporterPaidPaymentsComponent {
        payments = {
            totalCount:null,
            payments:[]
        }
    searches = {
        startDate:null,
        endDate:null,
        customerName:null,
        orderNumber:null
    }
     body ={
        payment_types:[3]
    }
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    modalRef: BsModalRef;
    selectedMonth:number = 0;
    selectedYear:number = 0;
    size = 10;
    page = 1;
    @Output() downloadResponse = new EventEmitter<any>();
    constructor(private service:AccountsService,private modalService: BsModalService,private location: PlatformLocation){
        let that =this;
        location.onPopState(() => that.modalRef.hide());
    }

    monthArray = [
        {Id:1, Value:'January'},{Id:2, Value:'February'},{Id:3, Value:'March'},{Id:4, Value:'April'},{Id:5, Value:'May'},{Id:6, Value:'June'},
        {Id:7, Value:'July'},{Id:8, Value:'August'},{Id:9, Value:'Septembe'},{Id:10, Value:'October'},{Id:11, Value:'November'},{Id:12, Value:'December'}
    ]
    yearArray = [{Id:2018, Value:'2018'},{Id:2019, Value:'2019'},{Id:2020, Value:'2020'},{Id:2021, Value:'2021'},{Id:2022, Value:'2022'},
        {Id:2023, Value:'2023'},{Id:2024, Value:'2024'},{Id:2025, Value:'2025'}]

    ngOnInit(){
      this.getPayments();

    }
    getPayments(){
        let promise = this.service.transporterPayments(this.user.token,this.body,this.searchString()).subscribe(res =>{
            if(res.response){
                console.log(res);
                this.payments = res.data;

            }
        })
    }

    updatePaymentStatus(paymentId,paymentStatus){
        let body = {
            _id:paymentId,
            payment_status:paymentStatus
        }
        let promise = this.service.updatePaymentStatus(this.user.token,body).subscribe(res =>{
            if(res.response){
                console.log(res);

            }
        })
    }

    tdsByMonth(template: TemplateRef<any>){
        this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray modal-md' })
        );
    }

    cancel(){
       this.modalRef.hide();
    }


    getTdsList(month,year){
        this.modalRef.hide();
        this.service.getTdsPaymentList(this.user.token,month,year).subscribe(res =>{
            if(res.response){
                const initialState = {
                    payments: res['data'].payments,
                    totalTds:res['data'].totalTds,
                    month:(this.monthArray.find(item =>item.Id==month)).Value,
                    year:(this.yearArray.find(item =>item.Id==year)).Value
                };
                let modalReference = this.modalService.show(TdsPaymentsModalComponent,{class:'modal-lg',initialState});
                modalReference.content.onDownload.subscribe(result => {
                    if(result){
                        this.downloadResponse.emit({severity: 'success', summary: 'Success Message', detail: "Tds File downloaded"});
                    }
                    else{

                    }
                })
            }
        })
    }

    filter(startDate?,endDate?) {
        let that = this;
        if (startDate) {
            that.searches.startDate = startDate;
            that.searches.startDate.setHours(0, 0, 0, 0);
        }
        if (endDate) {
            that.searches.endDate = endDate;
            that.searches.endDate.setHours(23, 59, 59, 0);
        }
        this.getPayments();
    }
    searchString(){
        let searchString = '&size='+this.size + '&page='+this.page;
        for(let key in this.searches){
            if(this.searches[key]!=null){
                searchString = searchString + '&'+key +'=' + this.searches[key]
            }
        }
        return searchString
    }

    clearFilter(){
        this.searches.startDate = null;
        this.searches.endDate = null;
        this.getPayments();
    }

    filterListKeyUp(){
        this.getPayments();
    }

    filterListKeyDown(){
        let that = this;
        setTimeout(function() {
            that.getPayments();
        },1);


    }

    pageChangeApi(page){
      this.page = page;
      this.getPayments()
    }
}
