import {Component, OnInit} from '@angular/core';
import {OrderHistoryService} from '../../order-history/order-history.service';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import {BsModalRef} from 'ngx-bootstrap';
import { PlatformLocation } from '@angular/common';

@Component({
    selector: 'invoice-modal',
    templateUrl: './invoice-modal.component.html',
    styleUrls: ['./invoice-modal.component.css']
})
export class InvoiceModalComponent implements OnInit{
    invoiceDetail:any;
    invoiceId:any;
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    constructor(private service: OrderHistoryService,private _bsModalRef: BsModalRef,private location: PlatformLocation){
        location.onPopState(() => this._bsModalRef.hide());
    }

    ngOnInit(){
    }

    inWords (num) {
        let a = ['','ONE ','TWO ','THREE ','FOUR ', 'FIVE ','SIX ','SEVEN ','EIGHT ','NINE ','TEN ','ELEVEN ','TWELVE ','THIRTEEN ','FOURTEEN ','FIFTEEN ','SIXTEEN ','SEVENTEEN ','EIGHTEEN ','NINETEEN '];
        let b = ['', '', 'TWENTY','THIRTY','FORTY','FIFTY', 'SIXTY','SEVENTY','EIGHTY','NINETY'];
        let n =[];
        if ((num = num.toString()).length > 9) return 'overflow';
        n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; let str = '';
        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'CRORE ' : '';
        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'LAKH ' : '';
        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'THOUSAND ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'HUNDRED ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Only ' : 'ONLY';
        return str;
    }

    downloadInvoice(){
        let that = this;
        let promise =  html2canvas(document.getElementById('invoiceDataForPdf'),{scale:2, backgroundColor:'#ffffff'});
        promise.then(function (canvas) {
            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jsPDF('p', 'mm', 'a4',true);
            var imgWidth = 208;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST')
            pdf.save(that.invoiceDetail.invoice_sequence+'-invoice'+'.pdf');
        });


    }

    cancel(){
        this._bsModalRef.hide();
    }

}
