import {Component, TemplateRef,HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {OrderHistoryService} from './order-history.service';
import {  utils, write, WorkBook, writeFile } from 'xlsx';
import * as _ from "lodash";
import {Alert} from 'selenium-webdriver';
import {Message} from 'primeng/api';
import {BsModalService} from 'ngx-bootstrap';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import {InvoiceModalComponent} from '../common/invoice/invoice-modal.component';
import {LrModalComponent} from '../common/lr/lr-modal.component';
import {OrderDetailService} from '../order-detail/order-detail.service';
import {UpdateVehicleStatusComponent} from './update-vehicle-status.component';

@Component({
    selector: 'order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.css']
})

export class OrderHistoryComponent {

    user: any = JSON.parse(localStorage.getItem('currentUser'));
    orderList = [];
    orderSummary = {
        total_orders:null,
        total_amount:null,
        margin:null,
        margin_percent:null,
        average_actual_credit_days:null,
        average_invoice_credit_days:null
    }
    queryObject: {};
    lrDetail:any = {
        order_number:null,
        order_date:null,
        invoice_number:null,
        invoice_date:null,
        consignee:null,
        consigner:null,
        vehicle_details:null,
        from:null,
        to:null
    };
    modalRef;
    page = 1;
    size = 10;
    entry = [5,10,15,25];

    successMsg: Message[] = [];
    search = {
        customer_name:null,
        transporter_name:null,
        order_number:null,
        invoice_sequence:null,
        margin:null,
        vehicle_number:null
    }
    cancelorder  = {
        order_id:null,
        index:null
    }
    filters = {
        start_date:null,
        end_date:null,
        vehicle_status:null,
        pod_status:null

    }
orderListKeyDown = []


constructor( private router: Router, private service: OrderHistoryService, private orderDetailService: OrderDetailService,
             private modalService: BsModalService) {}


    ngOnInit() {
        this.page = 1;
        this.getorderList(this.page,this.size,this.filters,this.search);

    }

    editOrder(id,deleted) {
        this.router.navigate(['order/detail/', id]);
    }
    transporterDetail(id){
        this.router.navigate(['admin/transporters/'+id+'/details'])
    }
    customerDetail(customerId){
        this.router.navigate(['admin/user/' + customerId + '/details'])
    }

    getorderList(page?,size?,filter?,search?) {
        let that = this;
        let body = {
            filter:filter,
            search:search
        }
        this.service.getOrderList(this.user.token,page,size,body).subscribe(
            res => {

                that.orderSummary.total_orders = res['data'].total_orders;
                that.orderSummary.total_amount = res['data'].total_amount;
                that.orderSummary.margin = res['data'].margin;
                that.orderSummary.margin_percent = res['data'].margin_percent;
                that.orderSummary.average_actual_credit_days = res['data'].average_actual_credit_days;
                that.orderSummary.average_invoice_credit_days = res['data'].average_invoice_credit_days
                that.orderList  = res['data'].orders
            }, error1 => {
                console.log(error1);
            });
    }

    showInvoice(invoiceId) {
        let that = this;
        this.service.getInvoiceDetail(this.user.token, invoiceId).subscribe(
        res =>{
            let invoiceDetail = res.data;
            const initialState = {
                invoiceId: invoiceId,
                invoiceDetail : invoiceDetail
            };
            console.log(initialState);
            let modalref = this.modalService.show(InvoiceModalComponent,{class:'modal-lg',initialState});
        })
    }

    dateFormating(dateValue){
        if(dateValue){
            let date = new Date(dateValue);
            return date.getFullYear()+ '.' + (date.getMonth()+1) + '.'+date.getDate();
        }

        return '';

    }



    filterListKeyUp(){
        this.getorderList(this.page,this.size,this.filters,this.search)
        // this.orderList = this.filterOrderList(this.orderList,this.search);
    }

    filterListKeyDown(){
        let that = this;
        console.log(this.search);
        setTimeout(function() {
            that.getorderList(that.page,that.size,that.filters,that.search)
        },1);


    }



    showLR(orderId){
        let that =  this;
        this.orderDetailService.getOrderDetail(this.user.token,orderId).subscribe(res =>{
            let order = res.data.order;
            console.log(res.data);
            that.lrDetail.order_number = order.order_number,
                that.lrDetail.order_date = order.order_date,
                that.lrDetail.from = order.customer && order.customer.from;
            that.lrDetail.to = order.customer && order.customer.to;
            that.lrDetail.consigner = order.customer && order.customer.consigner
            that.lrDetail.consignee = order.customer && order.customer.consignee
            that.lrDetail.vehicle_details = order.transporter && order.transporter.vehicle_details
            const initialState = {
                lrDetail : that.lrDetail
            };
            let modalRef = that.modalService.show(LrModalComponent,{class:'modal-lg',initialState})
        },error1 => {

        })



    }

    margin(customer,transporter){
        let amount = customer - transporter;
        if(isNaN(amount)){
            return null;
        }
        else {
            return amount;
        }
    }

    confirmationDialog(template,orderId,index){
        this.cancelorder.order_id = orderId;
        this.cancelorder.index = index
        this.modalRef = this.modalService.show(
            template, {class: 'modal-md'}
        );
    }
    cancelOrder(){
        let that = this;
        this.service.cancelOrderById(this.user.token, this.cancelorder.order_id).subscribe(
            res=>{
                console.log(res);
                if(res.response){
                    this.modalRef.hide();
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success', summary: 'Success Message', detail: 'Order Cancelled'});
                    that.orderList[that.cancelorder.index].is_deleted = true;

                }
            }
        )
    }
     filterOrderList(orderList,filters){
        let filterKeys = Object.keys(filters);
        return orderList.filter(function (eachObj) {
            return filterKeys.every(function (eachKey) {
                if (!filters[eachKey]) {
                    return true;
                }
                if(!eachObj[eachKey]){
                    return false;
                }
                let st = eachObj[eachKey]
                st = st+'';
                return st.match(new RegExp(filters[eachKey],'i'));
            });
        })
    }

    filter(startDate?,endDate?){
        let that = this;
        if(startDate) {
            that.filters.start_date = startDate;
            that.filters.start_date.setHours(0, 0, 0, 0);
        }
        if(endDate) {
            that.filters.end_date = endDate;
            that.filters.end_date.setHours(23, 59, 59, 0);
        }
        this.getorderList(this.page,this.size,this.filters,this.search)
    }

    clearFilter(){
      this.filters.start_date = null;
      this.filters.end_date = null;
      this.getorderList(this.page,this.size,this.filters,this.search);
      // this.orderList = this.filterOrderList(this.orderListKeyDown,this.search)
    }

    pageChangeApi(page){
        this.page = page;
        this.getorderList(this.page,this.size,this.filters,this.search)
    }
    tableSizeChange(size){
        this.size  = size;
        this.getorderList(this.page,this.size,this.filters,this.search)
    }

    podStatus(podValue){

        switch (podValue) {
            case 21:
                return 'With Transporter'
            case 22:
                return 'With Driver'
            case 23:
                return 'Couriered'
            case 24:
                return 'Received'
            default:
                return '-'
        }
    }

    vehicleStatusModal(misId,index){
        if(!misId)
            return;
        let initialState = {
            misId:misId
        }
        let modalRef = this.modalService.show(UpdateVehicleStatusComponent,{initialState});
        modalRef.content.onClose.subscribe(result =>{
            this.orderList[index].vehicle_present_day_status = result.status;
            this.orderList[index].vehicle_present_day_status_date = result.date;
            this.successMsg = [];
            this.successMsg.push({severity: 'success', summary: 'Success Message', detail: result.message});
        },error =>{

        })

     }

    updatePodStatus(misId,podStatus){
        let body = {
            _id:misId,
            pod_status: podStatus
        }
        this.service.saveMis(this.user.token,body).subscribe(
            res =>{
                this.successMsg = [];
                this.successMsg.push({severity: 'success', summary: 'Success Message', detail: 'Pod Status updated'});
            },error1 => {

            })
    }

}
