import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
 const url = '/api/v1/';

@Injectable()
export class HistoryService {
  constructor(private http: Http) { }

  getHistory(token: string){
    let headers = new Headers();
    return this.http.get(url+'user/queries/history?token='+token).map((response: Response) =>response.json());
  }
  sendReply(replyData: any, token: string){
      return this.http.post(url+'user/send/rates?token='+token, replyData).map((response: Response) =>response.json());
  }
  sendMessageToTransporters(list: any,token: string){
    console.log('transportesr list',list);
    return this.http.post(url+'tp/send/queries?token='+token,list).map((response: Response) =>response.json());
  }
  getTransporters(token: string,queryid: any){
    return this.http.post(url+'tp/transporterslist?token='+token,queryid).map((response: Response) =>response.json());
  }
  getUserResponse(userResponseData: any, token: string){
      return this.http.post(url+'user/get/response?token='+token, userResponseData).map((response: Response) =>response.json());
  }
  getUserAddress(token: string,responseData: any) {
    console.log(".....responseData",responseData);
    return this.http.post(url+'user/get/addresses?token='+token, responseData)
      .map((response: Response) =>response.json());
  }
  getUserDetail(token, id){
      console.log(id);
      return this.http.get(url+'admin/user/detail?token=' +token + '&id=' +id)
          .map((response: Response) =>response.json());
  }

}
