import {Component, OnInit, TemplateRef} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
//import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';


@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail-modal.component.html',
})

export class UserDetailModalComponent implements OnInit {
    modalRef: BsModalService;
    user;
   constructor(public bsModalRef: BsModalService){

   }

    ngOnInit() {
        console.log("suhel");
    }

}
