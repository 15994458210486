import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
	selector: '[outsideClick]'
})

export class OutsideClickDirective{
		constructor(private elementRef:ElementRef){}
		@Output() outsideClick= new EventEmitter();
		@HostListener('document:click',['$event.target']) public onClick(targetElement){
			const containedElement = this.elementRef.nativeElement.contains(targetElement);
			if(targetElement.id != 'bars' && !containedElement){
				this.outsideClick.emit(null);	
			}
		}

}