import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
const url = '/api/v1/';

@Injectable()
export class AddTransportersService {

  constructor(private http: Http) { }

  createTransporters( token: string,transportersData) {
  	console.log(".....token",token,transportersData);
  	return this.http.post(url+'admin/transporter?token='+token,transportersData)
  	.map((response: Response) =>response.json());
  }

}
