import {Component} from '@angular/core';
import {Message} from 'primeng/api';
import { Router } from '@angular/router';

@Component({
    selector:'transporter-payments',
    templateUrl:'transporter-payments.html',
    styleUrls: ['transporter-payments.css']
})
export class TransporterPayments {
    successMsg: Message[] = [];
    constructor(private router: Router){
        router.navigate(['/admin/accounts/transporter-payments/unpaid-payments']);
    }

    showResponse(response){
        this.successMsg = [];
        this.successMsg.push(response);
    }
}
