import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-response-back',
  templateUrl: './response-back.component.html',
  styleUrls: ['./response-back.component.css']
})
export class ResponseBackComponent implements OnInit {

   path: any;
  constructor(private route:ActivatedRoute) { 
  	this.route.queryParams.subscribe(
  		res =>{
                console.log('id',res.query_id);
                this.path = res.path;
            },err =>{
                console.log('err',err);
            }
        )
  }

  ngOnInit() {
  }

}
