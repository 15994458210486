import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
 const url = '/api/v1/';

@Injectable()
export class NewRequestService {

  constructor(private http: Http) { }

  postRequest(requestData: any, token: string) {
    console.log(".....token",token);
    console.log(requestData);
    return this.http.post(url+'search/transporters?token='+token, requestData)
      .map((response: Response) =>response.json());
  }
  sendMessageToTransporters(list: any,token: string){
    console.log('transportesr list',list);
    return this.http.post(url+'tp/send/queries?token='+token,list).map((response: Response) =>response.json());
  }
 
  getTransporters(token: string, queryid: any){
    return this.http.post(url+'tp/transporterslist?token='+token,queryid).map((response: Response) =>response.json());
  }

  getCities(){
    return this.http.get(url+'transporter/cities').map((response: Response) =>response.json());
  }
  getVehicles(){
    return this.http.get(url+'transporter/vehicle/types').map((response: Response) =>response.json());
  }

    listUsers( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/user/list?token='+token)
            .map((response: Response) =>response.json());
    }

    addCustomer( token: string, body: any) {
        console.log(".....token",token);
        return this.http.post(url+'admin/customer?token='+token, body)
            .map((response: Response) =>response.json());
    }


}
