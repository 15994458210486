import {Component, EventEmitter} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
    selector: 'app-material-description',
    templateUrl: './material-description-modal.component.html',
    styleUrls: ['./material-description-modal.component.css']
})

export class MaterialDescriptionModalComponent {
    selectedmaterial;
    public event: EventEmitter<any> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef) {

    }

    selectedMaterial(material) {
        this.selectedmaterial = material;
        this.event.emit(material);

    }
}