import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
const url = '/api/v1/';

@Injectable()
export class TransportersDetailsService {

	constructor(private http: Http) { }

	listTransporters( token: string) {
		console.log(".....token",token);
		return this.http.get(url+'admin/transporters?token='+token)
		.map((response: Response) =>response.json());
	}

	createRoutes(token: string, tranporterId, routeData) {
		console.log(".....token",token,routeData);
		return this.http.post(url+'admin/transporters/'+tranporterId+'/routes?token='+token,routeData)
		.map((response: Response) =>response.json());
	}

	getCities(){
		return this.http.get(url+'transporter/cities').map((response: Response) =>response.json());
	}
	getVehicles(){
		return this.http.get(url+'transporter/vehicle/types').map((response: Response) =>response.json());
	}
	listRoutes(token: string, tranporterId) {
		return this.http.get(url+'admin/transporters/'+tranporterId+'/routes?token='+token)
		.map((response: Response) =>response.json());
	}

	getTransporter(token: string, tranporterId) {
		return this.http.get(url+'admin/transporters/'+tranporterId+'?token='+token)
		.map((response: Response) =>response.json());
	}
	updateTransporter(token: string, tranporterId,transporter) {
		return this.http.put(url+'admin/transporters/'+tranporterId+'?token='+token,transporter)
		.map((response: Response) =>response.json());
	}
	deleteRoute(token: string, tranporterId) {
		return this.http.delete(url+'transporter/routes/'+tranporterId+'?token='+token)
		.map((response: Response) =>response.json());
	}
	updateRoute(token: string, tranporterId, data) {
		return this.http.put(url+'admin/transporters/'+tranporterId+'/routes/'+data.id+'?token='+token,data)
		.map((response: Response) =>response.json());
	}
	uploadTransporterDocs(token,body){
        return this.http.post(url+'transporter/upload/docs?token='+token,body)
            .map((response: Response) =>response.json());
	}
    listOrders(token: string, tranporterId) {
        return this.http.get(url+'transporter/orders/' + tranporterId + '?token='+token)
            .map((response: Response) =>response.json());
    }
}
