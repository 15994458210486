import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message} from 'primeng/components/common/api';
import { MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { MessageService} from 'primeng/components/common/messageservice';
import { AppComponent } from '../../app.component';
import { NewRequestService }  from './new-request.service';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {VehicleTypeModalComponent} from './vehicle-type-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {UserDetailModalComponent} from '../history/user-detail-modal.component';
import {MaterialDescriptionModalComponent} from './material-description-modal.component';

@Component({
	selector: 'app-new-request',
	templateUrl: './new-request.component.html',
	styleUrls: ['./new-request.component.css']
})
export class NewRequestComponent implements OnInit {

	columnsForTransporters = ['reply','name','location','contact_person','contact_numbers'];
	fromOptions = [];
	vehicleOptions =[];
	toOptions =[];

	fromOption: Observable<string[]>;
	toOption: Observable<string[]>;
	VehiclesTypes: Observable<string[]>;

	requestData: any = {
		from: '',
		to: '',
		vehicle_type: '',
		date : '',
		material: '',
		no_of_vehicle : null
	};
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
    @ViewChild('datePicker') el: ElementRef;
    newCustomer = {
    	name: null,
		phone: null,
		password: null,
        confirmPassword: null,
		email: null,
		role: 6,
		company: null,
		gst: null,
		city: null
	};
	user: any = JSON.parse(localStorage.getItem('currentUser'));
	successMsg: Message[] = [];
	msg: any;
	errorMessage: any;
	errorMsg: any;
	isUserLoggedIn: boolean = false;
	showNotificationToLogin: boolean = false;
	blankResponse: boolean = false;
	showUserPopup: boolean = false;
	showAdminPopup: boolean = false;
	isSelectAll: boolean = false;
	selectedTransportersList: any = [];
	transportersList: any = [];
	message: string;
	transporters: any = [];
	current_query: any =  {};
	isAdmin: boolean = false;
	fromCity: any =  '';
	cityTo: any =  '';
	dataForTransporters : MatTableDataSource<any>;
	fromControl: FormControl = new FormControl();
	toControl: FormControl = new FormControl();
	vehicleType: FormControl = new FormControl();
	transportersDataObject: any = {
		query_id: '',
		transporters_list: []
	};
	minDate: Date;

	customerList  = [];
	customerId = null;
    modalRef;

    constructor(private router: Router,
		private appComp: AppComponent,
		private service: NewRequestService,private modalService: BsModalService) {
		if(this.user){

			if(this.user.role == 1){
				this.isAdmin = true;
			}
			this.minDate = new Date();

		}
	}

	ngOnInit() {
		console.log(this.user);
		console.log(this.el);
		this.isUserLoggedIn = false;
		if(this.user && JSON.parse(localStorage.getItem('query'))) {
            this.getCustomers();
			this.requestData = JSON.parse(localStorage.getItem('query'));
			console.log(localStorage.getItem('query'));
			this.saveRequest(this.requestData);
		}else{
			this.requestData = {
				from: '',
				to: '',
				vehicle_type: '',
				date : '',
				material: '',
				no_of_vehicle : null
			};
		}
	
		console.log('',this.VehiclesTypes); 
		this.getCities();
		this.getVehicles();

	}
	getToCity(event){
		console.log(event.city);
		console.log(event.state);
		console.log('event',event); 
		let cityObj = {
			city: event.city,
			state: event.state
		}
		console.log(cityObj);
		this.requestData.to = cityObj;
	}
	getFromCity(event){
		console.log('event',event);
        console.log(event.city);
        console.log(event.state);
		let cityObj = {
			city: event.city,
			state: event.state
		}
		this.requestData.from = cityObj;
	}
	getCities(){
		this.service.getCities().subscribe(
			res => {
				console.log('',res.data.cities);
				this.fromOptions = res.data.cities;
				this.toOptions = res.data.cities
				this.fromOption = this.fromControl.valueChanges
				.pipe(
					startWith(''),
					map(val => this.filterFrom(val))
					);
				this.toOption = this.toControl.valueChanges
				.pipe(
					startWith(''),
					map(val => this.filterTo(val))
					);
			},err => {
			}
			)		
	}
	getVehicles(){
		this.service.getVehicles().subscribe(
			res => {
				console.log('',res);
				this.vehicleOptions = res.data.vehicleOptions;
				this.VehiclesTypes = this.vehicleType.valueChanges
				.pipe(
					startWith(''),
					map(val => this.filterVehicle(val))
					); 
			},err => {
			}
			)		
	}
	filterFrom(val: string): string[]{
		return this.fromOptions.filter(option =>
			option.city.toLowerCase().includes(val.toLowerCase()));
	}
	filterTo(val: string): string[]{
		return this.toOptions.filter(option =>
			option.city.toLowerCase().includes(val.toLowerCase()));
	}
	filterVehicle(val: string): string[] {
		return this.vehicleOptions.filter(option =>
			option.label.toLowerCase().includes(val.toLowerCase()));	
	}
	loginToSubmitQuery(){
		this.showNotificationToLogin = false;
		this.router.navigate(['/signin']);
	}
	saveRequest(requestData: any){
		console.log('request data',this.user);
		if(this.customerId)
			this.requestData['customerId'] = this.customerId;
		if (this.user) {
			this.requestData.no_of_vehicle = 1;
			this.service.postRequest(this.requestData, this.user.token).subscribe(
				data => {
					console.log("...request data",data);
					if(data && data.data){
						this.current_query = data.data.current_query;
						this.transportersDataObject.query_id = this.current_query._id;
						if(this.user.role == 1){
							if (data.data.transporters.length == 0) {
								this.blankResponse = true;
							}else{
								let dataArray = [];
								data.data.transporters.forEach((item,index) =>{
								let obj = {
									_id: item.transporter._id,
									name:item.transporter.user.company,
									location: item.transporter.user.address,
									contact_person: item.transporter.user.name,
									contact_numbers: item.transporter.contact_numbers
	                                // vehicle_type: current_query.vehicle_type,
	                                // from : current_query.from,
	                                // to : current_query.to
	                            }
	                            dataArray.push(obj);
	                        })

							this.transporters = dataArray;
							this.dataForTransporters = new MatTableDataSource(dataArray);
							}							
						}							
					}
					else if(data.response && !data.data && this.user.role == 6) { 
						this.message = data.message;
					}

					localStorage.removeItem('query');
					 
					if(!this.isAdmin){
						this.appComp.cssClass = 'modal-open';
						this.showUserPopup = true;
					}
					else{
						this.appComp.cssClass = 'modal-open';
						this.showAdminPopup = true;
					}

				},
				error => {
					console.log(error);
					this.errorMessage = error.json().message;
					this.errorMsg = [];
					this.errorMsg = [{severity:'error',detail: this.errorMessage}];
				}
				) 
		}else{
			localStorage.setItem('query',JSON.stringify(this.requestData));
			this.appComp.cssClass = 'modal-open';
			this.showNotificationToLogin = true;
           // this.router.navigate(['/signin']);
       }
       
   	}
    
    sendMessageToTransporters(){
        this.transportersDataObject.transporters_list = this.transportersList;
        this.service.sendMessageToTransporters(this.transportersDataObject,this.user.token).subscribe(
            res => {
                console.log('res',res);
                if (res.response) { 
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
                } else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
                }
                this.closeModal();
            },err => {
                console.log('error',err);
            }
            )
    }

    selectTransporters(event,id){
        if (event.checked) { 
            this.transportersList.push(id);
        } else {
            let index = this.transportersList.findIndex(item => item == id);
            if (index != -1) { 
                this.transportersList.splice(index,1);
            }  
        }
        if (this.transportersList.length == 0) { 
            this.isSelectAll = false;
        }
        if (this.transportersList.length == this.transporters.length) {
            this.isSelectAll = true;
        }
        console.log('row details',this.transportersList);
    }
     selectAll(event) {
        this.transportersList = [];
        console.log('event',event);
        if (event.checked) { 

            this.transporters.forEach((item,index) => {
                console.log('transporters',this.transporters);
                    // let tp_index = this.datasourceTransportersArray.findIndex(function(selected_transporter){
                    //     return selected_transporter.id._id != item._id;
                    // })
                    // console.log(tp_index);
                    // if(tp_index > -1){
                    //     item.is_query_sent = true;
                    // }else{
                    //     this.transportersList.push(item._id)
                    // }
                    item.is_query_sent = true;
                    this.transportersList.push(item._id)
                })
            
        } else {
            // if (this.datasourceTransportersArray.length > 0) { 
            //     this.transporters.forEach((item,index) => {
            //         console.log('transporters',this.transporters);
            //         let tp_index = this.datasourceTransportersArray.findIndex(function(selected_transporter){
            //             return selected_transporter.id._id == item._id;
            //         })
            //         console.log(tp_index);
            //         if(tp_index > -1){
            //             item.is_query_sent = true;
            //         }
            //     })
            // } else {
            //     this.transporters.forEach((item,index) => {
            //         item.is_query_sent = false;
            //     })
            // }
            this.transporters.forEach((item,index) => {
                item.is_query_sent = false;
            })
            this.transportersList = [];       
        } 
        console.log('this.transportersList',this.transportersList);
    }       

   	closeModal(){
	   	this.transportersList = [];
	   	this.showUserPopup = false;
	   	this.showAdminPopup = false;
	   	this.isSelectAll = false;
	   	this.blankResponse = false;
	   	this.appComp.cssClass = '';
   		this.requestData = {
	   		from: '',
	   		to: '',
	   		vehicle_type: '',
	   		date : '',
	   		material: '',
	   		no_of_vehicle : null         
	   	};
	   	this.fromCity = '';
	   	this.cityTo = '';
		this.router.navigate(['/bookings']);    
    	
	}
	validateDate(date){
   		date = new Date(date);
   		if(date < new Date()){
   			this.requestData.date = new Date();
   		}
   	}

    openVehicleTypeModal(){

                let bsModalRef = this.modalService.show(VehicleTypeModalComponent);
                bsModalRef.content.closeBtnName = 'Close';
          bsModalRef.content.event.subscribe(data => {
              this.requestData.vehicle_type = data.label;
        });




	}

    openMaterialModal(){
        let bsModalRef: BsModalRef = this.modalService.show(MaterialDescriptionModalComponent,{class: 'modal-dialog-centered'});
        bsModalRef.content.event.subscribe(data => {
            this.requestData.material = data;
        });
	}



	getCustomers() {
        this.service.listUsers(this.user.token).subscribe(
            res => {
                console.log(res);
                this.customerList = res.data;
            },err => {
            });
    }


        addNewCustomer(){
		this.service.addCustomer(this.user.token, this.newCustomer).subscribe(
			res => {
				console.log(res);
				this.getCustomers();
				this.modalRef.hide();
			}, err => {
				console.log(err);
            });
}


    openAddNewCustomerModal(template: TemplateRef<any>){
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
        );
	}


}
