import { Component, OnInit } from '@angular/core';
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { AddTransportersService } from './add-transporters.service';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { Message} from 'primeng/components/common/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {HistoryService} from '../../history/history.service';
import {TransporterRoutesService} from '../../transporter-routes/transporter-routes.service';


@Component({
	selector: 'app-add-transporters',
	templateUrl: './add-transporters.component.html',
	styleUrls: ['./add-transporters.component.css']
})
export class AddTransportersComponent implements OnInit {

	constructor(
		private service: AddTransportersService,
		private route: ActivatedRoute,
		private router: Router,
		private historyService:HistoryService,
		private routeService: TransporterRoutesService
	) { }
	transporter: any =  { gps_info:{ } }
	vehicleType: FormControl = new FormControl();
	VehiclesTypes: Observable<string[]>;
	userToken: any = JSON.parse(localStorage.getItem('currentUser'));
	vehicleOptions = [
	{label: "20 ft Close Body", value: "20 ft Close Body"},
	{label: "22 ft Close Body", value: "22 ft Close Body"},
	{label: "24 ft Close Body", value: "24 ft Close Body"},
	{label: "32ft SXL", value: "32ft SXL"},
	{label: "32ft SXL HQ", value: "32ft SXL HQ"},
	{label: "32ft MXL", value: "32ft MXL"},
	{label: "32ft MXL HQ", value: "32ft MXL HQ"},
	{label: "32ft XXXL", value: "32ft XXXL"},
	{label: "14ft 3MT Open Body", value: "14ft 3MT Open Body"},
	{label: "17ft 4.5MT Open Body", value: "17ft 4.5MT Open Body"},
	{label: "9MT Open body", value: "9MT Open body"},
	{label: "22ft 15MT  Open body", value: "22ft 15MT  Open body"},
	{label: "24ft 20MT Open Body", value: "24ft 20MT Open Body"},
	{label: "20ft Trailer", value: "20ft Trailer"},
	{label: "28ft Half Body Trailer", value: "28ft Half Body Trailer"},
	{label: "20ft Trailer", value: "20ft Trailer"},
	{label: "56ft Trailer", value: "56ft Trailer"},
	{label: "ODC", value: "ODC"}
	];

	successMsg: Message[] = [];
	isAllVehicleType: any;
	
	ngOnInit() {
	}

	addTransporters() {
		this.service.createTransporters(this.userToken.token, this.transporter).subscribe(
			res => {
				console.log('add transporters',res);
				this.transporter = {};
				if (res.response) {
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}
				this.router.navigate(['/admin/transporters']);
			},err => {
			}
			)
		
	}
}
