import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { CommonModule,
    HashLocationStrategy,
    LocationStrategy} from '@angular/common';
import { MatTabsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatChipsModule
} from '@angular/material';

import {MessagesModule} from 'primeng/primeng';
import {MessageService} from 'primeng/api';

import { ClickOutsideModule } from 'ng4-click-outside';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { BsDropdownModule, PopoverModule, PaginationModule,TooltipModule,DatepickerModule,ModalModule,AlertModule,BsDatepickerModule   } from 'ngx-bootstrap';
import { LetterAvatarDirective} from './common/directives/common.letterAvatar.directive';
import { ValidateEqualDirective} from './common/directives/validate-equal.directive';
import { OutsideClickDirective} from './common/directives/outsideClick.directive';
//import { SelectDropDownModule } from 'ngx-select-dropdown';
import { GrowlModule } from 'primeng/growl';
import { DataViewModule } from 'primeng/dataview';
import { PanelModule} from 'primeng/panel';
import { DialogModule} from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule} from 'primeng/dropdown';
import { SidebarModule} from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { MultiSelectModule} from 'primeng/multiselect';
import {PaginatorModule} from 'primeng/paginator';
import { AppComponent } from './app.component';
import { SigninComponent } from './modules/user/signin/signin.component';
import { SignupComponent } from './modules/user/signup/signup.component';
import { ProfileComponent } from './modules/user/profile/profile.component';
import { PasswordComponent } from './modules/user/password/password.component';
import { AuthenticationService } from './modules/user/services/authentication.services';
import { AppRoutingModule } from './app.routing';
import { HeaderComponent } from './modules/core/header/header.component';
import { AdminComponent } from './modules/admin/admin.component';
import { UserServices } from './modules/admin/services/admin.users.services';
import { NewRequestService } from './modules/new-request/new-request.service';
import { HistoryService } from './modules/history/history.service';
import { QueryDetailService } from './modules/query-price-detail/query-detail.service';
import { TranportersService} from './modules/transporters-form/tranporters.service';
import { VehicleDetailService} from './modules/transporters-vehicle-detail/vehicle-detail.service';
import { UserAddressService} from './modules/user-address/user-address.service';
import { TransporterBookingsService} from './modules/transporter-bookings/transporter-bookings.service';
import { TransportersFormComponent } from './modules/transporters-form/transporters-form.component';
import { ForgotPasswordComponent } from './modules/user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/user/reset-password/reset-password.component';
import { QueryPriceDetailComponent } from './modules/query-price-detail/query-price-detail.component';
import { TransportersVehicleDetailComponent } from './modules/transporters-vehicle-detail/transporters-vehicle-detail.component';
import { UserAddressComponent } from './modules/user-address/user-address.component';
import { ResponseBackComponent } from './modules/response-back/response-back.component';
import { NewRequestComponent } from './modules/new-request/new-request.component';
import { HistoryComponent } from './modules/history/history.component';
import { TransportersComponent } from './modules/admin/transporters/transporters.component';
import { AdmintransportersService } from './modules/admin/transporters/admintransporters.service';
import { AddTransportersService } from './modules/admin/add-transporters/add-transporters.service';
import { TransportersDetailsService } from './modules/admin/transporters-details/transporters-details.service';
import { UserListComponent } from "./modules/admin/user/user-list.component";
import { UserListService } from "./modules/admin/user/user-list.service";
import { AllRoutesService } from "./modules/all-routes/all-routes.service";
//pipes or filters
//import { Search } from './common/pipes/serach.pipe';
//import { OrderrByPipe } from './common/pipes/sort.pipe';
import { AddTransportersComponent } from './modules/admin/add-transporters/add-transporters.component';
import { TransporterRoutesService } from './modules/transporter-routes/transporter-routes.service';

import { TransportersDetailsComponent } from './modules/admin/transporters-details/transporters-details.component';
import { TransporterRoutesComponent } from './modules/transporter-routes/transporter-routes.component';
import { TransporterBookingsComponent } from './modules/transporter-bookings/transporter-bookings.component';
import { TransporterSignupComponent } from './modules/user/transporter/transporter-signup/transporter-signup.component';
import { TransporterSignupService }  from './modules/user/transporter/transporter-signup/transporter-signup.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AllRoutesComponent } from './modules/all-routes/all-routes.component';
import {VehicleTypeModalComponent} from './modules/new-request/vehicle-type-modal.component';
import {MaterialDescriptionModalComponent} from './modules/new-request/material-description-modal.component';
import {CustomerProfileComponent} from './modules/user/profile/customer-profile.component';
import {OrderDetailComponent} from './modules/admin/order-detail/order-detail.component';
import {OrderDetailService} from './modules/admin/order-detail/order-detail.service';
import {VehicleService} from './modules/admin/vehicle/vehicle.service';
import {OrderHistoryComponent} from './modules/admin/order-history/order-history.component';
import {OrderHistoryService} from './modules/admin/order-history/order-history.service';
import {FilterPipe} from './modules/admin/order-history/order-history.pipe';
import {UserDetailsComponent} from './modules/admin/user/user-details.component';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UserDetailModalComponent} from './modules/history/user-detail-modal.component';
import {ConfirmationModalComponent} from './modules/admin/common/confirmation/confirmation-modal.component';
import {InvoiceModalComponent} from './modules/admin/common/invoice/invoice-modal.component';
import { RegisterComponent } from './modules/register/register.component';
import { RegisterService } from './modules/register/register.service';
import {LrModalComponent} from './modules/admin/common/lr/lr-modal.component';
import {FullImageModalComponent} from './modules/admin/common/full-image/full-image-modal.component';
import {CustomerPaymentsComponent} from './modules/admin/accounts/customer-payments/customer-payments.component';
import {InvoiceListComponent} from './modules/admin/accounts/customer-payments/invoice-list.component';
import {AccountsService} from './modules/admin/accounts/accounts.service';
import {TdsPaymentsModalComponent} from './modules/admin/accounts/transporter-payments/tds-payments.modal.component';
import { AddTransporterComponent } from './modules/admin/common/add-transporter/add-transporter.component';
import { AddCustomerComponent } from './modules/admin/common/add-customer/add-customer.component';
import {TransporterPaidPaymentsComponent} from './modules/admin/accounts/transporter-payments/transporter-paid-payments.component';
import {TransporterUnpaidPaymentsComponent} from './modules/admin/accounts/transporter-payments/transporter-unpaid-payments.component';
import {TransporterPayments} from './modules/admin/accounts/transporter-payments/transporter-payments';
import {NgxPaginationModule} from 'ngx-pagination';
import { ProjectionComponent } from './modules/projection/projection.component';
import { SalesAnalyticsComponent } from './modules/sales-analytics/sales-analytics.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LeadsComponent } from './modules/leads/leads.component'
import {SundryDebtorsComponent} from './modules/admin/accounts/customer-payments/sundry-debtors.component';
import {AddOrderComponent} from './modules/admin/order-detail/add-order.component';
import { EditLeadComponent } from './modules/leads/edit-lead/edit-lead.component';
import {UpdateVehicleStatusComponent} from './modules/admin/order-history/update-vehicle-status.component';
import { VehicleComponent } from './modules/admin/vehicle/vehicle.component';
import { ListVehiclesComponent } from './modules/admin/list-vehicles/list-vehicles.component';
import { ListVehicleService } from './modules/admin/list-vehicles/list-vehicle.service';

@NgModule({
    declarations: [
        AppComponent,
        SigninComponent,
        SignupComponent,
        ProfileComponent,
        PasswordComponent,
        HeaderComponent,
        AdminComponent,
        LetterAvatarDirective,
        ValidateEqualDirective,
        OutsideClickDirective,
        TransportersFormComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        QueryPriceDetailComponent,
        TransportersVehicleDetailComponent,
        UserAddressComponent,
        ResponseBackComponent,
        NewRequestComponent,
        HistoryComponent,
        OrderDetailComponent,
        TransportersComponent,
        FilterPipe,
        //Search,
        //OrderrByPipe,
        AddTransportersComponent,
        TransportersDetailsComponent,
        TransporterRoutesComponent,
        TransporterBookingsComponent,
        TransporterSignupComponent,
        UserListComponent,
        VehicleTypeModalComponent,
        MaterialDescriptionModalComponent,
        CustomerProfileComponent,
        AllRoutesComponent,
        OrderHistoryComponent,
        UserDetailsComponent,
        UserDetailModalComponent,
        ConfirmationModalComponent,
        InvoiceModalComponent,
        RegisterComponent,
        LrModalComponent,
        FullImageModalComponent,
        CustomerPaymentsComponent,
        InvoiceListComponent,
        TdsPaymentsModalComponent,
        AddTransporterComponent,
        AddCustomerComponent,
        TransporterPaidPaymentsComponent,
        TransporterUnpaidPaymentsComponent,
        TransporterPayments,
        ProjectionComponent,
        SalesAnalyticsComponent,
        DashboardComponent,
        LeadsComponent,
        SundryDebtorsComponent,
        AddOrderComponent,
        EditLeadComponent,
        UpdateVehicleStatusComponent,
        VehicleComponent,
        ListVehiclesComponent
    
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PaginationModule,
        TooltipModule,
        DatepickerModule,
        MatTabsModule,
        MatCardModule,
        MatListModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatOptionModule,
        MatTableModule,
        MatSelectModule,
        MatInputModule,
        MatMenuModule,
        MatIconModule,
        MatChipsModule,
        MatPaginatorModule,
        MatDatepickerModule,
        NgbModalModule,
        GrowlModule,
        DropdownModule,
        PanelModule,
        DialogModule,
        ButtonModule,
        SidebarModule,
        TableModule,
        MultiSelectModule,
        MatAutocompleteModule,
        PaginatorModule,
        DataViewModule,
        MatButtonModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatNativeDateModule,
        NgbModule.forRoot(),
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        NgxPaginationModule,
        NgxDatatableModule,
        MessagesModule,
        
    ],
    providers: [{
        provide: LocationStrategy,
        useClass: HashLocationStrategy
    },
        MessageService,    
        AuthenticationService,
        NewRequestService,
        HistoryService,
        UserAddressService,
        AdmintransportersService,
        AddTransportersService,
        TransportersDetailsService,
        TransporterRoutesService,
        TransporterBookingsService,
        TransporterSignupService,
        UserServices,
        QueryDetailService,
        TranportersService,
        VehicleDetailService,
        UserListService,
        OrderDetailService,
        AllRoutesService,
        OrderHistoryService,
        VehicleService,
        ListVehicleService,
        RegisterService,
    AccountsService],
    entryComponents : [
        VehicleTypeModalComponent,
        MaterialDescriptionModalComponent,
        UserDetailModalComponent,
        ConfirmationModalComponent,
        InvoiceModalComponent,
        LrModalComponent,
        FullImageModalComponent,
        TdsPaymentsModalComponent,
        AddTransporterComponent,
        AddCustomerComponent,
        UpdateVehicleStatusComponent,
        
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
