import { Component, OnInit,TemplateRef,HostListener, ViewChild } from '@angular/core';
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message} from 'primeng/components/common/api';
import { MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { MessageService} from 'primeng/components/common/messageservice';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AppComponent } from '../../app.component';
import { HistoryService }  from './history.service';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import * as _ from "lodash";
import {UserDetailModalComponent} from "./user-detail-modal.component";


@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

    moreIcon = '../../../assets/images/moreIcon.png';
    columnsForTransporters = ['reply', 'name', 'location', 'contact_person', 'contact_numbers'];

    displayedColumns = ['price', 'from', 'to', 'vehicle_type', 'material', 'date'];
    bookings: MatTableDataSource<any>;
    dataForTransporters: MatTableDataSource<any>;
    length = 100;
    pageSize = 10;
    pageSizeOptions = [5, 10, 25, 100];
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    successMsg: Message[] = [];
    msg: any;
    errorMessage: any;
    errorMsg: any;
    currentQueryId: any = '';
    modalRef: BsModalRef;
    transportersList: any = [];
    transporters: any = [];
    datasourceTransportersArray: any = [];
    transportersDataObject: any = {
        query_id: '',
        transporters_list: []
    };
    loadingAddress: any = {};
    unloadingAddress: any = {};
    activeRowId: any = '';
    isopen: boolean = false;
    selectTransporter: boolean = false;
    isReplyModalOpen: boolean = false;
    isSelectAll: boolean = false;
    current_query: any = {};
    blankResponse = false;
    priceArray: any = [];
    innerWidth: any = {};
    selected_tp: any = [];
    replyData: any = {};
    isMobileView: boolean = false;
    openReplyButton: boolean = false;
    isAdmin: boolean = false;
    showTrnasportersModal: boolean = false;
    sortOptions: any[];
    sortKey: string;
    sortField: string;
    sortOrder: number;
    queryId: any;
    userAddressData: any = {};
    isShowAddressForm: boolean = false;
    dataSource: any = [];
    createRouteData : any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.screen.width;
        console.log('innerWidth', this.innerWidth);

        if (this.innerWidth > 480) {
            this.isMobileView = false;
        } else {
            this.isMobileView = true;
        }
    }

    bsModalRef: BsModalRef;

    constructor(private service: HistoryService,
                private route: ActivatedRoute,
                private router: Router,
                private modalService: BsModalService,
                private appComp: AppComponent) {
        if (this.user) {
            this.getHistory();
            if (this.user.role == 1) {
                this.isAdmin = true;
            }
        }
    }

    ngOnInit() {
        this.innerWidth = window.screen.width;
        console.log('innerWidth', event);
        if (this.innerWidth > 480) {
            this.isMobileView = false;
        } else {
            this.isMobileView = true;
        }
        if (this.user) {
            if (this.isAdmin) {
                this.displayedColumns = ['txnId', 'name', 'phone', 'from', 'to', 'vehicle_type', 'material', 'date', 'quoted price', 'action'];
                this.openReplyButton = true;
            } else {
                this.displayedColumns = ['txnId', 'price', 'from', 'to', 'vehicle_type', 'vehicle_no', 'vehicle_details', 'material', 'date', 'user-action'];
            }
        }
        if (this.bookings) {
            console.log('paginator');
            this.bookings.paginator = this.paginator;
            this.bookings.sort = this.sort;
        }
    }

    openReplyModal(template: TemplateRef<any>, query_id: string) {
        this.currentQueryId = query_id;
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, {})
        );
    }

    openAddressModal(template: TemplateRef<any>, id: string) {
        let index = _.findIndex(this.dataSource, function (curr_obj) {
            return curr_obj._id.toString() == id.toString()
        });
        console.log('bvdvhjb', index);
        this.loadingAddress = this.dataSource[index].loading;
        this.unloadingAddress = this.dataSource[index].unloading;
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, {})
        );
    }

    openDetailModal(template: TemplateRef<any>, query_id: string) {
        console.log("here");
        let index = _.findIndex(this.dataSource, function (curr_obj) {
            return curr_obj._id.toString() == query_id.toString()
        });
        console.log(index);
        console.log(this.dataSource);
        this.selected_tp = this.dataSource[index].transporters;
        this.modalRef = this.modalService.show(template,
            Object.assign({}, {})
        );
    }

    sendReply() {
        this.isopen = false;
        this.replyData.query_id = this.currentQueryId;
        this.service.sendReply(this.replyData, this.user.token).subscribe(
            res => {
                this.getHistory();
                console.log("...reply", res);
                this.modalRef.hide();
                this.replyData.price = null;
                this.successMsg = [];
                this.appComp.cssClass = '';
                this.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message});
            }, err => {
                console.log(err);
                this.errorMessage = err.json().message;
                this.errorMsg = [];
                this.errorMsg = [{severity: 'error', detail: this.errorMessage}];
            }
        )
    }

    getHistory() {
        this.service.getHistory(this.user.token).subscribe(
            res => {

                let dataSource = [];
                console.log('query details', res.data);
                res.data.forEach((item, index) => {

                    let obj = {
                        _id: item._id,
                        txn_id: item.txn_id,
                        from: item.from,
                        date: item.date,
                        material: item.material,
                        name: item.user.name,
                        user_id: item.user._id,
                        phone: item.user.phone,
                        to: item.to,
                        vehicle_type: item.vehicle_type,
                        no_of_vehicle: item.no_of_vehicle,
                        priceArray: item.price ? item.price : [],
                        price: item.price.length > 0 ? {
                            value: item.price[item.price.length - 1].value,
                            price_details: item.price[item.price.length - 1].price_details,
                            user_response: item.price[item.price.length - 1].user_response,
                            _id: item.price[item.price.length - 1]._id
                        } : {value: "-", user_response: ''},
                        transporters: item.transporters,
                        loading: item.loading,
                        unloading: item.unloading,
                    }
                    dataSource.push(obj);
                })
                console.log('dataSource', dataSource);
                this.bookings = new MatTableDataSource(dataSource);
                this.dataSource = dataSource;
                // this.isRequestPosted = false;

            }, error => {
                console.log(error);
            }
        )
    }

    getTransporters(queryId, rowData) {
        console.log(rowData);
        this.showTrnasportersModal = true;
        this.transportersList = [];
        this.appComp.cssClass = 'modal-open';
        console.log('get transporters list', queryId);
        this.service.getTransporters(this.user.token, {query_id: queryId}).subscribe(
            res => {
                if (!res.response) {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
                }

                this.transportersDataObject.query_id = res.data.current_query._id;
                console.log('get transporters list', res);
                if (res.data.transporters.length == 0) {
                    this.blankResponse = true;
                    this.createRouteData = {
                        fromAddress: {
                                   city:  rowData.from.substring(0, rowData.from.indexOf(',')),
                                   state: rowData.from.substring(rowData.from.indexOf(',') + 1)
                        },
                        toAddress:{
                            city: rowData.to.substring(0, rowData.to.indexOf(',')),
                            state: rowData.to.substring(rowData.to.indexOf(',') + 1)
                        },
                        vehicle_type: [rowData.vehicle_type],
                        route_type: null,
                    };
                }
                this.datasourceTransportersArray = res.data.current_query.transporters;

                let dataArray = [];
                this.current_query = res.data.current_query;
                res.data.transporters.forEach((item, index) => {
                    let tp_index = this.current_query.transporters.findIndex(function (selected_transporter) {
                        return selected_transporter.id._id == item._id;
                    })
                    console.log(tp_index);
                    if (tp_index > -1) {
                        this.transportersList.push(item._id);
                        item.is_query_sent = true;
                        item.is_already_select = true;
                    }

                    let obj = {
                        _id: item.transporter._id,
                        name: item.transporter.user.company,
                        location: item.transporter.user.address,
                        contact_person: item.transporter.user.name,
                        contact_numbers: item.transporter.contact_numbers,
                        // vehicle_type: current_query.vehicle_type,
                        // from : current_query.from,
                        // to : current_query.to,
                        is_query_sent: item.is_query_sent,
                        is_already_select: item.is_already_select
                    }

                    dataArray.push(obj);

                    console.log('dataArray', dataArray);
                    this.blankResponse = false;
                })
                this.transporters = dataArray;
                console.log('this.transportersList', this.transportersList);
                this.dataForTransporters = new MatTableDataSource(dataArray);
            }, err => {
            }
        )
    }

    getUserResponse(id, priceId, action) {
        let userResponseData = {
            query_id: id,
            price_id: priceId,
            action: action
        }
        console.log(userResponseData);
        this.service.getUserResponse(userResponseData, this.user.token).subscribe(
            res => {
                console.log('home....res', res);
                if (res.response) {
                    this.getHistory();
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message});
                } else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
                }

            }, err => {
                console.log('error', err);
            }
        )
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches 
        console.log(filterValue);
        this.bookings.filter = filterValue;
    }

    closeModal() {
        this.transportersList = [];
        this.isSelectAll = false;
        this.blankResponse = false;
        this.appComp.cssClass = '';
        this.showTrnasportersModal = false;
        this.isShowAddressForm = false;
    }

    sendMessageToTransporters() {
        this.transportersDataObject.transporters_list = this.transportersList;
        this.service.sendMessageToTransporters(this.transportersDataObject, this.user.token).subscribe(
            res => {
                console.log('res', res);
                if (res.response) {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message});
                } else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
                }
                this.closeModal();
            }, err => {
                console.log('error', err);
            }
        )
    }

    selectTransporters(event, id) {
        if (event.checked) {
            this.transportersList.push(id);
        } else {
            let index = this.transportersList.findIndex(item => item == id);
            if (index != -1) {
                this.transportersList.splice(index, 1);
            }
        }
        if (this.transportersList.length == 0) {
            this.isSelectAll = false;
        }
        if (this.transportersList.length == this.transporters.length) {
            this.isSelectAll = true;
        }
        console.log('row details', this.transportersList);
    }

    selectAll(event) {
        this.transportersList = [];
        console.log('event', event);
        if (event.checked) {

            this.transporters.forEach((item, index) => {
                console.log('transporters', this.transporters);
                // let tp_index = this.datasourceTransportersArray.findIndex(function(selected_transporter){
                //     return selected_transporter.id._id != item._id;
                // })
                // console.log(tp_index);
                // if(tp_index > -1){
                //     item.is_query_sent = true;
                // }else{
                //     this.transportersList.push(item._id)
                // }
                item.is_query_sent = true;
                this.transportersList.push(item._id)
            })

        } else {
            // if (this.datasourceTransportersArray.length > 0) { 
            //     this.transporters.forEach((item,index) => {
            //         console.log('transporters',this.transporters);
            //         let tp_index = this.datasourceTransportersArray.findIndex(function(selected_transporter){
            //             return selected_transporter.id._id == item._id;
            //         })
            //         console.log(tp_index);
            //         if(tp_index > -1){
            //             item.is_query_sent = true;
            //         }
            //     })
            // } else {
            //     this.transporters.forEach((item,index) => {
            //         item.is_query_sent = false;
            //     })
            // }
            this.transporters.forEach((item, index) => {
                item.is_query_sent = false;
            })
            this.transportersList = [];
        }
        console.log('this.transportersList', this.transportersList);
    }

    openSubmitAddressModal(id) {
        this.queryId = id;
        this.isShowAddressForm = true;
    }

    submitAddress() {
        this.userAddressData.query_id = this.queryId;
        let that = this;
        this.service.getUserAddress(this.user.token, this.userAddressData).subscribe(
            res => {
                console.log('vehicle response', res);
                if (!res.response) {

                    this.successMsg = [];
                    this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
                } else {
                    this.successMsg = [];
                    this.successMsg.push({
                        severity: 'success',
                        summary: 'Success Message',
                        detail: 'Thanks for your response, you will hear from transporter shortly.'
                    });
                    // this.router.navigate(['/response-back'],{queryParams:{path:'user-address'}});
                    that.dataSource.forEach(function (item,index) {
                        if(item._id == that.queryId){
                            let loading = {
                                address:that.userAddressData.loading_address,
                                contact_number:that.userAddressData.loading_contact_number
                            };
                            let unloading  = {
                                address:that.userAddressData.unloading_address,
                                contact_number:that.userAddressData.unloading_contact_number
                            };
                                that.dataSource[index].loading = loading;
                                    that.dataSource[index].unloading = unloading;
                        }
                    })
                }
            }, err => {
                console.log('err', err);
            }
        )
        this.isShowAddressForm = false;
    }

    getUserProfile(id) {
        this.service.getUserDetail(this.user.token, id).subscribe(
                            res => {
                        console.log(res.data);
                                const initialState = {
                                    user: res.data
                                };
                                this.bsModalRef = this.modalService.show(UserDetailModalComponent,{initialState});
                                this.bsModalRef.content.closeBtnName = 'Close';






                        },err => {
                        console.log('error', err);
                    }
            );
        }

    editBooking(bookingId){
        console.log(bookingId);
        this.router.navigate(['order/detail/edit',{id:bookingId}]);
    }


    }

