import { Component, OnInit,TemplateRef,HostListener, ViewChild } from '@angular/core';
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message} from 'primeng/components/common/api';
import { MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { MessageService} from 'primeng/components/common/messageservice';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AppComponent } from '../../app.component';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import * as _ from "lodash";
import { TransporterBookingsService }  from './transporter-bookings.service';

@Component({
	selector: 'app-transporter-bookings',
	templateUrl: './transporter-bookings.component.html',
	styleUrls: ['./transporter-bookings.component.css']
})
export class TransporterBookingsComponent implements OnInit {
	user: any = JSON.parse(localStorage.getItem('currentUser'));
	transporterId: string;
    replyData: any = {
        tp_id: '',
        query_id: '',
        price: null,
        available_vehicles: null,
    };
    vehicle_detail: any = {
        tp_id: '',
        query_id: '',
        vehicle_details: []
    };
    tp_response: any = {
        driver_number: "",
        vehicle_number: ""
    }
	constructor(
		private service: TransporterBookingsService,
		private router: Router,
		private route: ActivatedRoute,
        private modalService: BsModalService,
	) { 
		this.route.params.subscribe(
			res => {
				console.log('routes res',res);
				this.transporterId = res.id;
			},err => {
				console.log('err',err);
			}
			)
	}
	modalRef: BsModalRef;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	innerWidth: any = {};
	isMobileView: boolean = false;
	successMsg: Message[] = [];
	isShowAddressForm: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.screen.width;
		console.log('innerWidth',event);
		if (this.innerWidth > 480) {
			this.isMobileView = false;
		}else{
			this.isMobileView = true;
		}
	}
	displayedColumns = ['txn_id','from','to','vehicle_type','material','date','price','vehicle_details','address'];
	dataSource:  MatTableDataSource<any>;
	bookings: any = [];
	loading: any = {};
	unloading: any = {};
	vehicle_details: any = {};
	ngOnInit() {
		this.innerWidth = window.screen.width;
		console.log('innerWidth',event);
		if (this.innerWidth > 480) {
			this.isMobileView = false;
		}else{
			this.isMobileView = true;
		}
		this.getBookings();
		if (this.dataSource) {
			console.log('paginator');
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}  
	}
	
	getBookings() {
		console.log('user',this.user);
		this.service.getBookings(this.user.token, this.transporterId).subscribe(
			res => {
				let dataSource = [];
				
				console.log('query details',res.data);
				res.data.forEach((item,index) => {
					console.log(item);

					let obj = {
						_id: item._id,
						txn_id: item.txn_id,
						from: item.from,
						to: item.to,
						date: item.date,
						material: item.material,
						vehicle_type: item.vehicle_type,
						no_of_vehicle: item.no_of_vehicle,
						price: item.transporters.length?item.transporters[0].price:"-",
						transporters: item.transporters,
						loading: item.loading,
                        unloading: item.unloading,
					} 
					dataSource.push(obj);
					this.bookings.push(obj);
				})
				console.log('dataSource',dataSource);
				this.dataSource = new MatTableDataSource(dataSource);
				this.dataSource.paginator = this.paginator;
				
			},err => {
			} 
			)
	}

	openAddressModal(template: TemplateRef<any>, id: string) {
		console.log(id);
		console.log(template);
		console.log(this.bookings);
		let index =  _.findIndex(this.bookings, function(curr_obj){  return curr_obj._id.toString() == id.toString()});
        console.log(index);
       	this.loading = this.bookings[index].loading;
       	this.unloading = this.bookings[index].unloading;
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
            );
    }

    openVehicleModal(template: TemplateRef<any>, id: string){
     	console.log(id);
     	console.log(this.bookings);
        let index =  _.findIndex(this.bookings, function(curr_obj){  return curr_obj._id.toString() == id.toString()});
        console.log(index);
       	this.vehicle_details = this.bookings[index].transporters[0].vehicle_details;

        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
            );
    }

    openPriceModal(template: TemplateRef<any>, id: string){

        this.replyData.query_id = id;
        this.replyData.tp_id = this.transporterId;
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
        );
	}

    sendReply(){
        this.service.transportersResponse(this.user.token, this.replyData).subscribe(
           res => {
               console.log('transportersResponse', res);
               if(!res.response){
                   this.successMsg = [];
                   this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
               }else{
                   this.successMsg = [];
                   this.successMsg.push({severity: 'success',summary:'Success Message',detail: "Thanks for your response, we will get back to you shortly."});
                   let that = this;
                   setTimeout(function(){
                       that.router.navigate(['/bookings']);
                   }, 2000);

               }
           },err => {
               console.log('err',err);
           })
	}

    addVehicleDetail(template: TemplateRef<any>, id: string){

		this.vehicle_detail.tp_id = this.transporterId;
		this.vehicle_detail.query_id = id;
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
        );
	}

    sendVehicleDetails() {
        this.modalRef.hide();
		this.vehicle_detail.vehicle_details.push(this.tp_response);
        this.service.transportersVehicleDetail(this.user.token, this.vehicle_detail).subscribe(
            res => {
                console.log('vehicle response',res);
                if (!res.response){
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
                }
                else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success',summary:'Success Message',detail: "Thanks for your response, we will get back to you shortly."});
                    let that = this;
                        that.router.navigate(['/bookings']);

                }
            },err => {
                console.log('err',err);
            })
    }



}
