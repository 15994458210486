import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Message} from 'primeng/components/common/api';
import { MessageService} from 'primeng/components/common/messageservice';
import { VehicleDetailService} from './vehicle-detail.service';
import * as _ from "lodash";
@Component({
  selector: 'app-transporters-vehicle-detail',
  templateUrl: './transporters-vehicle-detail.component.html',
  styleUrls: ['./transporters-vehicle-detail.component.css']
})
export class TransportersVehicleDetailComponent implements OnInit {

  constructor(private service: VehicleDetailService,
    private route: ActivatedRoute,
    private router: Router) { 
    this.route.queryParams.subscribe(
      res =>{
        this.detailData.query_id = res.query_id;
        this.detailData.tp_id = res.tp_id;
        this.token = res.token;
      },err =>{
        console.log('err',err);
      }
      )  
  }
  user: any = JSON.parse(localStorage.getItem('currentUser'));
  token: any;
  queryData: any = {};
  Vehicles: any = [];
  successMsg: Message[] = [];
  detailData: any = {
  	tp_id: '',
  	query_id: ''
  };
  vehicle_detail: any = {
    tp_id: '',
    query_id: '',
    vehicle_details: []
  };
  tp_response: any = {
    driver_number: "",
    vehicle_number: ""
  }
  isShowPriceForm: Boolean = true;

  addRow(){
    this.vehicle_detail.vehicle_details.push(this.tp_response);
  }
  ngOnInit() {
    this.service.getQueryDetail(this.token,{query_id: this.detailData.query_id}).subscribe(
      res => {
        let query = res.data;
        console.log('query',query);
        let that = this;
        if (query.transporters) {
          var tp_index= _.findIndex(query.transporters, function(transporter) {return transporter.id._id.toString() == that.detailData.tp_id.toString()});
          console.log(tp_index);
          for(var i=0; i< query.transporters[tp_index].available_vehicles; i++){
            this.Vehicles.push(i);
            }
           
          if (query) { 
            this.queryData = {
              from: query.from,
              to: query.to,
              vehicle_type:query.vehicle_type,
              date: query.date,
              price: query.transporters[tp_index].price,
              available_vehicles: query.transporters[tp_index].available_vehicles

            }
            console.log('trans vehicle detail', this.queryData);
          }
        }
       
        if(!res.response){
          this.successMsg = [];
          this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
        }
      },err => {
        console.log('err',err);
      }
      )
    
  }
  submitData(){
    this.vehicle_detail.tp_id = this.detailData.tp_id;
    this.vehicle_detail.query_id = this.detailData.query_id;
    this.vehicle_detail.vehicle_details.push(this.tp_response);
    this.service.transportersVehicleDetail(this.token,this.vehicle_detail).subscribe(
      res => {
        console.log('vehicle response',res);
        this.isShowPriceForm = false;
        if(!res.response){
          this.successMsg = [];
          this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
        }else{
          this.detailData.driver_number= null;
          this.detailData.vehicle_number = '';
          this.successMsg = [];
          this.successMsg.push({severity: 'success',summary:'Success Message',detail: "Thanks for your response, we will get back to you shortly."});
          let that = this;
          setTimeout(function(){
            that.router.navigate(['/bookings']);    
          }, 2000);
          
        }
      },err => {
        console.log('err',err);
      }
      )
  }
}
