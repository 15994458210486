import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';


@Injectable()
export class UserServices {
  constructor(
    private http: Http
  ) { }
  
  listUsers() {
    return this.http.get('/users').map((response: Response) => response.json());    
  }

  createUser(user) {
    return this.http.post('/users', user).map((response: Response) => response.json());    
  }

  updateUser(user) {
    return this.http.put('/users', user).map((response: Response) => response.json());    
  }

  deleteUser(userId) {
    return this.http.delete('/users/'+userId).map((response: Response) => response.json());    
  }
}

let convertToArray = function(jsonObj){
  let keys = Object.keys(jsonObj);
  let array =  keys.map(function(key){
    return jsonObj[key];
  })
  return array;
}