import {Component, OnInit, Input, Output, EventEmitter, Host, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import { SigninComponent } from '../../user/signin/signin.component';
import { AuthenticationService } from '../../user/services/authentication.services';
import * as _ from 'lodash';
import  { AppComponent } from '../../../app.component'
import { ProfileComponent } from '../../user/profile/profile.component';
import { LetterAvatarDirective } from '../../../common/directives/common.letterAvatar.directive';

@Component({
	moduleId: module.id,
	selector: "header-view",
    host: {
        '(document:click)': 'handleClick($event)',
    },
	templateUrl: "header.component.html",
	styleUrls: ['header.component.css']
})

export class HeaderComponent {
    innerWidth: any = {};
    isMobileView: boolean = false;
	visibleSidebar1: boolean = false;
	notify = '../../../assets/images/notify.png';
	notifyBig = '../../assets/images/notification-big-icon.png';
	helperImage = '../../assets/images/helper.png';
	shared = '../../assets/images/shared.png';
	bars = "../../assets/images/bars.png";
	home = "../../assets/images/home.png";
	logo = "../../assets/images/logo_trunkpool.png";
	userImage = "../../assets/images/user.png";
	// logo: string = ".../../assets/images/zatayuLogo.png";
	angleDown = "../../assets/images/angleDown.png";
	profileIcon = "../../assets/images/profile.jpg";
	userIcon = "../../assets/images/user.png";
	keyIcon = "../../assets/images/password.png";
	logoutIcon = "../../assets/images/logout.png";
    userListIcon = "../../assets/images/user.svg";
	cancelIcon = "../../assets/images/cancelIcon.png";
	@Input() sidebarStateChange: string;
	@Output() onToggled = new EventEmitter<string>();
	state = 'out';
	// isRequest = true;
	// isBooking = false;
	// isSelected:string = "request";
	user: any = JSON.parse(localStorage.getItem('currentUser'));
	showAdminSetting: boolean = false;
	showTransportersSetting: boolean = false;
	showSalesSetting: boolean = false;
	showOperationsSetting: boolean = false;
	showSourcingSetting: boolean = false;
	showAccountSetting: boolean = false;
	showHeaderIcons: boolean = false;
	showProfile: boolean = false;
	transporterId: string;
	navBarWidth = "0px";
	showAccountItems = false;
	@Output() show = new EventEmitter<boolean>();

	@ViewChild(ProfileComponent) userprofile : ProfileComponent;
	@Input() bookingScreen;
	search:boolean = false;
    public elementRef;

    showLeadsSetting: boolean = false;
	toggleSidebar() {
		this.state = this.state === 'out' ? 'in' : 'out';
		this.onToggled.emit(this.state);
	}
	constructor(
		private authenticationService: AuthenticationService,
		private route: ActivatedRoute,
		private router: Router,
		private appComp: AppComponent,myElement: ElementRef) {
        this.elementRef = myElement;
    }

	ngOnInit(){
        this.innerWidth = window.screen.width;
        console.log('innerWidth', event);
        if (this.innerWidth > 480) {
            this.isMobileView = false;
        } else {
            this.isMobileView = true;
        }
        console.log(this.isMobileView);
		// check login status		
		if(localStorage.getItem('currentUser')){    		    					         	
			this.showHeaderIcons = true;
		}else{    		    					         	
			this.showHeaderIcons = false;
		}
		if(this.user && this.user.role == 1){
			this.showAdminSetting = true;
		}
		if(this.user && this.user.role == 7){
			this.showTransportersSetting = true;
		}
        if(this.user && this.user.role == 2){
            this.showSalesSetting = true;
        }
        if(this.user && this.user.role == 3){
            this.showSourcingSetting = true;
        }
        if(this.user && this.user.role == 4){
            this.showOperationsSetting = true;
        }
        if(this.user && this.user.role == 5){
            this.showAccountSetting = true;
        }
        if(this.user && this.user.role == 6){
            this.showLeadsSetting = true;
        }
		if(this.user && this)
		if(this.user && this.user.transporter){
			this.transporterId = this.user.transporter._id;
		}
				
	}
	// getSidebarStatus(){	
	// 	this.sidebar = false;
	// }
	opensidebar(){
		this.visibleSidebar1 = !this.visibleSidebar1;
	}
	// ngOnChanges(){
	// 	this.state = this.sidebarStateChange;
	// }
	hidePanel(){}

	toggleDropdown(event: MouseEvent){
		event.stopPropagation();
	}
	openProfileModal(){
		this.showProfile = true;
		this.appComp.cssClass = 'modal-open';
		console.log("user",this.user);
		this.userprofile.profile(this.user);
		this.show.emit(false);
	}
	showRequestTab(event){
		console.log("....event",event);
		this.show.emit(event);
	}

    newrequest(){
        // this.isSelected = 'request';
        // this.isBooking = false;
        // this.isRequest = true;
        // console.log(this.isSelected);
        this.router.navigate(['new-request']);
	}

	signout() {
        this.navBarWidth = "0px";
		this.authenticationService.signout(localStorage.getItem('currentUser'))
		.subscribe(
			user => {
        		console.log(localStorage.getItem('currentUser')) 
				this.router.navigate(['/signin']);
			},
			error => {
				console.log(error);
			}
		);
	}
	getBookings() {
	    // this.isBooking = true;
	    // this.isRequest = false;
        // this.isSelected = 'booking';
        // console.log(this.isSelected)
		if (this.user.role == 7) {
            // this.isSelected = 'booking';
			this.router.navigate(['transporters/'+this.transporterId+'/bookings']);
		} else {
            // this.isSelected = 'booking';
			this.router.navigate(['bookings']);
		}
	}
	
	/**
	 * Change image src on mouse over
	 * @param imagePath
	 */
	 onMouseOver(imagePath: string): void {
	 	switch (imagePath) {
	 		case 'user_hover': {
	 			this.userImage = ".../../../assets/images/user_hover.png";
	 			break;
	 		}
	 		case 'notify_hover': {
	 			this.notify = ".../../../assets/images/notify_hover.png";
	 			break;
	 		}
	 		case 'userIcon_hover': {
	 			this.userIcon = ".../../../assets/images/user_hover.png";
	 			break;
	 		}
	 		case 'password_hover': {
	 			this.keyIcon = ".../../../assets/images/password_hover.png";
	 			break;
	 		}
	 		case 'logout_hover': {
	 			this.logoutIcon = ".../../../assets/images/logout_hover.png";
	 			break;
	 		}
	 	}
	 }

	/**
	 * Change image src on mouse out
	 * @param imagePath
	 */
	onMouseOut(imagePath: string): void {
	 	switch (imagePath) {
	 		case 'user': {
	 			this.userImage = ".../../../assets/images/user.png";
	 			break;
	 		}
	 		case 'notify': {
	 			this.notify = ".../../../assets/images/notify.png";
	 			break;
	 		}
	 		case 'userIcon': {
	 			this.userIcon = ".../../../assets/images/user.png";
	 			break;
	 		}
	 		case 'password': {
	 			this.keyIcon = ".../../../assets/images/password.png";
	 			break;
	 		}
	 		case 'logout': {
	 			this.logoutIcon = ".../../../assets/images/logout.png";
	 			break;
	 		}
	 	}
	}

    openNav(){
		this.navBarWidth = "240px";
	}

    closeNav(){
		// console.log("here");
		// if(value){
		//     if(value='request'){
		//         this.isRequest = true;
		//         this.isBooking = false;
        //     }
        //     else {
        //         this.isRequest = false;
        //         this.isBooking = true;
        //     }
        //     this.isSelected = value;
        // }

            this.navBarWidth = "0px";

	}
    searchBooking(){
		this.search = true;
	}

    editProfile(){
        this.navBarWidth = "0px";
        this.router.navigate(['customer-profile/edit']);

    }

    handleClick(event){
        let clickedComponent = event.target;
        let inside = false;
        do {
            if (clickedComponent === this.elementRef.nativeElement) {
                inside = true;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);
        if (inside){
            console.log('inside');
        }else {
            this.navBarWidth = "0px";
        }
    }

    getRoutes(){
        this.router.navigate(['transporter/routes']);
	}
	newOrder() {
		this.router.navigateByUrl('/order/addOrder');
	}

	getVehicles(){
		this.router.navigateByUrl('/admin/vehicles');
	}

	getVehiclesList(){
		this.router.navigateByUrl('transporters/list-vehices');
	}


	accountItems(){
		this.showAccountItems = !this.showAccountItems;
	}
}
