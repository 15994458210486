import {Component} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {PlatformLocation} from '@angular/common';
import {utils, writeFile} from 'xlsx';
import {Subject} from 'rxjs';

@Component({
    templateUrl:'./tds-payment.modal.component.html',
    styleUrls:['./transporter-payments.css']
})
export class TdsPaymentsModalComponent {
    payments:any;
    month;
    year;
    totalTds
    modalRef:BsModalService
    public onDownload: Subject<boolean>;
    constructor(private _bsModalRef: BsModalRef,private location: PlatformLocation){
        location.onPopState(() => this._bsModalRef.hide());
    }
    ngOnInit(){
        this.onDownload = new Subject();
    console.log(this.payments);
    }
    cancel(){
        this.onDownload.next(false);
        this._bsModalRef.hide();
    }
    download(){
        let tbl = document.getElementById('payments');
        const wb = utils.book_new();
        let ws = utils.table_to_sheet(tbl)
        const wscols = [
             {wch:20}, {wch:30}, {wch:15}, {wch:15}, {wch:20}, {wch:15}, {wch:30}, {wch:20}

        ];
        ws['!cols'] = wscols;
        utils.book_append_sheet(wb, ws );
        writeFile(wb, 'payments-' + this.month +' '+ this.year+ '.xlsx');
        this.onDownload.next(true);
        this._bsModalRef.hide();
    }

}
