import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
import { Subject } from 'rxjs/Subject';
const url = '/api/v1/';

@Injectable()
export class TransporterSignupService {

 public isUserLoggedIn: boolean = false;
 
  constructor(private http: Http) { }
   
  signup( token: string,transportersData) {
    console.log(".....token",token,transportersData);
    return this.http.post(url+'admin/transporter?token='+token,transportersData)
    .map((response: Response) =>response.json());
  }

  getVehicles(){
    return this.http.get(url+'transporter/vehicle/types').map((response: Response) =>response.json());
  }
}
