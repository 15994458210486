import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent implements OnInit{

    title;
    public onClose: Subject<boolean>;

    constructor(private _bsModalRef: BsModalRef){

    }
    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    onConfirm(){
        this.onClose.next(true);
        this._bsModalRef.hide();
    }

    cancel(){
        this.onClose.next(false);
        this._bsModalRef.hide();
    }

}