import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
const url = '/api/v1/';

@Injectable()
export class TranportersService {
  constructor(private http: Http) { }
  public queryDetails = new Subject();
  transportersResponse(token: string,responseData: any) {
    console.log(".....responseData",responseData);
    return this.http.post(url+'transporter/get/price?token='+token, responseData)
      .map((response: Response) =>response.json());
  }
  getQueryDetail(token: string,queryId: any) {
    // console.log(".....queryId",queryId);
    return this.http.post(url+'tp/queries/transporters/details?token='+token, queryId)
      .map((response: Response) =>response.json());
  }

}
