import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';

const url = '/api/v1/';


@Injectable()

export class VehicleService {

    constructor(private http: Http) {}
    
    getCities(){
        return this.http.get(url + 'transporter/cities').map((response: Response) =>response.json());
    }

    saveVehicle(token: string , body: any){
        return this.http.post(url + 'vehicle' + '?token='+token, body)
            .map((response: Response) =>response.json());
    }

    getVehicleTypes() {
        return this.http.get(url+'transporter/vehicle/types')
            .map((response: Response) =>response.json());
    }

    getTransporterVehicles(token) {
        return this.http.get(url+'vehicles?token='+token)
            .map((response: Response) =>response.json());
    }

}
