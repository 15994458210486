import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {PlatformLocation} from '@angular/common';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@Component({
    selector:'lr-modal',
    templateUrl:'./lr-modal.component.html',
    styleUrls:['./lr-modal.component.css']
})
export class LrModalComponent {
    lrDetail
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    constructor(private _bsModalRef: BsModalRef,private location: PlatformLocation){
        location.onPopState(() => this._bsModalRef.hide());
    }

    ngOnInit(){

    }


    downloadLR(){
        let that = this;
        let promise =  html2canvas(document.getElementById('LR'),{scale:2, backgroundColor:'#ffffff'});

        promise.then(function (canvas) {
            var imgWidth = 297;
            var pageHeight = 210;
            // var imgHeight = canvas.height * imgWidth / canvas.width;
            // var heightLeft = imgHeight;

            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, 295,200, '', 'FAST')
            pdf.save(that.lrDetail.order_number+'-LR'+'.pdf'); // Generated PDF
        });
    }
    cancel(){
        this._bsModalRef.hide();
    }
}