import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
const url = '/api/v1/';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  constructor(private http: Http) { }

  addLead(token: string, body: any) {
        return this.http.post(url+'lead?token='+token, body)
            .map((response: Response) =>response.json());
    }

    updateLead(token: string, body: any) {
        return this.http.put(url+'leads/'+body._id + '?token='+token, body)
            .map((response: Response) =>response.json());
    }

    listLeads( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'leads?token='+token).map((response: Response) =>response.json());
    }

    getCustomers( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/customer/list?token='+token)
            .map((response: Response) =>response.json());
    }

    getLead( token: string, leadId: string) {
        console.log(".....token",token);
        return this.http.get(url+'leads/'+leadId +'?token='+token).map((response: Response) =>response.json());
    }

    listUsers( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/user/list?token='+token)
                .map((response: Response) =>response.json());
    }
}
