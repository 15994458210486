import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
const url = '/api/v1/';

@Injectable()

export class OrderHistoryService {
    constructor(private http: Http) { }
    getOrderList(token, page, size,body){
        return this.http.post(url+'admin/order/list?token='+token+ '&page='+ page + '&size=' + size, body)
            .map((response: Response) =>response.json());
    }
    getInvoiceDetail(token, invoiceId){
        return this.http.get(url + 'admin/invoice/' + invoiceId + '?token=' + token)
            .map((response: Response) =>response.json());
    }

    cancelOrderById(token, orderId){
        return this.http.get(url + 'admin/order/cancel/' + orderId + '?token=' + token)
            .map((response: Response) =>response.json());
    }
    updateVehicleStatus(token,misId,body){
        return this.http.put(url + 'admin/vehicle/status/mis/'+misId +'?token=' + token,body)
}

    saveMis(token: string , body: any){
        return this.http.post(url + 'admin/order/mis' + '?token='+token, body)
            .map((response: Response) =>response.json());
    }

}
