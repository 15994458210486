import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
const url = '/api/v1/';

@Injectable()
export class TransporterRoutesService {

constructor(private http: Http) { }

	getCities(){
		return this.http.get(url+'transporter/cities').map((response: Response) =>response.json());
	}
	getVehicles(){
		return this.http.get(url+'transporter/vehicle/types').map((response: Response) =>response.json());
	}
	listRoutes(token: string,id) {
		return this.http.get(url+'transporter/'+id+'/routes?token='+token)
		.map((response: Response) =>response.json());
	}

	createRoutes(token: string, tranporterId, routeData) {
		console.log(".....token",token,routeData);
		return this.http.post(url+'admin/transporters/'+tranporterId+'/routes?token='+token,routeData)
		.map((response: Response) =>response.json());
	}

	updateTransporter(token: string, tranporterId, transporter) {
		return this.http.put(url+'admin/transporters/'+tranporterId+'?token='+token,transporter)
		.map((response: Response) =>response.json());
	}

	deleteRoute(token: string, tranporterId) {
		return this.http.delete(url+'transporter/routes/'+tranporterId+'?token='+token)
		.map((response: Response) =>response.json());
	}

	updateRoute(token: string, tranporterId, data) {
		return this.http.put(url+'admin/transporters/'+tranporterId+'/routes/'+data.id+'?token='+token,data)
		.map((response: Response) =>response.json());
	}

}
