import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
const url = '/api/v1/';

@Injectable()
export class QueryDetailService {

  constructor(private http: Http) { }
  public queryDetails = new Subject();
  getTransportersForQuery(token: string,queryId: any) {
    console.log(".....token",token);
    return this.http.post(url+'tp/queries/transporters/details?token='+token, queryId)
      .map((response: Response) =>response.json());
  }
  sendResponse(token: string,responseData: any) {
    console.log(".....token",token);
    return this.http.post(url+'transporter/send/response?token='+token, responseData)
      .map((response: Response) =>response.json());
  }
  sendReply(replyData: any, token: string){
      return this.http.post(url+'user/send/rates?token='+token, replyData).map((response: Response) =>response.json());
  }
}
