import { Component, OnInit } from '@angular/core';
import {OrderDetailService} from '../../order-detail/order-detail.service';
import {Subject} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
    salesPersonList;
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    public onCustomerSave: Subject<Object>;
  constructor(private service: OrderDetailService, private _bsModalRef: BsModalRef, private location: PlatformLocation) {
      location.onPopState(() => this._bsModalRef.hide());
  }

  customerModel = {
      name: null,
      phone: null,
      password: '123456',
      confirmPassword: '123456',
      email: null,
      sales_person: null,
      role: 6,
      company: '',
      gst: '',
      address: null
  }
  ngOnInit(){
      this.onCustomerSave = new Subject();
  }

  cancel() {
      this._bsModalRef.hide();
  }
  addValues() {
        this.service.addCustomer(this.user.token, this.customerModel).subscribe(
            res => {
                if (res.response) {
                    this.onCustomerSave.next({severity: 'success', summary: 'Success Message', detail: res.message})
                    this._bsModalRef.hide();
                } else {
                    this.onCustomerSave.next({severity: 'error', summary: 'Error Message', detail: res.message});
                }
            }, err => {
                this.onCustomerSave.next({severity: 'error', summary: 'Error Message', detail: err.message});
            });

  }

}
