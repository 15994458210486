import { Component, OnInit, HostListener,TemplateRef } from '@angular/core';
import {FormControl} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { Message} from 'primeng/components/common/api';
import { MessageService} from 'primeng/components/common/messageservice';
import { QueryDetailService }  from './query-detail.service';
import * as _ from "lodash";

@Component({
    selector: 'app-query-price-detail',
    templateUrl: './query-price-detail.component.html',
    styleUrls: ['./query-price-detail.component.css']
})

export class QueryPriceDetailComponent implements OnInit {

    moreIcon = '../../../assets/images/moreIcon.png';
    constructor(private service: QueryDetailService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,) {
        this.route.queryParams.subscribe(
            res =>{
                console.log('id',res.query_id);
                this.queryId = res.query_id;
            },err =>{
                console.log('err',err);
            }
            )
        this.getTrasportersForQuery();

    }
    columnsForTransporters = ['price','name','location','available_vehicles', 'vehicle_details','contact_person','contact_numbers','action'];
    // dataForTransporters =  new MatTableDataSource(ELEMENT_DATA);
    queryData: any = [
    'From',
    'To',
    'Vehicle Type',
    'Number Of Vehicle',
    'Date',
    'Price',
    'Material Description'

    ]
    modalRef: BsModalRef;
    queryId: any;
    errorMessage: any;
    errorMsg: any;
    currentQueryId: any ='';
    replyData: any = {};
    accepted: boolean = false;
    rejected: boolean = false;
    currentId: string = '';
    successMsg: Message[] = [];
    innerWidth: any;
    isMobileView: boolean = false;
    blankResponse: boolean = false;
    queryDetail: any = {} ;
    price: number;
    margin: number;
    price_details: any = "";
    dataForTransporters : MatTableDataSource<any>;
    all_transporters: any = [];
    selected_tp: any = {};
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
        console.log(innerWidth);
        if (this.innerWidth > 480) {
            this.isMobileView = false;
        }else{
            this.isMobileView = true;
        }
    }
    ngOnInit() {  
        this.innerWidth = window.screen.width;
        console.log(innerWidth);
        if (this.innerWidth > 480) {
            this.isMobileView = false;
        }else{
            this.isMobileView = true;
        }
       
    }
    ngAfterViewInit(){ 

    }
    openReplyModal(template: TemplateRef<any>,query_id: string,price) {
        this.currentQueryId = query_id;
        this.price = price;
        console.log('),,.,.,',this.currentQueryId);
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
            );
    }
    openAddressModal(template: TemplateRef<any>,query_id: string,price) {
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
            );
    }
    openDetailModal(template: TemplateRef<any>,id: string){
     
        let index =  _.findIndex(this.all_transporters, function(curr_obj){  return curr_obj.id._id.toString() == id.toString()});
        this.selected_tp = this.all_transporters[index].vehicle_details;
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
            );
    }
    getTrasportersForQuery(){
        this.service.getTransportersForQuery(this.user.token,{query_id: this.queryId}).subscribe(
            res =>{
                console.log('data of transporters',res.data);
                let query = res.data
                if (query) { 
                    this.queryDetail = {
                        txn_id: query.txn_id,
                        from: query.from,
                        to: query.to,
                        vehicle_type:query.vehicle_type,
                        date: query.date,
                        price: query.price.length > 0  ? {value:query.price[query.price.length-1].value,
                        user_response:query.price[query.price.length-1].user_response || "Not Recieved",
                        _id:query.price[query.price.length-1]._id ,price_details:query.price[query.price.length-1].price_details}: {value:0,user_response:'' },
                        no_of_vehicle: query.no_of_vehicle,
                        material: query.material,
                        loading: query.loading,
                        unloading: query.unloading
                    }
                    console.log('trans vehicle detail', this.queryDetail);
                }

                if (res.data.transporters.length > 0) {
                    this.blankResponse = false;
                    let dataArray = [];
                    res.data.transporters.forEach((item,index) => {
                        let obj = {
                            _id: item.id._id,
                            name: item.id.user.name,
                            location: item.id.address,
                            contact_person: item.id.user.name,
                            contact_numbers: item.id.contact_numbers,
                            price: item.price,
                            price_details: item.price_details,
                            available_vehicles: item.available_vehicles,
                            tp_response: item.tp_response,
                            vehicle_details: item.vehicle_details

                        }
                        this.all_transporters.push(item);
                        dataArray.push(obj);
                    })
                    console.log('dataForTransporters',dataArray);
                    this.dataForTransporters = new MatTableDataSource(dataArray);                    
                }else{
                    this.blankResponse = true;
                }
               
            },err =>{
                console.log('err',err);
            }
            )
    }
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); 
        filterValue = filterValue.toLowerCase(); 
        this.dataForTransporters.filter = filterValue;
    }
    sendResponse(id,tpResponse){
        this.currentId = id;
        let responseData = {
            query_id: this.queryId,
            tp_id: id,
            tp_response: tpResponse
        }
        console.log('(this.user.token,responseData)',responseData);
        this.service.sendResponse(this.user.token,responseData).subscribe(
            res =>{
                this.getTrasportersForQuery();
                if (tpResponse == 'accept') { 
                   this.accepted = true;
                   this.rejected = false;
                } else {
                   this.accepted = false;
                   this.rejected = true;
                }
                if (res.response) {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
                }else{
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
                }
            },err =>{
                console.log('err',err);
            }
            ) 
    }
    sendReply(){
        let percentagePrice = this.price*this.margin;
        this.replyData.price = this.price + percentagePrice/100;
        this.replyData.price_details = this.price_details ? this.price_details : "";
        this.replyData.query_id = this.queryId;
        console.log("...this.replyData",this.replyData);
        this.service.sendReply(this.replyData, this.user.token).subscribe(
            res => {
                console.log("...reply",res);
                this.queryDetail.price = res.data && res.data.price.length > 0   ? res.data.price[res.data.price.length-1] : {value:0,user_response:''}
                this.modalRef.hide();
                this.margin = null;
                this.replyData.price = null;
                this.successMsg = [];
                this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message}); 
            },err => {
                console.log(err);
                this.errorMessage = err.json().message;
                this.errorMsg = [];
                this.errorMsg = [{severity:'error',detail: this.errorMessage}];
            }
            )
    }
    
}
// const ELEMENT_DATA: PeriodicElement[] = [
// {name: 'Hydrogen',from: 'delhi',to: 'faridabad',location: 'delhi/ncr',vehicle_type: 'type1', contact_person: 'xyz', contact_number: 12334455},
// {name: 'Hydrogen',from: 'delhi',to: 'faridabad',location: 'delhi/ncr',vehicle_type: 'type2', contact_person: 'xyz', contact_number: 12334455}
// ];
// export interface PeriodicElement {
//     name: string;
//     from: string;
//     to: string;
//     location: string;
//     vehicle_type: string;
//     contact_person: string;
//     contact_number: number;
// }



