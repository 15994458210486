import { Component, OnInit, ViewChild } from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {VehicleService} from './vehicle.service';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {} from 'googlemaps';
import {Message} from 'primeng/api';
import { forEach } from '@angular/router/src/utils/collection';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

  @ViewChild('map') mapElement: any;
    map: google.maps.Map;


    lat:number = 28.7041;
    lng: number = 77.1025;

    vehicleList = [];


  fromOptions = [];
  toOptions = [];
  fromOption: Observable<string[]>;
  toOption: Observable<string[]>;
  vehicle: boolean = false;
  successMsg: Message[] = [];
  vehicleTypeList = [];

  image = '../../../assets/images/truck';
  user: any = JSON.parse(localStorage.getItem('currentUser'));
    vehicleForm = new FormGroup({
        vehicle_number: new FormControl(''),
        from: new FormControl(''),
        to: new FormControl(''),
        price: new FormControl(''),
        vehicle_type:new FormControl(''),
        vehicle_availability: new FormControl('')

    });
   
 
  constructor(private vehicleService : VehicleService,
    private route: ActivatedRoute,
                private router: Router) { }

                
  ngOnInit() {
    let that = this;
    let myLatLng = {lat: this.lat, lng: this.lng};
  
    const mapProperties = {
      center: new google.maps.LatLng(this.lat,this.lng),
      zoom: 8,
      mapTypeId: google.maps.MapTypeId.ROADMAP
      
 };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);


    
 /**     var infowindow = new google.maps.InfoWindow();
     var infowindowContent = document.getElementById('infowindow-content');
     infowindow.setContent(infowindowContent);
**/




      this.vehicleTypes();
      this.getVehiclesOnMap();
      var from = <HTMLInputElement>document.getElementById('from');
      var to = <HTMLInputElement>document.getElementById('to');



    //  var infowindow = new google.maps.InfoWindow();
      //var infowindowContent = document.getElementById('infowindow-content');
     // infowindow.setContent(infowindowContent);

      var options = {
          types: ['(cities)'],
          componentRestrictions: {country: 'in'}
      };
      let autocompleteFrom = new google.maps.places.Autocomplete(from, options);
      autocompleteFrom.addListener('place_changed', function () {
          let place = autocompleteFrom.getPlace();

          that.vehicleForm.get('from').setValue(place.address_components[0].long_name);
          // that.body.latFrom = place.geometry.location.lat();
          // that.body.longFrom = place.geometry.location.lng();


      })
      let autocompleteTo = new google.maps.places.Autocomplete(to, options);
      autocompleteTo.addListener('place_changed', function () {
          let place = autocompleteTo.getPlace();
          console.log(place);
          that.vehicleForm.get('to').setValue(place.address_components[0].long_name);
      })
  
  }

  

  saveVehicle(){   
      let post_data = this.vehicleForm.value;
      post_data['transporter_id'] = this.user.transporter._id;

      this.vehicleService.saveVehicle(this.user.token, post_data).subscribe(
      res => {
     
          console.log(res);
    
          if (res.response) {
            //  this.successMsg = [];
          
             this.successMsg.push({severity:'success', summary:'Success Message', detail:'Vehicle Saved Successfully'});
             this.vehicleForm.reset();      
          } else {
              this.successMsg = [];
              this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
          }
      }, error1 => {
          console.log(error1);
      });
}


vehicleTypes() {
  this.vehicleService.getVehicleTypes().subscribe(
      res => {
          console.log(res);
          this.vehicleTypeList = res.data.vehicleOptions;
      }
  )
}

  expandVehicle() {
    this.vehicle = !this.vehicle;
}

omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k==8 || k!=32 || (k >= 48 && k <= 57)); 
}



getVehiclesOnMap(){
      let that = this;
     let res =   this.vehicleService.getTransporterVehicles(this.user.token).subscribe(
         res =>{
          
console.log(res);
                       this.vehicleList = res.data; 
             let that = this;
             this.vehicleList.forEach(function (vehicle) {
                 let marker = new google.maps.Marker({
                     position: new google.maps.LatLng(parseFloat(vehicle.lat),parseFloat(vehicle.long)),
                     icon: '../../../../assets/images/red-truck2.png',
                     map: that.map,
                     title: vehicle.transporter.name + ": " + vehicle.vehicleNumber 
                 });
                

               
                 var contentString =
                 '<p><b>Transporter Name</b>: ' + vehicle.transporter.name + '</br>' +
                 '<b>Vehicle Number</b>: ' + vehicle.vehicleNumber + '</br>' +'</p>';
                 var infowindow = new google.maps.InfoWindow({
                  content: contentString
                });

                 marker.addListener('click', function() {
                  infowindow.open(this.map, marker);
                });
              });
                 //google.maps.event.addListener(marker, 'click', function () {
                   //this.infoWindow.setContent(this.title);              
                   //this.infowindowContent.children['transporter-name'].textContent = vehicle.transporter.name;
                   //this.infowindowContent.children['vehicle-number'].textContent = vehicle.vehicleNumber;
                   //this.infoWindow.open(map, marker);
              //});            

         }
    )
}
}


/**
 * 
 * 
 * 
 */