import {Injectable} from "@angular/core";
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
const url = '/api/v1/';

    @Injectable()
export class UserListService {
    constructor(private http: Http) {}

    listUsers( token: string) {
                console.log(".....token",token);
                return this.http.get(url+'admin/user/list?token='+token)
                        .map((response: Response) =>response.json());
            }

        userUpdate( token: string,body) {
            console.log(".....token",token);
            return this.http.put(url+'admin/customer/profile?token='+token,body)
                .map((response: Response) =>response.json());
        }

        addUser( token: string, body: any) {
            console.log(".....token",token);
            return this.http.post(url+'admin/customer?token='+token, body)
                .map((response: Response) =>response.json());
        }

        deleteUser( token: string, userId: any) {
            console.log(".....token",token);
            return this.http.get(url+ 'admin/delete/user/' + userId + '?token=' + token)
                .map((response: Response) =>response.json());
        }
        getUser(token,userId:any){
            return this.http.get(url+ 'admin/user/detail' +'?token=' + token + '&id='+userId)
                .map((response: Response) =>response.json());
        }
        getUserOrders(token, userId){
            return this.http.get(url+ 'user/orders/' + userId +'?token=' + token)
                .map((response: Response) =>response.json());
        }
        misDetail(token, body){
            return this.http.post(url+'admin/create/mis?token='+token, body)
                .map((response: Response) =>response.json());
        }
        saveInvoice(token: string,invoiceData) {
            return this.http.post(url+'admin/save/invoice?token='+token, invoiceData)
                .map((response: Response) =>response.json());
        }
        getInvoiceDetail(token, invoiceId){
            return this.http.get(url + 'admin/invoice/' + invoiceId + '?token=' + token)
                .map((response: Response) =>response.json());
        }
        invoiceList(token,customer){
            return this.http.get(url + 'admin/invoice/list/' + '?token=' + token +'&customer='+customer)
                .map((response: Response) =>response.json());
        }
        addOrdersToinvoice(token, orders){
            return this.http.post(url + 'admin/invoice/add/order/' + '?token=' + token, orders)
                .map((response: Response) =>response.json());
        }
        salesPersonList(token){
            return this.http.get(url + 'admin/sales/person/list'+'?token=' + token)
                .map((response: Response) =>response.json());
        }


    }