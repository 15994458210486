import { Component, OnInit} from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.services';
import {Message} from 'primeng/components/common/api';
import {MessageService} from 'primeng/components/common/messageservice';

@Component({
  selector: 'app-transporter-signup',
  templateUrl: './transporter-signup.component.html',
  styleUrls: ['./transporter-signup.component.css'],
  providers: [MessageService]
})
export class TransporterSignupComponent implements OnInit {
	constructor(
		private service: AuthenticationService,
		private route: ActivatedRoute,
		private router: Router
	) { }

 	transporter: any =  { }
	userToken: any = JSON.parse(localStorage.getItem('currentUser'));

	public errorMessage: string = null;
	public showError: boolean = false;
	public isOtpTabOpen: boolean = false;
	successMsg:  Message[] = [];
	errorMsg:  Message[] = [];
	
	
	ngOnInit() {
	}	
	
	getOTP(phone: string) {
		if(!this.transporter.authcode){
			this.transporter.role = 7;
			this.service.getOTP(this.transporter.phone)
			.subscribe(
				res => {
					console.log(res);
					if(!res.response && res.message == "User already exists"){
						this.showError = true;
						this.errorMsg.push({severity: 'error',summary:'Error Message',detail: "User account already exists"});
					}
					else{
						this.isOtpTabOpen = true;					
					}
				},
				error => {
					console.log(error.json() , typeof error._body);
					this.errorMessage = error.json().message;
					// this.showError = true;
					this.errorMsg.push({severity: 'success',summary:'Success Message',detail: this.errorMessage});
				}
			);
		}
		
	}
	
	signup() {
		console.log("...this.transporter",this.transporter);
		this.transporter.authcode = this.transporter.authcode.toString();
		this.service.signup(this.transporter)
		.subscribe(
			user => {
				console.log(user.message);
				if (user.response) {
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: user.message});
					setTimeout( ()=> {
						this.router.navigate(['/signin']);
					},2000)
				}else{
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: user.message});
					this.isOtpTabOpen = false;
				}
			},
			error => {
				console.log(error.json() , typeof error._body);
				this.successMsg = [];
				this.errorMessage = error.json().message;
				this.successMsg.push({severity: 'error',summary:'Error Message',detail: this.errorMessage});
				
				// this.showError = true;
			});
	}
}
