import {Component, OnInit, Input,Directive, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AllRoutesService } from './all-routes.service';
import * as _ from "lodash";
import { Message} from 'primeng/components/common/api';

@Component({
  selector: 'app-all-routes',
  templateUrl: './all-routes.component.html',
  styleUrls: ['./all-routes.component.css']
})
export class AllRoutesComponent {

  	user: any = JSON.parse(localStorage.getItem('currentUser'));
    cols: any[];
    
	allVehicles: any = [];
	routes: any = [];
	loaded:boolean = false;
	constructor(
		private router: Router,
		private service: AllRoutesService
		){}

	roleIcon : string = '.../../../app/assets/images/roleIcon.png';
	addIcon: any = ".../../../app/assets/images/add.png";
	moreIcon : string = '.../../../app/assets/images/moreIcon.png';
	dividerIcon: any = ".../../../app/assets/images/divider.png";
	plusIcon: any = ".../../../app/assets/images/plusIcon.png";

	ngOnInit(){
		this.cols = [
			{ field: 'fromState', header: 'From State' },
            { field: 'fromCity', header: 'From City' },
            { field: 'toState', header: 'To State' },
            { field: 'toCity', header: 'To City' },            
            { field: 'vehicleType', header: 'Vehicle Type' },
            { field: 'name', header: 'Name' },
            { field: 'company', header: 'Compnay' },
            { field: 'address', header: 'Transporter Address' },
            { field: 'phone', header: 'Contact Number' }
           
        ];
        this.service.getVehicles().subscribe(
        	res => {
        		this.allVehicles = res.data.vehicleOptions;
        		console.log(this.allVehicles);
        	},err => {
			}
		)

        this.service.listRoutes(this.user.token).subscribe(
			res => {
				let that = this;
				this.routes = [];
				_.each(res.data, function(route, index){
					let temp_route = {};
					if(route){
						temp_route['fromState'] = route.fromAddress.split(",")[1].trim();
						temp_route['fromCity'] = route.fromAddress.split(",")[0];
						temp_route['toState'] = route.toAddress.split(",")[1].trim();
						temp_route['toCity'] = route.toAddress.split(",")[0];
						temp_route['vehicleType'] = route.vehicle_type;
						temp_route['name'] = route.name;
						temp_route['company'] = route.company;
						temp_route['address'] = route.address;
						temp_route['phone'] = route.contact_numbers[0];
						that.routes.push(temp_route);
					}

				})
				this.loaded = true;
				console.log(this.routes);
				
			},err => {
			}
			)
	}

 
}
