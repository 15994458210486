import {Component,EventEmitter} from '@angular/core';
import {AccountsService} from '../accounts.service';
import {InvoiceModalComponent} from '../../common/invoice/invoice-modal.component';
import {BsModalService} from 'ngx-bootstrap';
import {Router} from '@angular/router';
import {Message} from 'primeng/api';

import {Output} from '@angular/core';
import {ConfirmationModalComponent} from '../../common/confirmation/confirmation-modal.component';

@Component({
    selector:'invoice-list',
    templateUrl:'invoice-list.component.html',
    styleUrls: ['invoice-list.component.css']
})
export class InvoiceListComponent {
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    data = {
        total_invoices:null,
        invoices:[]
    }
    successMsg: Message[] = [];
    size=10;
    page=1;
    searches = {
        startDate:null,
        endDate:null,
        customerName:null,
        invoiceSequence:null
    }

    invoiceStatus =  [{id:1,value:'invoice raised'},{id:2,value:'overdue'},{id:3,value:'partially paid'},{id:4,value:'paid'}]
    @Output() response = new EventEmitter<any>();
    constructor(private service:AccountsService,private modalService: BsModalService,private router: Router){

    }

    ngOnInit(){
        this.invoiceListData();
    }

    customerDetail(customerId){
        this.router.navigate(['admin/user/' + customerId + '/details']);
    }
    showInvoice(invoiceId) {
        const that = this;
        this.service.getInvoiceDetail(this.user.token, invoiceId).subscribe(
            res =>{
                const invoiceDetail = res.data;
                const initialState = {
                    invoiceId: invoiceId,
                    invoiceDetail : invoiceDetail
                };
                console.log(initialState);
                let modalref = this.modalService.show(InvoiceModalComponent,{class:'modal-lg',initialState});
            });
    }

    deleteInvoice(invoiceId,index){
        const that = this;
        const initialState = {
            title: 'Do you really want to delete this invoice?'
        };
        let modalReference = this.modalService.show(ConfirmationModalComponent,{initialState});
        modalReference.content.onClose.subscribe(result => {
            if(result){
                this.service.deleteInvoice(this.user.token,invoiceId).subscribe(
                    res =>{
                        if(res.response){

                            that.response.emit({severity: 'success', summary: 'Success Message', detail: res.message});
                            that.data.invoices.splice(index,1);
                        }
                        else{
                            that.response.emit({severity: 'error', summary: 'Error Message', detail: 'Not deleted'});
                        }
                    },error1 => {
                        that.response.emit({severity: 'error', summary: 'Error Message', detail: error1.message});
                    }
                );
            }
            else{

            }
        })


    }
    filter(startDate?,endDate?) {
        let that = this;
        if (startDate) {
            that.searches.startDate = startDate;
            that.searches.startDate.setHours(0, 0, 0, 0);
        }
        if (endDate) {
            that.searches.endDate = endDate;
            that.searches.endDate.setHours(23, 59, 59, 0);
        }
        this.invoiceListData();
    }
    clearFilter(){
        this.searches.startDate = null;
        this.searches.endDate = null;
        this.invoiceListData();
    }

    pageChangeApi(page){
        this.page = page;
        this.invoiceListData();
    }


    searchString(){
        let searchString = '&size='+this.size + '&page='+this.page;
        for(let key in this.searches){
            if(this.searches[key]!=null){
               searchString = searchString + '&'+key +'=' + this.searches[key]
            }
        }
        return searchString
    }


    filterListKeyUp(){
        this.invoiceListData()
    }

    filterListKeyDown(){
        let that = this;
        setTimeout(function() {
            that.invoiceListData()
        },1);


    }
     invoiceListData(){
         this.service.getInvoiceList(this.user.token,this.searchString()).subscribe(res=>{
             if(res.response){
                 this.data = res.data;
             }
         });
     }
}
