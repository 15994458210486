import {Component,EventEmitter, Output} from '@angular/core';
import {AccountsService} from '../accounts.service';
import {utils, writeFile} from 'xlsx';
import {Message} from 'primeng/api';

@Component({
    selector:'transporter-unpaid-payments',
    templateUrl:'transporter-unpaid-payments.component.html',
    styleUrls:['./transporter-payments.css']
})
export class TransporterUnpaidPaymentsComponent {
    payments = {
        totalCount:null,
        payments:[]
    }
    searches = {
        startDate:null,
        endDate:null,
        customerName:null,
        orderNumber:null
    }
    size = 10;
    page = 1;
    body ={
        payment_types:[1,2,4]
    }
    isSelected = false;
    selectedItems: any = [];
    successMsg: Message[] = [];
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    @Output() paymentStatusUpdateResponse = new EventEmitter<any>();
    constructor(private service:AccountsService){

    }
    ngOnInit(){
        this.getPayments();

    }
    getPayments(){


        let promise = this.service.transporterPayments(this.user.token,this.body,this.searchString()).subscribe(res =>{
            if(res.response){
                console.log(res);
                this.payments = res.data;

            }
        })
    }

    updatePaymentStatus(paymentId,paymentStatus,index){
        let that = this;
        let body = {
            _id:paymentId,
            payment_status:paymentStatus
        }
        let promise = this.service.updatePaymentStatus(this.user.token,body).subscribe(res =>{
            if(res.response){
                let found = that.body.payment_types.find(function (item) {
                    return item==paymentStatus;
                })
                if(!found)
                this.payments.payments.splice(index,1);
                console.log(res);

                    this.paymentStatusUpdateResponse.emit({severity: 'success', summary: 'Success Message', detail: res.message});
            }
            else{
                this.paymentStatusUpdateResponse.emit({severity: 'error', summary: 'Error Message', detail: res.message});
            }
        })
    }

    onCheckboxClick(event, payment,index) {
        if (event) {
            this.selectedItems.push(payment);
        }
        else {
            this.selectedItems.splice(index,1);
        }
        console.log(this.selectedItems);
    }


    generateCMS(){
        let items = []
        let that = this;
        this.selectedItems.forEach(function (item) {
            let  ob  = {
                '':'ZENDSPT',
                'Product_Code':'RPAY',
                'Payment_Type':'',
                'Payment_Ref_No.':'',
                'Payment_Date':that.dateFormating(item.date),
                'Instrument Date':'',
                'Dr_Ac_No':'5711703493',
                'Amount':item.payable_amount+'',
                'Bank_Code_Indicator':'M',
                'Beneficiary_Code':'',
                'Beneficiary_Name':item.transporter_account_name,
                'Beneficiary_Bank':'',
                'Beneficiary_Branch / IFSC Code':item.transporter_bank_ifsc_code,
                'Beneficiary_Acc_No':','+item.transporter_account_number,
                'Location':'',
                'Print_Location':'',
                'Instrument_Number':'',
                'Ben_Add1':'',
                'Ben_Add2':'',
                'Ben_Add3':'',
                'Ben_Add4':'',
                'Beneficiary_Email':'',
                'Beneficiary_Mobile':'',
                'Debit_Narration':item.order_number+'  ' +item.transporter_name,
                'Credit_Narration':'',
                'Payment Details 1':'',
                'Payment Details 2':'',
                'Payment Details 3':'',
                'Payment Details 4':'',
                'Enrichment_1':'',
                'Enrichment_2':'',
                'Enrichment_3':'',
                'Enrichment_4':'',
                'Enrichment_5':'',
                'Enrichment_6':'',
                'Enrichment_7':'',
                'Enrichment_8':'',
                'Enrichment_9':'',
                'Enrichment_10':'',
                'Enrichment_11':'',
                'Enrichment_12':'',
                'Enrichment_13':'',
                'Enrichment_14':'',
                'Enrichment_15':'',
                'Enrichment_16':'',
                'Enrichment_17':'',
                'Enrichment_18':'',
                'Enrichment_19':'',
                'Enrichment_20':''



            }
            items.push(ob);
        })

        if (items && items.length) {
            const wb = utils.book_new();
            const ws = utils.json_to_sheet(items,{dateNF: 'MM.DD.YYYY'});
            const wscols = [
                {wch:20}, {wch:20}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30},
                {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:30},
                {wch:30}, {wch:30}, {wch:30}, {wch:30}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20},
                {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20},
                {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}

            ];
            const wsrows = [];
            ws['!cols'] = wscols;
            utils.book_append_sheet(wb, ws );
            writeFile(wb,  'CMS-'+ that.dateFormating(new Date()) + '.xlsx');
            this.successMsg = [];
            this.successMsg.push({severity: 'success', summary: 'Success Message', detail: 'CMS downloaded'});

        }
        else{
            this.successMsg = [];
            this.successMsg.push({severity: 'error', summary: 'Error Message', detail: 'CMS detail missing'});
        }


    }


    dateFormating(dateValue){
        if(dateValue){
            let date = new Date(dateValue);
            return date.getDate() +'.' +(date.getMonth()+1) + '.'  +date.getFullYear()
        }

        return '';

    }
    filter(startDate?,endDate?) {
        let that = this;
        if (startDate) {
            that.searches.startDate = startDate;
            that.searches.startDate.setHours(0, 0, 0, 0);
        }
        if (endDate) {
            that.searches.endDate = endDate;
            that.searches.endDate.setHours(23, 59, 59, 0);
        }
        this.getPayments();
    }
    searchString(){
        let searchString = '&size='+this.size + '&page='+this.page;
        for(let key in this.searches){
            if(this.searches[key]!=null){
                searchString = searchString + '&'+key +'=' + this.searches[key]
            }
        }
        return searchString
    }

    filterListKeyUp(){
        this.getPayments();
    }

    filterListKeyDown(){
        let that = this;
        setTimeout(function() {
            that.getPayments();
        },1);


    }

    clearFilter(){
        this.searches.startDate = null;
        this.searches.endDate = null;
        this.getPayments();
    }

    pageChangeApi(page){
        this.page = page;
        this.getPayments()
    }
}
