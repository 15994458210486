import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
 
import { SigninComponent } from './modules/user/signin/signin.component';
import { SignupComponent } from './modules/user/signup/signup.component';
import { ForgotPasswordComponent } from './modules/user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/user/reset-password/reset-password.component';
import { TransportersFormComponent } from './modules/transporters-form/transporters-form.component';
import { QueryPriceDetailComponent } from './modules/query-price-detail/query-price-detail.component';
import { TransportersVehicleDetailComponent } from './modules/transporters-vehicle-detail/transporters-vehicle-detail.component';
import { UserAddressComponent } from './modules/user-address/user-address.component';
import { ResponseBackComponent } from './modules/response-back/response-back.component';
import { NewRequestComponent } from './modules/new-request/new-request.component';
import { HistoryComponent } from './modules/history/history.component';
import { TransportersComponent } from './modules/admin/transporters/transporters.component';
import { AddTransportersComponent } from './modules/admin/add-transporters/add-transporters.component';
import { TransporterRoutesComponent } from './modules/transporter-routes/transporter-routes.component';
import { TransportersDetailsComponent } from './modules/admin/transporters-details/transporters-details.component';
import { TransporterBookingsComponent } from './modules/transporter-bookings/transporter-bookings.component';
import { TransporterSignupComponent } from './modules/user/transporter/transporter-signup/transporter-signup.component';
import { UserListComponent } from "./modules/admin/user/user-list.component";
import { AllRoutesComponent } from "./modules/all-routes/all-routes.component";
import {CustomerProfileComponent} from './modules/user/profile/customer-profile.component';
import {OrderDetailComponent} from './modules/admin/order-detail/order-detail.component';
import {OrderHistoryComponent} from './modules/admin/order-history/order-history.component';
import {UserDetailsComponent} from './modules/admin/user/user-details.component';
import {RegisterComponent} from './modules/register/register.component';
import {CustomerPaymentsComponent} from './modules/admin/accounts/customer-payments/customer-payments.component';
import {TransporterPayments} from './modules/admin/accounts/transporter-payments/transporter-payments';
import { ProjectionComponent } from './modules/projection/projection.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { TransporterUnpaidPaymentsComponent } from './modules/admin/accounts/transporter-payments/transporter-unpaid-payments.component';
import { TransporterPaidPaymentsComponent } from './modules/admin/accounts/transporter-payments/transporter-paid-payments.component';
import { LeadsComponent } from './modules/leads/leads.component';
import { EditLeadComponent } from './modules/leads/edit-lead/edit-lead.component';

import {AddOrderComponent} from './modules/admin/order-detail/add-order.component';
import { VehicleComponent } from './modules/admin/vehicle/vehicle.component';
import {ListVehiclesComponent} from './modules/admin/list-vehicles/list-vehicles.component';
//import { AuthGuard } from './app.auth-guard.service';

const routes: Routes = [
	{ path: '',  redirectTo: '/new-request', pathMatch: 'full'},
	{ path: 'new-request', component: NewRequestComponent },
	{ path: 'bookings', component: HistoryComponent },
	{ path: 'admin/transporters', component: TransportersComponent },
	{ path: 'admin/transporters/new', component: AddTransportersComponent },
	{ path: 'admin/transporters/:id/details', component: TransportersDetailsComponent },
    { path: 'admin/user/:id/details', component: UserDetailsComponent },
	{ path: 'admin/all-routes', component: AllRoutesComponent },
	{ path: 'admin/vehicles', component: VehicleComponent},
	{path:  'transporters/list-vehices', component: ListVehiclesComponent},
	{ path: 'transporter/routes', component: TransporterRoutesComponent },
	{ path: 'transporters/:id/bookings', component: TransporterBookingsComponent },
	{ path: 'transporters/signup', component: TransporterSignupComponent },
	{ path: 'transporters-form', component: TransportersFormComponent },
	{ path: 'query-price-detail', component: QueryPriceDetailComponent },
	{ path: 'transporters-vehicle-detail', component: TransportersVehicleDetailComponent },
	{ path: 'user-address', component: UserAddressComponent },
    { path: 'user-list', component: UserListComponent},
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'signup', component: SignupComponent },
	{ path: 'signin', component: SigninComponent },
	{ path: 'signout', component: SigninComponent },
	{ path: 'response-back', component: ResponseBackComponent },
	{ path: 'customer-profile/edit', component: CustomerProfileComponent},
	{ path: 'order/detail/:id', component: OrderDetailComponent},
	{ path: 'order/addOrder', component: AddOrderComponent},
	{ path: 'admin/order/history/list', component: OrderHistoryComponent},
	{ path: 'admin/registers', component: RegisterComponent},
	{ path: 'admin/accounts/transporter-payments', component: TransporterPayments,
	children:[ 
        { path: 'unpaid-payments', component: TransporterUnpaidPaymentsComponent},
        { path: 'paid-payments', component: TransporterPaidPaymentsComponent}
      ]
	},
	{ path: 'admin/accounts/customer-payments', component: CustomerPaymentsComponent},
	{ path: 'projections', component: ProjectionComponent},
	{ path: 'dashboard', component: DashboardComponent},
	{ path: 'leads', component: LeadsComponent},
	{ path: 'leads/edit/:id', component: EditLeadComponent},
	// { path: 'admin/transporters', component: TransportersComponent },
	// otherwise redirect to home
	{ path: '**', redirectTo: '',  pathMatch: 'full' }
	
		
];

@NgModule({
	imports: [ RouterModule.forRoot(routes),
	RouterModule.forChild(routes) ],
	exports: [ RouterModule ]
})


export class AppRoutingModule {}

