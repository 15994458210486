import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
const url = '/api/v1/';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: Http) { }

   listProjections( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'projections?token='+token).map((response: Response) =>response.json());
    }

    listOrders( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/dashboard/orders?token='+token).map((response: Response) =>response.json());
    }

    aggregatedLeads( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/dashboard/leads?token='+token).map((response: Response) =>response.json());
    }

    listLeads( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'leads?token='+token).map((response: Response) =>response.json());
    }
}
