import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
const url = '/api/v1/';

@Injectable({
  providedIn: 'root'
})
export class ProjectionService {

  	constructor(private http: Http) { }

  	addProjection(token: string, body: any) {
        return this.http.post(url+'projection?token='+token, body)
            .map((response: Response) =>response.json());
    }

    updateProjection(token: string, body: any) {
        return this.http.put(url+'projection/'+body._id + '?token='+token, body)
            .map((response: Response) =>response.json());
    }

    listProjections( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'projections?token='+token).map((response: Response) =>response.json());
    }

    getCustomers( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/customer/list?token='+token)
            .map((response: Response) =>response.json());
    }

}
