import { Component, OnInit, TemplateRef } from '@angular/core';
import { RegisterService } from './register.service';
import { Message} from 'primeng/components/common/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as _ from "lodash";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(
  	private registerService: RegisterService,
  	private modalService: BsModalService
  ) { }
  user: any = JSON.parse(localStorage.getItem('currentUser'));
  start: Number = 0;
  end: Number = 0;
  successMsg: Message[] = [];
  registers: any = [];
  cols: any[];
  modalRef: BsModalRef;
  teritarry: any  = ["Delhi", "Mumbai"]
  newLr: any = {};

  ngOnInit() {

  	this.cols = [
            { field: 'start', header: 'Start From' },
            { field: 'end', header: 'End At' },
            { field: 'available', header: 'Available LR' },
            { field: 'isClosed', header: 'isClosed' },
            { field: 'Delete', header: 'Delete' }
     
        ];
        this.getLr();
  	}
  	getLr() {
  		this.registerService.getLr(this.user.token).subscribe(
        res => {
            console.log('registers ',res);
            if (!res.response){
                this.successMsg = [];
                this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
            }
            else {
            	this.registers = [];
            	let that = this;

            	_.each(res.data, function(register, index){
                	let temp_register = {};
                	console.log(register);
					if(register){
						temp_register['start'] = register.start;
						temp_register['end'] = register.end;
						temp_register['available'] = register.available_Lrs.length;
						temp_register['isClosed'] = register.isClosed;
						temp_register['id'] = register._id;
						temp_register['Delete'] = "";
						that.registers.push(temp_register);
					}

				})            
            }
        },err => {
            console.log('err',err);
        })
  	}
  	showDialogToAdd(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template,
			Object.assign({}, { class: 'gray modal-md' })
			);
	}

	createLR(){
		this.successMsg = [];
		console.log(this.newLr);
		if(this.newLr.end > this.newLr.start){
			this.newLr.register_type = "LR";
			this.registerService.generateLr(this.newLr, this.user.token).subscribe(
		        res => {
		        	console.log('registers ',res);
		        	if(res.response){		        		
			            this.successMsg.push({severity: 'success',summary:'Success',detail: "LR generated successfuly"});
			            this.getLr();
			            this.modalRef.hide();	
		        	}
		        	else if(!res.response){
		        		this.successMsg.push({severity: 'error',summary:'Error',detail: res.message});
			            this.modalRef.hide();
		        	}
		            
		        },err => {
		            console.log('err',err);
		            this.successMsg.push({severity: 'error',summary:'Error in LR generation',detail: ""});
		        })	
		}
		else{
			this.successMsg.push({severity: 'error',summary:'Error Message',detail: "End number can not be greater than start number"});
		}			  	
	}

	deleteLr(lrData){
		this.successMsg = [];
		console.log(lrData);
		if((lrData.end - lrData.start + 1) == lrData.available){
			if(confirm("This list will get deleted permanently, dou want to proceed?")){
				this.registerService.deleteLr(lrData.id, this.user.token).subscribe(
		        res => {
		        	if(res.response){		        		
			            this.successMsg.push({severity: 'success',summary:'LR deleted successfuly',detail: ""});
			            this.getLr();
		        	}
		        	else if(!res.response){
		        		this.successMsg.push({severity: 'error',summary:'Error',detail: res.message});
		        	}
		            
		        },err => {
		            console.log('err',err);
		            this.successMsg.push({severity: 'error',summary:'Error in LR deletion',detail: ""});
		        })	
			}
				
		}
		else{
			console.log("error");
			this.successMsg.push({severity: 'error',summary:'Error Message',detail: "There is used LR numbers in this series"});
		}			  	
	}

	closeLr(lrData, status){
		this.successMsg = [];
		console.log(lrData, status);
		
		this.registerService.closeLr(lrData.id, status, this.user.token).subscribe(
	        res => {
	        	if(res.response){		        		
		            this.successMsg.push({severity: 'success',summary:'Success',detail: "res.message"});
		            this.getLr();
	        	}
	        	else if(!res.response){
	        		this.successMsg.push({severity: 'error',summary:'Error',detail: res.message});
	        	}
	            
	        },err => {
	            console.log('err',err);
	            this.successMsg.push({severity: 'error',summary:'Error',detail: "Error in LR update"});
	        })	
				  	
	}

}
