import { Component, OnInit } from '@angular/core';

import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { Message} from 'primeng/components/common/api';
import { MessageService} from 'primeng/components/common/messageservice';
import { TranportersService} from './tranporters.service';
@Component({
    selector: 'app-transporters-form',
    templateUrl: './transporters-form.component.html',
    styleUrls: ['./transporters-form.component.css']
})
export class TransportersFormComponent implements OnInit {

    constructor(private service: TranportersService,
        private route: ActivatedRoute,
        private router: Router) {
        this.route.queryParams.subscribe(
            res =>{
                this.queryId = res.query_id;
                this.responseData.query_id = res.query_id;
                this.responseData.tp_id = res.tp_id;
                this.token = res.token;
            },err =>{
                console.log('err',err);
            }
            )  
    }
    isShowPriceForm: Boolean = true;
    userSuccessMsg: any = "";
    queryId: any;
    token: any;
    queryData: any = {
        from: '',
        to: '',
        vehicle_type: '',
        date: ''
    };
    responseData: any = {
        tp_id: '',
        query_id: '',
        price: null,
        available_vehicles: null,
    };
    vehicle_required: Number = 0;
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    successMsg: Message[] = [];
    ngOnInit() {
        this.service.getQueryDetail(this.token, {query_id: this.queryId}).subscribe(
            res => {
               
                console.log('queryData....,,,,,....',res);
                if (res.data) {
                    console.log('null data',res);
                   this.queryData = res.data;
                   this.vehicle_required = this.queryData.no_of_vehicle;
                }
                if(!res.response){
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
                }
            },err => {
                console.log('err',err);
            }
            )
    }
    saveRequest(){
        this.service.transportersResponse(this.token, this.responseData).subscribe(
            res => {
                console.log('transportersResponse',res);
                if(!res.response){
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
                }else{
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success',summary:'Success Message',detail: "Thanks for your response, we will get back to you shortly."});
                    this.isShowPriceForm = false;
                    let that = this;
                    setTimeout(function(){
                        that.router.navigate(['/bookings']);    
                    }, 2000);
                    
                }
            },err => {
                console.log('err',err);
            }
            )
    }
}
