import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
const url = '/api/v1/';


@Injectable()

export class OrderDetailService {
    constructor(private http: Http) {}
    
    getCustomers( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/customer/list?token='+token)
            .map((response: Response) =>response.json());
    }

    listTransporters( token: string) {
        console.log(".....token",token);
        return this.http.get(url+'admin/transporters?token='+token)
            .map((response: Response) =>response.json());
    }
    getOrderDetail(token: string , orderId: any) {
        console.log("service");
        console.log(orderId);
       return this.http.get(url+'admin/order/'+ orderId +'?token='+token)
           .map((response: Response) =>response.json());
    }

    saveAccounts(token: string , body: any){
        return this.http.post(url + 'admin/order/accounts' + '?token='+token,body)
            .map((response: Response) =>response.json());
    }

    saveMis(token: string , body: any){
        return this.http.post(url + 'admin/order/mis' + '?token='+token, body)
            .map((response: Response) =>response.json());
    }
 
    getCities(){
        return this.http.get(url + 'transporter/cities').map((response: Response) =>response.json());
    }

    saveOrderDetail(token: string , body: any){
        return this.http.post(url + 'admin/order/detail' + '?token='+token, body)
            .map((response: Response) =>response.json());
    }

    addCustomer( token: string, body: any) {
        console.log(".....token",token);
        return this.http.post(url+'admin/customer?token='+token, body)
            .map((response: Response) =>response.json());
    }

    createTransporters( token: string,transportersData) {
        return this.http.post(url+'admin/transporter?token='+token,transportersData)
            .map((response: Response) =>response.json());
    }

    getVehicleTypes() {
        return this.http.get(url+'transporter/vehicle/types')
            .map((response: Response) =>response.json());
    }

    getMaterialTypes() {
        return this.http.get(url+'user/material/types')
            .map((response: Response) =>response.json());
    }

    getChargeTypes() {
        return this.http.get(url+'admin/charge/types')
            .map((response: Response) =>response.json());
    }
    uploadDocs(token, body){
        return this.http.post(url + 'admin/upload/order/docs?token='+ token, body)
              .map((response: Response) =>response.json());
    }
    salesPersonList(token){
        return this.http.get(url + 'admin/sales/person/list'+'?token=' + token)
            .map((response: Response) =>response.json());
    }

    getLr(token){
        return this.http.get('/api/v1/register/get/lr?token='+token).map((response: Response) =>response.json());
    }


}