import { Component, OnInit,TemplateRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { AuthenticationService } from '../services/authentication.services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as _ from "lodash";
import {SwPush} from '@angular/service-worker';

@Component({
	moduleId: module.id,
	selector: "auth-app",	
	templateUrl: "signin.component.html",
	styleUrls: ['signin.component.css'],
	providers: [AuthenticationService,MessageService]
})

export class SigninComponent{
	credentials: any = {};
	url: string;
	public user: any = {};
	phone: number;
	collage: string = ".../../../app/common/assets/images/collage.png";
	public errorMessage :string = null;
	public showError = false;
	successMsg:  Message[] = [];

	modalRef: BsModalRef;
	VAPID_PUBLIC_KEY="HC36Y6gO4KBI2fiPj1vJq60dZfZV6J6S8M7IKbgcDlsYPlsApJyr9p9JujZiRo1bhAAgorlkJ6JXUJ-HBqmsg"

	constructor(       
		private authenticationService: AuthenticationService,
		private route: ActivatedRoute,
		private router: Router,
		 private messageService: MessageService,
		 private modalService: BsModalService,
        private swPush: SwPush
		) { }


	ngOnInit() {
		this.showError = false;
		this.user = JSON.parse(localStorage.getItem('currentUser'));
		console.log(localStorage.getItem('currentUser'));
		if(localStorage.getItem('currentUser')){ 
			if(this.user.role == 6){
				this.router.navigate(['/new-request']);
			}
			else if(this.user.role == 1){
				this.router.navigate(['/bookings']);
			} else if(this.user.role == 7 || this.user.role == 8) {
				this.router.navigate(['/transporters/'+this.user.transporter._id+'/bookings']);
			}	
			
		}
	} 

	openModalWithClass(template: TemplateRef<any>) {
    	this.modalRef = this.modalService.show(
      	template,
      	Object.assign({}, { class: 'gray modal-lg' })
    	);
  	}   
	
	signin(credentials: any) {
		this.authenticationService.signin(this.credentials)
		.subscribe(
			user => {
				this.user = user.data;
				this.showError = false;
				this.successMsg = [];
				this.successMsg.push({severity: 'success',summary:'Success Message',detail: 'Logged in successfully'});
				
	            // store user details and token in local storage to keep user logged in between page refreshes
	            localStorage.setItem('currentUser', JSON.stringify(this.user));
	           
				if(this.user.role == 6){

                    if (this.swPush.isEnabled) {
                        this.swPush.requestSubscription({
                            serverPublicKey: this.VAPID_PUBLIC_KEY
                        }).then(sub => this.authenticationService.subscribePushNotification(sub, this.user.token, this.user._id).subscribe())
                            .catch(err => console.error("Could not subscribe to notifications", err));
                    }
                    this.router.navigate(['/new-request']);
                }
				else if(this.user.role == 1){
					this.router.navigate(['/bookings']);
				}
				else if(this.user.role == 3){
					this.router.navigate(['/leads']);
				}else if(this.user.role == 7 || this.user.role == 8) {
					this.router.navigate(['/transporters/'+this.user.transporter._id+'/bookings']);
				}
				else{
					this.router.navigate(['/admin/order/history/list']);
				}
			},
			error => {
				console.log(error);
				if(error.status !== 401){
					this.errorMessage = error.json().message;
				}
				console.log("errorMessage",this.errorMessage);
				this.showError = true;
			});
	}
}