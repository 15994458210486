import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
    selector:'full-image',
    templateUrl:'full-image-modal.component.html',
    styleUrls:['full-image-modal.component.css']
})
export class FullImageModalComponent {
    imageUrl:String

    constructor(private bsModalRef: BsModalRef){
    }


    close(){
        this.bsModalRef.hide();
    }

}
