import {Component} from '@angular/core';
import {AuthenticationService} from '../services/authentication.services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'customer-profile',
    templateUrl: 'customer-profile.component.html',
    styleUrls: ['customer-profile.component.css']
})
export class CustomerProfileComponent {

    user: any = JSON.parse(localStorage.getItem('currentUser'));
  changePass:boolean = false;
  isEdit:boolean = false;
  editField:string = '';
   userProfile:any = {};
   password = {
       currentPassword: null,
       newPassword:  null,
       verifyPassword: null
   };
    profileIcon = ".../../assets/images/profile.jpg";
    imageURL:string = "";
    pattern: any = '[0-9]{10,10}';
    patternForEmail: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    constructor(private authService: AuthenticationService, private route: ActivatedRoute,
                private router: Router){
     this.userProfile = this.user;
     this.imageURL = 'users/'+this.userProfile._id+'/image?'+Math.random();

    }

    changePassword(){
        this.changePass = !this.changePass;
    }
    backToProfile(){
        this.changePass = !this.changePass;
    }

    backToBookings() {
        if (this.user.role == 7) {
            // this.isSelected = 'booking';
            this.router.navigate(['transporters/' + this.user.transporter._id + '/bookings']);
        } else {
            // this.isSelected = 'booking';
            this.router.navigate(['bookings']);
        }
    }

    save(){
        if (this.changePass){
            this.authService.changePassword(this.password).subscribe(
                success => {
                    console.log(success);
                },
                error => {
                    console.log(error);
                }

            );
        }
        else {
            this.isEdit = false;
            this.authService.updateUser(this.userProfile, this.userProfile.token)
                .subscribe(
                    user => {
                        this.userProfile = user;
                    },
                    error => {
                        console.log(error);
                    }
                );

        }


    }

    cancel() {
        this.isEdit = false;
    }

    edit(value) {
        this.editField = value;
        this.isEdit = true;
    }
}



