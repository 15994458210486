import { Component, OnInit,TemplateRef } from '@angular/core';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../services/authentication.services';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [MessageService]
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private messageService: MessageService,
		private modalService: BsModalService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router

  ) { }
  logo = '../../../assets/images/logo.png';
  moreIcon = '../../../assets/images/moreIcon.png';
  user: any = {};
  forgotPasswordTemplate: boolean = false;
  resetPasswordTemplate: boolean = false;
  showError: boolean = false;
  modalRef: BsModalRef;
  successMsg:  Message[] = [];
  errorMsg:  Message[] = [];

  ngOnInit() {}

  forgotPassword() {
    this.forgotPasswordTemplate = false;
    this.authenticationService.getPassResetOTP(this.user.phone)
      .subscribe(
      res => {
        console.log(res);      
        if(!res.response){
          this.showError = true;
          this.errorMsg.push({severity: 'error',summary:'Error Message',detail: "Could not find user"});
        }
        else{
          this.forgotPasswordTemplate = true;
        }
      },
      error => {
        console.log(error);
        this.errorMsg.push({severity: 'success',summary:'Success Message',detail: error});
      }
    );
  }

  submitOTP() {
    this.modalRef.hide();
    this.resetPasswordTemplate = false;
    this.authenticationService.submitPassResetOTP(this.user)
      .subscribe(
      res => {
        console.log(res);
        if(!res.response){
          this.showError = true;
          this.errorMsg.push({severity: 'error',summary:'Error Message',detail: "Incorrect OTP"});
        }
        else{
          this.resetPasswordTemplate = true;
          this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
          //this.router.navigate(['/reset-password?phone='+this.user.phone+'&authcode='+this.user.authCode]);
        }
      },
      error => {
        this.errorMsg.push({severity: 'success',summary:'Success Message',detail: error});
      }
    );
  	this.successMsg = [];
  }

  openForgotPasswordModal(template: TemplateRef<any>) {
    let that = this;
    setTimeout(function(){
      console.log(that.forgotPasswordTemplate)
      if(that.forgotPasswordTemplate){
        that.modalRef = that.modalService.show(
          template,
          { class: 'gray modal-md', ignoreBackdropClick: true }
        );
      }  
    }, 1000)
    
    	
  }

  openResetPasswordModal(template: TemplateRef<any>) {
    let that = this;
    setTimeout(function(){
      if(that.resetPasswordTemplate){
        that.modalRef = that.modalService.show(
          template,
          { class: 'gray modal-md', ignoreBackdropClick: true }
        );  
      }
      
    }, 1000)
  }

  updatePassword(){

    this.authenticationService.changePassword(this.user)
      .subscribe(
      res => {
        console.log(res);
        if(!res.response){
          this.showError = true;
          this.errorMsg.push({severity: 'error',summary:'Error Message',detail: "Incorrect OTP"});
        }
        else{
          this.modalRef.hide();
          this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
          this.router.navigate(['/signin']);
        }
      },
      error => {
        this.errorMsg.push({severity: 'success',summary:'Success Message',detail: error});
      }
    );
  }


}
