import {Component, Input,Directive, Output, EventEmitter, ViewChild,TemplateRef } from '@angular/core';
import { TransporterRoutesService } from './transporter-routes.service';
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { Message} from 'primeng/components/common/api';
// import { AppComponent } from '../../../app.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as _ from "lodash";

@Component({
	selector: 'app-transporter-routes',
	templateUrl: './transporter-routes.component.html',
	styleUrls: ['./transporter-routes.component.css']
})
export class TransporterRoutesComponent {

	user: any = JSON.parse(localStorage.getItem('currentUser'));
	cols: any[];
	errorMessage: string = null;
	groupTree: any = [];
	routeData: any={
        fromAddress: null,
        toAddress: null,
        vehicle_types: [],
        route_type:null
	};
	editRouteData: any={
		id: null,
		fromAddress: null,
		toAddress: null,
        vehicle_types: [],
		route_type: null
	};
	successMsg: Message[] = [];
	routes: any = [];
	displayDialog: boolean;
	editDisplayDialog: boolean = false;
	showAddRouteButton: boolean = false;
	vehicleOptions: any  = [];
	typeOptions = [{label: "One Way", value: 1},{label: "Two Way", value: 2}]
	modalRef: BsModalRef;
	fromOptions: any = [];
	toOptions: any = [];
	fromControl: FormControl = new FormControl();
	toControl: FormControl = new FormControl();
    addfromControl: FormControl = new FormControl();
    addtoControl: FormControl = new FormControl();
	fromOption: Observable<string[]>;
	toOption: Observable<string[]>;
	fromAddress : any ='';
	toAddress : any ='';
	vehicle_type: any;
	constructor(
		private service: TransporterRoutesService,
		private route: ActivatedRoute,
		private router: Router,
		private modalService: BsModalService
		){
	}
	
	ngOnInit(){
		this.listRoutes();
		this.service.getCities().subscribe(
			res => {
				console.log('cites',res.data.cities);
				this.fromOptions = res.data.cities;
				this.toOptions = res.data.cities;
				this.fromOption = this.fromControl.valueChanges
				.pipe(
					startWith(''),
					map(val => this.filterFrom(val))
					);
				this.toOption = this.toControl.valueChanges
				.pipe(
					startWith(''),
					map(val => this.filterTo(val))
					);
			},err => {
				console.log(err);
			}	
			)
		this.service.getVehicles().subscribe(
			res => {
				console.log('vehicles options',res.data.vehicleOptions);
				this.vehicleOptions = res.data.vehicleOptions;
			},err => {
				console.log(err);
			}	
			)
		this.cols = [
		{ field: 'SRNO', header: 'SR.NO.' },
		{ field: 'fromAddress', header: 'From Address' },
		{ field: 'toAddress', header: 'To Address' },
		{ field: 'vehicle_type', header: 'Vehicle Types' }

		];
	}
	filterFrom(val: string): string[]{
		return this.fromOptions.filter(option =>
			option.city.toLowerCase().includes(val.toLowerCase()));
	}
	filterTo(val: string): string[]{
		return this.toOptions.filter(option =>
			option.city.toLowerCase().includes(val.toLowerCase()));
	}
	showDialogToAdd(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template,
			Object.assign({}, { class: 'gray modal-md' })
			);
	}
	getVehicle(event){
		console.log('event vehicle_type',event); 
		this.editRouteData.vehicle_type = event.value;
	}
	getToCity(event){
		console.log('event',event); 
		this.editRouteData.toAddress = event;
	}
	getFromCity(event){
		console.log('getFromCity',event); 
		this.editRouteData.fromAddress = event;
	}
	selectedIndex(index) {
		console.log('index',index);
		if (index == 1) {
			this.showAddRouteButton = true;
		}else{
			this.showAddRouteButton = false;
		}
	}
	editRouteDetail(template, rowData) {
		console.log('rowData',rowData);
		this.editRouteData.id = rowData.id;
		this.editRouteData.route_type = rowData.route_type;
		this.editRouteData.fromAddress = rowData.fromAddress;
		this.editRouteData.toAddress = rowData.toAddress;
		this.editRouteData.vehicle_type = [rowData.vehicle_type];
		console.log(this.editRouteData);
		this.modalRef = this.modalService.show(template,
			Object.assign({}, { class: 'gray modal-md' })
			);
	}
	editTransportersDetails() {
		this.editDisplayDialog = true;
	}
	createRoute() {

		this.modalRef.hide();
		console.log('this.routeData',this.routeData);
			this.service.createRoutes(this.user.token, this.user.transporter._id, this.routeData).subscribe(
				res => {
					console.log('add transporters',res);
					this.routeData = {};
					if (res.response) {
						this.listRoutes(); 
						this.successMsg = [];
						this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
						this.modalRef.hide();
					} else {
						this.successMsg = [];
						this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
					}
					
				},err => {
				}
				)
			
	}
	listRoutes() {
		console.log('user',this.user);
		this.service.listRoutes(this.user.token,this.user._id).subscribe(
			res => {
				console.log('listRoutes',res);
				let that = this;
				this.routes = [];
				_.each(res.data, function(route, index){
					let temp_route = {};					
					temp_route['SRNO'] = index+1;
					temp_route['id'] = route._id;
					temp_route['fromAddress'] = route.fromAddress;
					temp_route['toAddress'] = route.toAddress;
					temp_route['vehicle_type'] = route.vehicle_type;
					temp_route['route_type'] = route.route_type;
					that.routes.push(temp_route);
				})
			},err => {
			}
			)
	}

	updateTransporters() {
		console.log('this.user',this.user);
		this.service.updateTransporter(this.user.token, this.user._id,this.user).subscribe(
			res => {
				console.log('transporter',res);
				
				if (res.response) {
					this.editDisplayDialog = false;
					this.user = res.data;
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}

			},err => {
			})
	}
	deleteRoute(rowData) {
		console.log('rowdata',rowData);
		this.service.deleteRoute(this.user.token, rowData.id).subscribe(
			res => {
				console.log('transporter',res);
				
				if (res.response) {
					this.editDisplayDialog = false;
					this.successMsg = [];
					this.listRoutes();
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}

			},err => {
			})
	}
	updateRoute(rowData) {
		this.modalRef.hide();
		console.log(this.editRouteData);
		if (this.editRouteData.fromAddress && this.editRouteData.toAddress && this.editRouteData.vehicle_type && this.editRouteData.route_type ) {
			this.service.updateRoute(this.user.token,this.user._id,this.editRouteData).subscribe(
				res => {
					console.log('add transporters',res);
					this.routeData = {};
					if (res.response) {
						this.listRoutes(); 
						this.successMsg = [];
						this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
						this.modalRef.hide();
					} else {
						this.successMsg = [];
						this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
					}
					
				},err => {
				}
				)
		}else{
			this.successMsg = [];
			this.successMsg.push({severity: 'error',summary:'Error Message',detail: 'Please fill all the fields'});
		}
		
	}


}
