import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
const url = '/api/v1/';

@Injectable()
export class AdmintransportersService {

  constructor(private http: Http) { }
  listTransporters( token: string) {
  	console.log(".....token",token);
  	return this.http.get(url+'admin/transporters?token='+token)
  	.map((response: Response) =>response.json());
  }
}
