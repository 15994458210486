import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';
import { NgStyle } from '@angular/common';
import { DashboardService } from './dashboard.service';
import * as  _ from "lodash";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

	ngOnInit() {
	}

	@ViewChild('myTable') table: any;

	funder = [];
	calculated = [];
	pending = [];
	groups = [];
	projections: any = [];
	editing = {};  
	sales_rows = [];
	lead_rows = [];

	constructor( private dashboardService: DashboardService
) {
	this.getProjections();
	this.getLeads();
	}

	user: any = JSON.parse(localStorage.getItem('currentUser'));

  	months = [
  	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"]

	enableSummary = true;
	summaryPosition = 'bottom';
	loadingIndicator: boolean = true;

	sales_columns = [
	    { prop: 'month', summaryFunc: null, },
	    { name: 'name',summaryFunc: null, },
	    { name: 'sales_person',summaryFunc: null, },
	    { prop: 'projection_value', summaryFunc: (cells) => this.totalValue(cells) },
	    { prop: 'projection_achieved', summaryFunc: (cells) => this.totalValue(cells) },
	    { prop: 'margin_value', summaryFunc: (cells) => this.totalValue(cells) },
	    { prop: 'total_orders', summaryFunc: null, },
	];

	lead_columns = [
	    { prop: 'month', summaryFunc: null, },
	    { prop: 'sales_person', summaryFunc: null, },
	    { name: 'total_leads',summaryFunc: null, },
	    { prop: 'not_started',summaryFunc: null, },
	    { prop: 'intro_email',summaryFunc: null, },
	    { prop: 'meeting_stage',summaryFunc: null, },
	    { prop: 'rfq_stage',summaryFunc: null, },
	    { prop: 'converted',summaryFunc: null, },
	    { prop: 'not_converted',summaryFunc: null, },
	];
	getProjections(){
	    this.dashboardService.listProjections(this.user.token).subscribe(
	      res => {
	        console.log(res);

	        this.projections = res.data;
	        let that = this;
	        for(var i = 0 ; i < this.projections.length; i++) {
	        	let temp_obj = {};
	        	temp_obj['month'] = this.months[(new Date(this.projections[i].month)).getMonth()];
	        	temp_obj['year'] = (new Date(this.projections[i].month)).getFullYear();
	        	temp_obj['sales_projection'] = this.projections[i]['projection_value'];
	        	temp_obj['actual_sales'] = 0;
	        	temp_obj['percentage_achieved'] = 0;
	        	temp_obj['sales_person'] = this.projections[i].user.name;
	        	temp_obj['sales_person_id'] = this.projections[i].user._id;
	        	temp_obj['total_orders'] = 0;
	        	temp_obj['margin_value'] = 0;
	        	this.sales_rows.push(temp_obj);
	        	//data.margin_value = (this.projections[i].projection_value*this.projections[i].margin_percentage)/100;
	        }
	        console.log(this.sales_rows);
			var helper = {};
			var result = this.sales_rows.reduce(function(r, o) {
			var key = o.month + '-' + o.sales_person;

			if(!helper[key]) {
				helper[key] = Object.assign({}, o); // create a copy of o
				r.push(helper[key]);
			} else {
				helper[key].sales_projection += o.sales_projection;
				helper[key].margin_value += o.margin_value;
			}
			  return r;
			}, []);

			console.log(result);
			this.sales_rows = result;

			this.getOrders();

	      },err => {

	      });
	}

	getOrders(){
		console.log("getting orders")
	    this.dashboardService.listOrders(this.user.token).subscribe(
	      res => {
	        console.log(res);

	        console.log(this.sales_rows);
	        let that= this;
	        _.each(this.sales_rows, function(row){
	        	var index = _.findIndex(res.data, function(data){return that.months[data._id.month-1] == row.month && data._id.year == row.year && data._id.sales_person == row.sales_person_id})
	        	if(index > -1){
	        		console.log(index)
	        		row.actual_sales = res.data[index].totalCustomerPrice;
	        		row.margin_value = res.data[index].totalCustomerPrice - res.data[index].totalTransporterPrice;	
	        		row.percentage_achieved = ((row.actual_sales/row.sales_projection)*100).toFixed(2);
	        		row.total_orders = res.data[index].totalOrders;
	        	}
	        	
	        })

	        console.log(this.sales_rows)

	      },err => {

	      });
	}

	getLeads(){
		console.log("getting leads")
	    this.dashboardService.listLeads(this.user.token).subscribe(
	      res => {
	        console.log(res);
	        let leads = res.data;

	        this.dashboardService.aggregatedLeads(this.user.token).subscribe(
		      res => {
		        console.log(res);

		        let aggregatedLeads = res.data;

		        for(var i = 0; i < leads.length; i++){
		        	let index = _.findIndex(aggregatedLeads, function(lead) {
		        		console.log(new Date(leads[i].created_at).getMonth()+1  + new Date(leads[i].created_at).getFullYear(), lead._id.month + lead._id.year, leads[i].lead_owner._id, lead._id.sales_person)
		        		return (new Date(leads[i].created_at).getMonth()+1  + new Date(leads[i].created_at).getFullYear()) == (lead._id.month + lead._id.year) && leads[i].lead_owner._id == lead._id.sales_person;
		        	})

		        	console.log('index ', index)

		        	if(index > -1){			        		
		        		if(!aggregatedLeads[index]["sales_person"]){
			        		aggregatedLeads[index]["sales_person"] = leads[i].lead_owner.name;
			        	}
		        		if(aggregatedLeads[index][leads[i].lead_status]){
			        		aggregatedLeads[index][leads[i].lead_status] = aggregatedLeads[index][leads[i].lead_status]+1
			        	}
			        	else{
			        		aggregatedLeads[index][leads[i].lead_status] = 1;	
			        	}	
		        	}
		        	
		        }

		        console.log(aggregatedLeads);

		        for(var i = 0; i < aggregatedLeads.length; i++){
		        	let temp_obj = {};
		        	temp_obj['month'] = this.months[aggregatedLeads[i]._id.month-1];
		        	temp_obj['year'] = aggregatedLeads[i]._id.year;
		        	temp_obj['total_leads'] = aggregatedLeads[i].totalLeads;
		        	temp_obj['sales_person'] = aggregatedLeads[i].sales_person;
		        	if(aggregatedLeads[i]["Not Started"]){
		        		temp_obj['not_started'] = aggregatedLeads[i]["Not Started"]
		        	}
		        	else{
		        		temp_obj['not_started'] = 0;	
		        	}
		        	if(aggregatedLeads[i]["Meeting Stage"]){
		        		temp_obj['meeting_stage'] = aggregatedLeads[i]["Meeting Stage"]
		        	}
		        	else{
		        		temp_obj['meeting_stage'] = 0;	
		        	}
		        	if(aggregatedLeads[i]["RFQ Stage"]){
		        		temp_obj['rfq_stage'] = aggregatedLeads[i]["RFQ Stage"]
		        	}
		        	else{
		        		temp_obj['rfq_stage'] = 0;	
		        	}
		        	if(aggregatedLeads[i]["Converted"]){
		        		temp_obj['converted'] = aggregatedLeads[i]["Converted"]
		        	}
		        	else{
		        		temp_obj['converted'] = 0;	
		        	}
		        	if(aggregatedLeads[i]["Introductory Mail"]){
		        		temp_obj['intro_email'] = aggregatedLeads[i]["Introductory Mail"]
		        	}
		        	else{
		        		temp_obj['intro_email'] = 0;	
		        	}
		        	if(aggregatedLeads[i]["Not Converted"]){
		        		temp_obj['not_converted'] = aggregatedLeads[i]["Not Converted"]
		        	}
		        	else{
		        		temp_obj['not_converted'] = 0;	
		        	}
		        
		        	this.lead_rows.push(temp_obj);
		        }
		        console.log(leads);
		        console.log(aggregatedLeads);
		        console.log(this.lead_rows);
				

		      },err => {

		      });
	      			

	      },err => {

	      });	
	}
	

	toggleExpandGroup(group) {
		console.log('Toggled Expand Group!', group);
		this.table.groupHeader.toggleExpandGroup(group);
	}  

	onDetailToggle(event) {
		console.log('Detail Toggled', event);
	}

	

	private totalValue(cells: number[]) {
	    const filteredCells = cells.filter(cell => !!cell);
	    let total = filteredCells.reduce((sum, cell) => sum += cell, 0);
	    return "Total: " + total;
	}
}