import {Component, Input,Directive, Output, EventEmitter, ViewChild,TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { TransportersDetailsService } from './transporters-details.service';
// import { AppComponent } from '../../../app.component';
import * as _ from "lodash";
import { Message} from 'primeng/components/common/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import {FullImageModalComponent} from '../common/full-image/full-image-modal.component';

@Component({
	selector: 'app-transporters-details',
	templateUrl: './transporters-details.component.html',
	styleUrls: ['./transporters-details.component.css']
})
export class TransportersDetailsComponent {

	user: any = JSON.parse(localStorage.getItem('currentUser'));
	cols: any[];

	errorMessage: string = null;
	docs = {
		pan_card:null,
		tds_declaration:null,
		loading_slip:null,
		cancelled_cheque:null
	};
	selectedFiles:FileList;
	confirmAccount;

	transporters: any = [];
	 orderTransporter = {
		orders:[],
		total_orders:null,
		total_amount:null,
		balance:null,
		advance:null,
		average_pod_cycle_days:null
	}
	orders = [];
	successMsg: Message[] = [];
	transporterId: any;
	displayDialog: boolean = false;
	editDisplayDialog: boolean;
	editRouteDisplayDialog: boolean;
	showAddRouteButton: boolean = false;
	editRouteData: any={};
	modalRef: BsModalRef;
	routeData: any={};
	routes: any = [];
	vehicleOptions: any  = [];
	transporter: any = {
		user: {
            bank_account_details:{}
		}
		}


	editData: any = {};
	fromOptions: any = [];
	toOptions: any = [];
	fromAddress : any = '';
	toAddress : any = '';
	vehicle_type: any;
	fromControl: FormControl = new FormControl();
	toControl: FormControl = new FormControl();
	fromOption: Observable<string[]>;
	toOption: Observable<string[]>;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private service: TransportersDetailsService,
		private modalService: BsModalService
		){
		this.route.params.subscribe(
			res => {
				console.log('routes res',res);
				this.transporterId = res.id;
			},err => {
				console.log('err',err);
			}
			)
	}

	slide: string = 'in';
	
	showRoleWrapper(){
		this.slide = "out";
	}
	

	ngOnInit(){

		this.getTransporter();
		this.listRoutes();
		this.orderList();
		
		this.service.getVehicles().subscribe(
			res => {
				this.vehicleOptions = res.data.vehicleOptions;
			},err => {
				console.log(err);
			}	
			)
		this.cols = [
		{ field: 'SRNO', header: 'SR.NO.' },
		{ field: 'fromAddress', header: 'From Address' },
		{ field: 'toAddress', header: 'To Address' },
		{ field: 'vehicle_type', header: 'Vehicle Types' }
		];
		this.getCities();
	}

	getCities(){
		this.service.getCities().subscribe(
			res => {
				console.log('',res.data.cities);
				this.fromOptions = res.data.cities;
				
				this.toOptions = res.data.cities;
				this.toOptions = this.toOptions.map(function(data){
					let tempObj: any = {};
					tempObj.label = data.city + ", One Way";
					tempObj.value = {
						city: data.city,
						state: data.state,
						lat: data.lat,
						long: data.long,
						type: 1
					}
					
					return tempObj;
				})

				let twoWayoptions: any = [];
				this.toOptions.forEach(function(item){
					let tempItem: any = {};
					tempItem.label = item.value.city + ", Two Way";
					tempItem.value = {
						city: item.value.city,
						state: item.value.state,
						type: 2,
						lat: item.value.lat,
						long: item.value.long
					}
					twoWayoptions.push(tempItem);

				})


				console.log(twoWayoptions);
				this.toOptions.push(twoWayoptions);
				this.toOptions = _.flatten(this.toOptions);
				console.log(this.toOptions);

				this.fromOption = this.fromControl.valueChanges
				.pipe(
					startWith(''),
					map(val => this.filterFrom(val))
					);
				
			},err => {
			}
			)		
	}

	filterFrom(val: any): string[]{
		return this.fromOptions.filter(option =>
			option.city.toLowerCase().includes(val.toLowerCase()));
	}

	initCurrentRow(index) {
		console.log('current row initCurrentRow',index);
	}

	selectedIndex(index) {
		console.log('index',index);
		if (index == 1) {
			this.showAddRouteButton = true;
		}else{
			this.showAddRouteButton = false;
		}
	}

	showDetails() {
		console.log();
		this.router.navigate(['admin/transporters/'+this.transporterId+'/routes']);
	}

	showDialogToAdd(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'gray modal-md', ignoreBackdropClick: true }
			);
	}
	
	editTransportersDetails() {
		this.editDisplayDialog = true;
	}
	submitEditDetail() {
		
	}
	editRoute(rowData) {
		console.log('rowData detail',rowData);
		
	}
	getVehicle(event){
		console.log('event vehicle_type',event); 
		this.editRouteData.vehicle_type = event.value;
	}
	getToCity(event){
		console.log('event',event); 
		this.editRouteData.toAddress = event;
	}
	getFromCity(event){
		console.log('getFromCity',event); 
		this.editRouteData.fromAddress = event;
	}
	
	editRouteDetail(template,rowData) {
		this.editRouteData.id = rowData.id;
		this.editRouteData.route_type = rowData.route_type;
		this.fromAddress = rowData.fromAddress;
		this.toAddress = rowData.toAddress;
		this.vehicle_type = [rowData.vehicle_type];
		console.log('rowData',rowData,this.editRouteData);
		this.modalRef = this.modalService.show(
			template,
			{ class: 'gray modal-md', ignoreBackdropClick: true }
			);
	}
	createRoute() {
		console.log('add route',this.routeData);
		
			this.service.createRoutes(this.user.token,this.transporterId,this.routeData).subscribe(
			res => {
				console.log('add transporters',res);
				this.routeData = {};
				if (res.response) {
					this.listRoutes(); 
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
					this.modalRef.hide();
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}
				
			},err => {
			}
			)		
		
	}
	listRoutes() {
		this.service.listRoutes(this.user.token,this.transporterId).subscribe(
			res => {
				let that = this;
				this.routes = [];
				_.each(res.data, function(route, index){
					let temp_route = {};					
					temp_route['SRNO'] = index+1;
					temp_route['id'] = route._id;
					temp_route['fromAddress'] = route.fromAddress;
					temp_route['toAddress'] = route.toAddress;
					temp_route['vehicle_type'] = route.vehicle_type;
					temp_route['route_type'] = route.route_type;
					that.routes.push(temp_route);
				})
			},err => {
			}
			)

	}
	updateTransporters() {
		console.log(this.transporter);
		if(this.transporter && this.transporter.user && this.transporter.user.bank_account_details && this.transporter.user.bank_account_details.account_number){
			let value =this.checkAccountNumber(this.transporter.user.bank_account_details.account_number,this.confirmAccount)
			if(!value){
			this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: "Account No. Incorrect"});
			return;
			}
		}
		this.service.updateTransporter(this.user.token, this.transporterId,this.transporter).subscribe(
			res => {
				console.log('transporter',res);
				
				if (res.response) {
					this.editDisplayDialog = false;
					this.transporter.user = res.data;
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}

			},err => {
			})

		
		
	}
	deleteRoute(rowData) {
		this.service.deleteRoute(this.user.token, rowData.id).subscribe(
			res => {
				console.log('transporter',res);
				
				if (res.response) {
					this.editDisplayDialog = false;
					this.listRoutes();
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}

			},err => {
			})
	}

	updateRoute() {
		console.log('edit route',this.editRouteData);
		if (this.routeData.fromAddress && this.routeData.toAddress && this.routeData.vehicle_type && this.routeData.route_type ) {
		this.service.updateRoute(this.user.token,this.transporterId,this.editRouteData).subscribe(
			res => {
				console.log('add transporters',res);
				this.routeData = {};
				if (res.response) {
					this.listRoutes(); 
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
					this.modalRef.hide();
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}
				
			},err => {
			}
			)
		}else{
		this.successMsg = [];
			this.successMsg.push({severity: 'error',summary:'Error Message',detail: 'Please fill all the fields'});
			}
	}

	uploadDocs(fileName, fileData){
		if(!fileData){
            this.successMsg = [];
            this.successMsg.push({severity: 'error',summary:'Error Message',detail: 'Please select a file'})
			return;
        }
        let that = this;
        let name = '';
        if( this.transporterId ){
			let key =
            name =  name +  this.transporterId+'/'+fileName;
        }
        else {
            return;
        }
        this.selectedFiles = fileData.target.files
        let currentFileUpload = this.selectedFiles.item(0);
        const formdata: FormData = new FormData();
        formdata.append('file', currentFileUpload);
        formdata.append('file_name', name);
        formdata.append('transporter_id',this.transporterId)
        this.service.uploadTransporterDocs(this.user.token, formdata).subscribe(
        	res => {
                if (res.response) {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
                    this.getTransporter();
                    this.docs[fileName]=null;
                } else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
                }
			}
		)
	}

	orderList(){
		let that = this;
		this.service.listOrders(this.user.token, this.transporterId).subscribe(
			res => {
				console.log(res);
				that.orderTransporter = res.data

			},error1 => {
				console.log(error1);
			}
		)
	}
    orderDetail(id){
		this.router.navigate(['order/detail/'+ id])
	}
showFullImage(imageUrl){
		let initialState = {imageUrl:imageUrl}
		let modal = this.modalService.show(FullImageModalComponent,{class:'modal-lg',initialState})
}

	getTransporter(){
        this.service.getTransporter(this.user.token, this.transporterId).subscribe(
            res => {
                console.log('transporter',res);
				this.transporter = res.data;

				
					this.confirmAccount = this.transporter && this.transporter.user && this.transporter.user.bank_account_details && this.transporter.user.bank_account_details.account_number;
	
				
            },err => {
            })
	}
	checkAccountNumber(account_number, confirm_account_number){
		if(account_number != confirm_account_number){
			return false;
		}
		else{
			return true;
		}
	}


}
