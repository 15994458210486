import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
 const url = '/api/v1/';

@Injectable()
export class TransporterBookingsService {

  constructor(private http: Http) { }

  getBookings(token: string,tpId){
  	let headers = new Headers();
  	return this.http.get(url+'admin/transporters/'+tpId+'/bookings?token='+token).map((response: Response) =>response.json());
  }
    transportersResponse(token: string,responseData: any) {
        console.log(".....responseData",responseData);
        return this.http.post(url+'transporter/get/price?token='+token, responseData)
            .map((response: Response) =>response.json());
    }

    transportersVehicleDetail(token: string,responseData: any) {
        console.log(".....responseData",responseData);
        return this.http.post(url+'transporter/get/vehicle/details?token='+token, responseData)
            .map((response: Response) =>response.json());
    }

}
