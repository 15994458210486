import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'

@Injectable()
export class RegisterService {

	constructor(private http: Http) { }

	getLr(token){
		return this.http.get('/api/v1/register/get/lr?token='+token).map((response: Response) =>response.json());
	}

	generateLr(lrData, token){
		return this.http.post('/api/v1/register/create/lr?token='+token, lrData).map((response: Response) =>response.json());
	}

	deleteLr(id, token){
		return this.http.get('/api/v1/register/delete/lr/'+id+'?token='+token).map((response: Response) =>response.json());
	}


	closeLr(id, status, token){
		return this.http.get('/api/v1/register/close/lr/'+id+"/"+status+'?token='+token).map((response: Response) =>response.json());
	}
}
