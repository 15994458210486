import {Component, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderDetailService} from './order-detail.service';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap';
import {AddTransportersComponent} from '../add-transporters/add-transporters.component';
import {AddCustomerComponent} from '../common/add-customer/add-customer.component';
import {AddTransporterComponent} from '../common/add-transporter/add-transporter.component';
import {Message} from 'primeng/api';
import { RegisterService } from '../../register/register.service';
import * as _ from "lodash";
import {FullImageModalComponent} from '../common/full-image/full-image-modal.component';

@Component({
    selector: 'order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent {

    docs = {
        frontPod: null,
        backPod: null,
        loadingSlip: null
    }
    sub: any;
    id: any = null;
    selectedFiles: FileList;
    salesPersonList = [];
    fromOptions = [];
    toOptions = [];
    fromOption: Observable<string[]>;
    toOption: Observable<string[]>;
    successMsg: Message[] = [];

    customer: boolean = false;
    transporter: boolean = false;
    accounts: boolean = false;
    mis: boolean = false;
    customerList = [];
    transporterList = [];
    vehicleTypeList = [];
    materialTypeList = [];
    chargeTypeList = [];
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    order;
    orderSummaryData = {
        order_number: null,
        customerRate: null,
        transporterRate: null,
        margin: null,
        marginPercent: null,
        orderDate: null
    }
    Data = {
        customer: null,
        transporter:null,
        account:null
    }
    transporterData = {
        transporter: null
    }
    accountData = {
        transporter: null,
        customer: null
    }
    misData = {}

    misForm = new FormGroup({
        _id:new FormControl(),
        reporting_date:new FormControl(),
        loading_date:new FormControl(),
        halting_days_loading_point:new FormControl(),
        expected_reporting_date:new FormControl(),
        in_transit:new FormArray([]),
        actual_reporting_date:new FormControl(),
        unloading_date:new FormControl(),
        halting_days_unloading_point:new FormControl(),
        pod_status:new FormControl(),
        pod_receiving_date:new FormControl(),
        pod_penalty_days:new FormControl('')
    })

    customerForm = new FormGroup({
        customer_id: new FormControl(),
        contact_number: new FormControl(''),
        contact_person: new FormControl(''),
        consigner: new FormGroup({
            name: new FormControl(''),
            address: new FormControl(''),
            gst: new FormControl('')

        }),
        consignee: new FormGroup({
            name: new FormControl(''),
            address: new FormControl(''),
            gst: new FormControl('')
        }),
        from: new FormControl(''),
        to: new FormControl(''),
        material_type: new FormControl(),
        sales_person:new FormControl(),
        price: new FormGroup({
            freight_charges: new FormControl(''),
            total_amount: new FormControl(0),
            other_charges: new FormArray([])
        })
    })

    transporterForm = new FormGroup({
        transporter_id: new FormControl(),
        contact_number: new FormControl(''),
        contact_person: new FormControl(''),
        vehicle_details: new FormGroup({
            vehicle_number: new FormControl(),
            vehicle_type: new FormControl(),
            driver_number: new FormControl(),
        }),
        tds_deduction: new FormControl(0),
        placed_by: new FormControl(),
        price: new FormGroup({
            freight_charges: new FormControl(''),
            total_amount: new FormControl(0),
            other_charges: new FormArray([])
        })
    })

    accountForm = new FormGroup({
        _id: new FormControl(),
        transporter: new FormGroup({
            payments: new FormArray([]),
            due_amount: new FormControl(),
            amount_paid: new FormControl(),
            total_amount: new FormControl()
        }),
        customer: new FormGroup({
            payments: new FormArray([]),
            due_amount: new FormControl(),
            amount_received: new FormControl(),
            total_amount: new FormControl()
        })
    })
    modalRef;

    availableLRs: any = [];
    constructor(private route: ActivatedRoute,
                private router: Router, private service: OrderDetailService, private modalService: BsModalService,
                private registerService: RegisterService) {

    }

    finalLrs: any = [];
    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        if (this.id && this.id != 'new') {
            this.getOrderDetail();
        }
        this.getCustomers();
        this.getTransporters();
        this.getSalesPersonList();
        this.getCities();
        this.vehicleTypes();
        this.materialTypes();
        this.chargeTypes();

        this.registerService.getLr(this.user.token).subscribe(
            res => {
                console.log('registers ',res);
                let that = this;
                for(var i = 0; i < res.data.length; i++){
                    if(res.data[i].available_Lrs.length && !res.data[i].isClosed){
                        console.log(this.availableLRs);
                        this.availableLRs = _.union(this.availableLRs, _.slice(res.data[i].available_Lrs, 0, 10));    
                    }
                }
               
                console.log(this.availableLRs);

                let updatedLrs: any = [];
                this.availableLRs.forEach(function(item){
                    let tempItem: any = {};
                    tempItem.label = item;
                    tempItem.value = item;
                    updatedLrs.push(tempItem);

                })

                this.finalLrs = updatedLrs;
                console.log(this.finalLrs);

            },err => {
                console.log('err',err);
            })

    }





    addChargesCustomer() {
        let priceGroup = this.customerForm.get('price') as FormGroup;
        let items = priceGroup.get('other_charges') as FormArray;
        items.push(this.chargeTypeItem());

    }

    removeChargesCustomer(index) {
        let priceGroup = this.customerForm.get('price') as FormGroup;
        let items = priceGroup.get('other_charges') as FormArray;
        items.removeAt(index);
        this.showCustomerTotalAmount()
    }
    addchargesTransporter() {
        let priceGroupTransporter = this.transporterForm.get('price') as FormGroup;
        let itemsTransporter = priceGroupTransporter.get('other_charges') as FormArray;
        itemsTransporter.push(this.chargeTypeItem());


    }

    removechargesTransporter(index){
        let priceGroupTransporter = this.transporterForm.get('price') as FormGroup;
        let itemsTransporter = priceGroupTransporter.get('other_charges') as FormArray;
        itemsTransporter.removeAt(index)
        this.showTransporterTotalAmount();
    }

    saveOrder() {
        this.Data.customer = this.customerForm.value;
        this.Data.transporter = this.transporterForm.value;
        this.Data.account = this.accountForm.value;

        if(this.Data && this.Data.account && this.Data.account.transporter && this.Data.account.transporter.payments.length>0){
            if(!this.checkTransporterAccountDetails(this.Data && this.Data.transporter && this.Data.transporter.transporter_id)){
                                this.successMsg = [];
                               this.successMsg.push({severity: 'error', summary: 'Error Message', detail: "Please fill account details of transporter"});
                               return;
                            }
        }

        this.Data['order_number'] = this.orderSummaryData.order_number;
        this.Data['order_date'] = this.orderSummaryData.orderDate;
        if (this.id && this.id != 'new') {
            this.Data['_id'] = this.id;
        }

        this.service.saveOrderDetail(this.user.token, this.Data).subscribe(
            res => {
                console.log(res);
                if (res.response) {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message});
                } else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
                }
                if (this.id == 'new') {
                    this.router.navigate(['admin/order/history/list'])
                }
            }, error1 => {
                console.log(error1);
            });
    }

    addPaymentTransporter(index?) {
        let transporter = this.accountForm.get('transporter') as FormGroup;
        let items = transporter.get('payments') as FormArray;
        if (index == 0 || index) {
            items.removeAt(index)
            let amountPaid = 0;
            let payments = items.value
            payments.forEach(function (item, index) {
                amountPaid = amountPaid + item.amount;
            })
            this.accountForm.get('transporter').get('amount_paid').setValue(amountPaid);
            this.accountForm.get('transporter').get('due_amount').setValue(this.accountForm.get('transporter').get('total_amount').value - amountPaid);
            }

        else {
            items.push(this.createTransporterPayment());
        }

    }

    addPaymentCustomer(index?) {
        let customer = this.accountForm.get('customer') as FormGroup;
        let items = customer.get('payments') as FormArray;
        if (index == 0 || index) {
            items.removeAt(index)
            let amountPaid = 0;
            let payments = items.value
            payments.forEach(function (item, index) {
                amountPaid = amountPaid + item.amount;
            })
            this.accountForm.get('customer').get('amount_received').setValue(amountPaid);
            this.accountForm.get('customer').get('due_amount').setValue(this.accountForm.get('customer').get('total_amount').value - amountPaid);

        }
        else {
            items.push(this.createCustomerPayment());
        }

    }

    saveAccounts() {
        this.accountData = this.accountForm.value;
        if (this.accountData['_id']==null) {
            this.accountData['order_id']=this.id
        }

        if(this.accountData  && this.accountData.transporter && this.accountData.transporter.payments.length>0){
            if(!this.checkTransporterAccountDetails(this.transporterForm.get('transporter_id').value)){
                this.successMsg = [];
                this.successMsg.push({severity: 'error', summary: 'Error Message', detail: "Please fill account details of transporter"});
                return;
            }
        }

        this.service.saveAccounts(this.user.token, this.accountData).subscribe(
            res => {
                console.log(res);
                if (res.response) {
                    this.accounts = !this.accounts;
                        this.successMsg = [];
                        this.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message});

                }
            }, error1 => {
                console.log(error1)
            })
    }

    addStatusInMis(){
        let inTransit = this.misForm.get('in_transit') as FormArray
        inTransit.push(this.misStatusItem())
    }
    misStatusItem(){
        return new FormGroup({
            date: new FormControl(),
            status: new FormControl('')
        })
    }

    saveMis(template: TemplateRef<any>) {
        this.misData = this.misForm.value;
        if (this.misData['_id']==null) {
            this.misData['order_id']=this.id
        }
        this.service.saveMis(this.user.token, this.misData).subscribe(
            res => {
                console.log(res);
                if (res.response) {
                    this.mis = !this.mis;
                    this.successMsg = [];
                    this.successMsg.push({severity: 'success', summary: 'Success Message', detail: res.message});
                } else {
                    this.successMsg = [];
                    this.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});
                }
            }, error1 => {
                console.log(error1);
            }
        )
    }

    customerChange() {
        let id = this.customerForm.get('customer_id').value;
        let that = this;
        this.customerList.forEach(function (item, index) {
            if (id == item._id) {
                that.customerForm.get('contact_number').setValue(item.phone)
                that.customerForm.get('contact_person').setValue(item.name)
                that.customerForm.get('sales_person').setValue(item.sales_person);
                if (item.consigner && item.consigner.length)
                    that.customerForm.get('consigner').setValue(item.consigner[0])
                if (item.consignee && item.consignee.length)
                    that.customerForm.get('consignee').setValue(item.consignee[0])

            }
        })
    }

    transporterChange() {
        let id = this.transporterForm.get('transporter_id').value;
        let that = this;
        this.transporterList.forEach(function (item, index) {
            if (id == item._id) {
                that.transporterForm.get('contact_number').setValue(item.contact_numbers[0]);
                that.transporterForm.get('contact_person').setValue(item.name)

            }
        })
    }





    showCustomerTotalAmount() {
        this.Data.customer = this.customerForm.value;
        let total = this.Data.customer.price.freight_charges;
        if (this.Data.customer.price.other_charges.length) {
            this.Data.customer.price.other_charges.forEach(function (item) {
                total = total + item.amount
            })
        }

        this.customerForm.get('price').get('total_amount').setValue(total)
        this.orderSummaryData.customerRate = total;
        this.accountForm.get('customer').get('total_amount').setValue(total);
        this.accountForm.get('customer').get('due_amount').setValue(this.accountForm.get('customer').get('total_amount').value-this.accountForm.get('customer').get('amount_received').value);
    }

    showTransporterTotalAmount() {
        this.transporterData.transporter = this.transporterForm.value;
        let total = this.transporterData.transporter.price.freight_charges;
        if (this.transporterData.transporter.price.other_charges.length) {
            this.transporterData.transporter.price.other_charges.forEach(function (item) {
                total = total + item.amount
            })
        }

        this.transporterForm.get('price').get('total_amount').setValue(total)
        this.orderSummaryData.transporterRate = total;
        this.accountForm.get('transporter').get('total_amount').setValue(total);
        this.accountForm.get('transporter').get('due_amount').setValue(this.accountForm.get('transporter').get('total_amount').value-this.accountForm.get('transporter').get('amount_paid').value);
    }


    getOrderDetail() {
        this.service.getOrderDetail(this.user.token, this.id).subscribe(
            res => {
                console.log(res);
                this.order = res.data.order;
                if (this.order && this.order.customer && this.order.customer.price && this.order.customer.price.other_charges.length) {
                    let count = this.order.customer.price.other_charges.length;
                    while (count > 0) {
                        this.addChargesCustomer()
                        count--;
                    }
                }
                if (this.order && this.order.transporter && this.order.transporter.price && this.order.transporter.price.other_charges.length) {
                    let count = this.order.transporter.price.other_charges.length;
                    while (count > 0) {
                        this.addchargesTransporter()
                        count--;
                    }
                }
                this.orderSummaryData.order_number = this.order.order_number;
                this.orderSummaryData.orderDate = this.order.order_date;
                if (this.order.customer.price) {
                    this.orderSummaryData.customerRate = this.order.customer.price.total_amount;
                }
                if (this.order.transporter.price) {
                    this.orderSummaryData.transporterRate = this.order.transporter.price.total_amount;
                }
                if (this.order.customer && this.order.customer.customer_id)
                    this.customerForm.setValue(this.order.customer);
                if (this.order.transporter && this.order.transporter.transporter_id) {
                    this.transporterForm.setValue(this.order.transporter);
                }


                    if (res.data.account && res.data.account._id) {
                        if (res.data.account.customer && res.data.account.customer.payments && res.data.account.customer.payments.length) {
                            let count = res.data.account.customer.payments.length;
                            while (count > 0) {
                                this.addPaymentCustomer()
                                count--;
                            }
                        }
                        if (res.data.account.transporter && res.data.account.transporter.payments && res.data.account.transporter.payments.length) {
                            let count = res.data.account.transporter.payments.length
                            while (count > 0) {
                                this.addPaymentTransporter();
                                count--;
                            }
                        }
                        this.accountForm.setValue(res.data.account);
                    }


                    if (res.data.mis && res.data.mis._id) {
                        if (res.data.mis.in_transit && res.data.mis.in_transit.length) {
                            let count = res.data.mis.in_transit.length;
                            while (count > 0) {
                                this.addStatusInMis();
                                count--;
                            }
                        }
                        this.misForm.setValue(res.data.mis)


                    }


            }, error1 => {
                console.log(error1);
            })
    };


    openAddNewCustomerModal() {
        const initialState = {
            salesPersonList: this.salesPersonList
        };
        this.modalRef = this.modalService.show(AddCustomerComponent, {initialState});
        this.modalRef.content.onCustomerSave.subscribe(result => {
            this.successMsg = [];
            this.successMsg.push(result);
            this.getCustomers();
        });
    }

    openAddNewTransporterModal() {
        this.modalRef = this.modalService.show(AddTransporterComponent);
        this.modalRef.content.onTransporterSave.subscribe(result => {
            this.successMsg = [];
            this.successMsg.push(result);
            this.getTransporters();

        });
    }


    customerAmount(i) {
        this.accountData.customer = this.accountForm.get('customer').value;
        let amountReceived = 0;
        this.accountData.customer.payments.forEach(function (item, index) {
            amountReceived = amountReceived + item.amount
        })
        this.accountForm.get('customer').get('amount_received').setValue(amountReceived);
        this.accountForm.get('customer').get('due_amount').setValue(this.accountForm.get('customer').get('total_amount').value - amountReceived);

    }

    transporterAmount(i) {
        let value = this.accountForm.get('transporter').get('payments') as FormArray;
        value.at(i).get('tds_percent').setValue(this.transporterForm.get('tds_deduction').value)
        this.accountData.transporter = this.accountForm.get('transporter').value;
        let amountPaid = 0;
        this.accountData.transporter.payments.forEach(function (item, index) {
            amountPaid = amountPaid + item.amount;
            if (i == index) {
                let tdsAmount = (item.amount * item.tds_percent) / 100;
                value.at(i).get('tds_amount').setValue(tdsAmount)
                let payableAmount = item.amount - tdsAmount;
                value.at(i).get('payable_amount').setValue(item.amount - tdsAmount)
            }
        })
        this.accountForm.get('transporter').get('amount_paid').setValue(amountPaid);
        this.accountForm.get('transporter').get('due_amount').setValue(this.accountForm.get('transporter').get('total_amount').value - amountPaid);
    }

    vehicleTypes() {
        this.service.getVehicleTypes().subscribe(
            res => {
                console.log(res);
                this.vehicleTypeList = res.data.vehicleOptions;
            }
        )
    }

    materialTypes() {
        this.service.getMaterialTypes().subscribe(
            res => {
                console.log(res);
                this.materialTypeList = res.data.materialOptions;
            }
        )
    }

    chargeTypes() {
        this.service.getChargeTypes().subscribe(
            res => {
                this.chargeTypeList = res.data.extraChargeOptions;
            }
        )
    }


    checkForOrderNumberandDate(orderNumber, date) {
        if (!orderNumber || !date)
            return false;
    }

    uploadOrderDocs(fileName, fileData) {
        let that = this;
        let name = '';
        if (this.id && this.order && this.order.transporter && this.order.transporter.transporter_id && this.misForm.get('_id').value) {
            name = name + this.order.transporter.transporter_id + '/' + this.id + '/' + fileName
        }
        else {
            return;
        }
        this.selectedFiles = fileData.target.files
        let currentFileUpload = this.selectedFiles.item(0);
        const formdata: FormData = new FormData();
        formdata.append('file', currentFileUpload);
        formdata.append('file_name', name);
        formdata.append('order_mis_id',this.misForm.get('_id').value);
        this.service.uploadDocs(this.user.token, formdata).subscribe(
            res => {
                console.log(res);
                if(res.response){
                that.successMsg = [];
                that.successMsg.push({severity: 'success', summary: 'Success Message', detail: "file uploaded"});
            } else {
                    that.successMsg = [];
                    that.successMsg.push({severity: 'error', summary: 'Error Message', detail: res.message});

                }
            }, error1 => {
                that.successMsg = [];
                that.successMsg.push({severity: 'error', summary: 'Error Message', detail: error1.message});
                console.log(error1);
            }
        )
    }
    chargeTypeItem (){
        return new FormGroup({
            charge_type: new FormControl(),
            amount: new FormControl('')
        })
    }

    createTransporterPayment(){
        return new FormGroup({
            date: new FormControl(),
            amount: new FormControl(),
            tds_percent: new FormControl(),
            tds_amount:new FormControl(),
            remark:new FormControl(),
            payable_amount:new FormControl(),
            payment_status:new FormControl(1)
        })
    }
    createCustomerPayment(){
        return new FormGroup({
            date: new FormControl(),
            amount:new FormControl(),
            remark: new FormControl()
        })
    }

    expandCustomer() {
        this.customer = !this.customer;
    }

    expandTransporter() {
        this.transporter = !this.transporter;
    }

    expandAccount() {
        this.accounts = !this.accounts;
    }

    expandmis() {
        this.mis = !this.mis;
    }

    getCustomers() {
        this.service.getCustomers(this.user.token).subscribe(
            res => {
                console.log(res);
                this.customerList = res.data;
            }, err => {
            });
    }

    getTransporters() {
        this.service.listTransporters(this.user.token).subscribe(
            res => {
                 this.transporterList = res.data;
                // this.transporterList.sort(function (item1, item2) {
                //     if (item1.company && item2.company)
                //         return item1.company.toLowerCase().localeCompare(item2.company.toLowerCase());
                // })
            }, err => {
            });
    }
    getCities() {
        this.service.getCities().subscribe(
            res => {
                this.fromOptions = res.data.cities;
                this.toOptions = res.data.cities
                this.fromOption = this.customerForm.get('from').valueChanges
                    .pipe(
                        startWith(''),
                        map(val => this.filterFrom(val))
                    );
                this.toOption = this.customerForm.get('to').valueChanges
                    .pipe(
                        startWith(''),
                        map(val => this.filterTo(val))
                    );
            }, err => {
            }
        )
    }

    filterFrom(val: string): string[] {
        return this.fromOptions.filter(option =>
            option.city.toLowerCase().includes(val.toLowerCase()));
    }

    filterTo(val: string): string[] {
        return this.toOptions.filter(option =>
            option.city.toLowerCase().includes(val.toLowerCase()));
    }

    back(){
        this.router.navigate(['admin/order/history/list'])
    }
    getSalesPersonList(){
        this.service.salesPersonList(this.user.token).subscribe(res =>{
            console.log(res);
            if(res.response){
                this.salesPersonList = res.data;
            }
        },error1 => {
            console.log(error1);
        })
    }

    checkTransporterAccountDetails(transporterId){
                let transporter = this.transporterList.find(function (transporter) {
                            return   transporter._id==transporterId
                        })

                    if(transporter.user && transporter.user.bank_account_details && transporter.user.bank_account_details.account_number &&
                        transporter.user.bank_account_details.ifsc_code && transporter.user.bank_account_details.name)
                       return true;
                else
                    return false
                }

                showFullImage(imageUrl){
                    let initialState = {imageUrl:imageUrl}
                    let modal = this.modalService.show(FullImageModalComponent,{class:'modal-lg',initialState})
                }

}






