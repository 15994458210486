import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
import { Subject } from 'rxjs/Subject';
 const url = '/api/v1/';


@Injectable()
export class AuthenticationService {
    public isUserLoggedIn: boolean = false;

    constructor(private http: Http) {
    }

    signup(user: any) {
        console.log(user);
        return this.http.post(url + 'authcode', user)
            .map((response: Response) => response.json());
    }

    signin(credentials: any) {
        return this.http.post(url + 'accounts/login', credentials)
            .map((response: Response) => response.json());
    }

    getOTP(phone: number) {
        let headers = new Headers();
        return this.http.get(url + 'authcode/' + phone)
            .map((response: Response) => response.json());
    }

    getPassResetOTP(phone: number) {
        let headers = new Headers();
        return this.http.get(url + 'authcode/' + phone + "?isPassReset=true")
            .map((response: Response) => response.json());
    }

    submitPassResetOTP(otpObj: any) {
        let headers = new Headers();
        return this.http.post(url + 'verify/otp/', otpObj)
            .map((response: Response) => response.json());
    }

    signout(user) {
        // remove user from local storage to log user out
        return this.http.get(url + 'accounts/logout')
            .map((response: Response) => {
                // if logout successful remove token from local storage
                localStorage.removeItem('currentUser');
                localStorage.removeItem('query');
            });
    }

    changePassword(password) {
        return this.http.post(url + 'reset/password', password).map((response: Response) => response.json());
    }

    updateUser(user: any, token: string) {
        return this.http.put(url + 'user/profile?token=' + token, user).map((response: Response) => response.json());
    }

    subscribePushNotification(subscriptionObject, token, user_id) {
        console.log("Suhel");
        return this.http.post(url + 'user/subscribe/push/notification?token=' + token + '&user_id=' + user_id, subscriptionObject).map((response: Response) => response.json());
    }
}