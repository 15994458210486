import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {OrderDetailService} from '../../order-detail/order-detail.service';
import {BsModalRef} from 'ngx-bootstrap';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-add-transporter',
  templateUrl: './add-transporter.component.html',
  styleUrls: ['./add-transporter.component.css']
})
export class AddTransporterComponent implements OnInit {

  user: any = JSON.parse(localStorage.getItem('currentUser'));
  public onTransporterSave: Subject<Object>;
  constructor(private service: OrderDetailService, private _bsModalRef: BsModalRef, private location: PlatformLocation) {
    location.onPopState(() => this._bsModalRef.hide());
  }

  transporterModel = {
    company: '',
    name: null,
    address: null,
    phone: null,
    email: null,
    gst: '',
    pan: '',
    password: '123456',
    gps_info: {
      isEnabled: false,
      api_url: '',
      username: '',
      password: ''
    }
  };

  onSubmit() {
    this.service.createTransporters(this.user.token, this.transporterModel).subscribe(
        res => {
          if (res.response) {
            this.onTransporterSave.next({severity: 'success', summary: 'Success Message', detail: res.message})
            this._bsModalRef.hide();
          } else {
            this.onTransporterSave.next({severity: 'error', summary: 'Error Message', detail: res.message});
          }
        }, err => {
          this.onTransporterSave.next({severity: 'error', summary: 'Error Message', detail: err.message});
        });
  }

  ngOnInit() {
    this.onTransporterSave = new Subject();
  }

  cancel() {
    this._bsModalRef.hide();
  }
}
