import {Component, OnInit, TemplateRef} from '@angular/core';
import {UserListService} from "./user-list.service";
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../common/confirmation/confirmation-modal.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})


export class UserListComponent implements  OnInit {

  user: any = JSON.parse(localStorage.getItem('currentUser'));
  userList = [];
    p: number =1;
  userListKeyDown = [];
  constructor(private service: UserListService, private modalService: BsModalService, private router: Router,
              private route: ActivatedRoute, private ngbModalService: NgbModal){

  }

  customer;
  modalRef;
  successMsg: Message[] = [];
  newCustomer = new FormGroup( {
    name: new FormControl(),
    role: new FormControl(),
    phone: new FormControl(),
    password: new FormControl(),
    confirmPassword: new FormControl(),
    email: new FormControl(),
    company: new FormControl(),
    gst: new FormControl(),
    address: new FormControl()
  });

  search = {
      name:null,
      email:null,
      phone:null,
      company:null,
      role:null
  }

  allRoles: any = [
    {
      name: "Sales", value: 2
    },
    {
      name: "Sourcing", value: 3
    },
    {
      name: "Operations", value: 4
    },
    {
      name: "Accounts", value: 5
    },
    {
      name: "Customer", value: 6
    },
    {
      name: "Transporter", value: 7
    },
    {
      name: "Broker", value: 8
    }
  ]

  ngOnInit(){
    this.getUserList();
  }


    showDetails(userId) {
    this.router.navigate(['admin/user/' + userId + '/details'])
  }
  getUserList(){
    this.service.listUsers(this.user.token).subscribe(
      res => {
        console.log(res);
        this.userList = res.data;
        this.userListKeyDown = this.userList;
      },err => {
      });
  }

  openAddNewCustomerModal(template: TemplateRef<any>){
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-md' })
      );
  }

  addNewCustomer(){
    this.service.addUser(this.user.token, this.newCustomer.value).subscribe(
      res => {
        this.getUserList();
        this.modalRef.hide();
      }, err => {
      });
  }

    filterListKeyUp(){
        this.userList = this.filterUserList(this.userList,this.search);
    }

    filterListKeyDown(){
        let that = this;
        console.log(this.search);
        setTimeout(function() {
            that.userList = that.filterUserList(that.userListKeyDown,that.search);
        },1);


    }

  deleteUser(userId){
      const initialState = {
          title: 'Do you really want to delete?'
      };
      let modalReference = this.modalService.show(ConfirmationModalComponent,{initialState});
      modalReference.content.onClose.subscribe(result => {
        if(result){
            this.service.deleteUser(this.user.token, userId).subscribe(
                res => {
                    if (res.response) {

                        this.successMsg = [];
                        this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
                        this.getUserList();
                    } else {
                        this.successMsg = [];
                        this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
                    }
                    console.log(res);

                }, err => {
                    console.log(err);
                });

        }
        else{

        }
      })

   }

    filterUserList(userList,filters){
        let filterKeys = Object.keys(filters);
        return userList.filter(function (eachObj) {
            return filterKeys.every(function (eachKey) {
                if (!filters[eachKey]) {
                    return true;
                }
                if(!eachObj[eachKey]){
                    return false;
                }
                let st = eachObj[eachKey]
                st = st+'';
                return st.match(new RegExp(filters[eachKey],'i'));
            });
        })
    }

}
