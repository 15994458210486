import {Injectable} from '@angular/core';
import { Http, Headers, Response, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'
const url = '/api/v1/';

@Injectable()
export class AccountsService {
    constructor(private http:Http){}


    getInvoiceList(token,searchString){
        return this.http.get(url+'accounting/invoice/list?token='+token + searchString)
            .map((response: Response) =>response.json());
    }
    getInvoiceDetail(token, invoiceId){
        return this.http.get(url + 'admin/invoice/' + invoiceId + '?token=' + token)
            .map((response: Response) =>response.json());
    }
    getTdsList(token){
        return this.http.get(url + 'accounting/tds/list?token=' + token)
            .map((response: Response) =>response.json());
    }
    getTdsPaymentList(token,month,year){
        return this.http.get(url + 'accounting/tds/month/payments?token=' + token+'&month='+month+'&year='+year)
            .map((response: Response) =>response.json());
    }
    deleteInvoice(token,invoiceId){
        return this.http.get(url + 'accounting/delete/invoice/'+invoiceId + '?token=' + token)
            .map((response: Response) =>response.json());
    }
    transporterPayments(token,body,searchString){
        return this.http.post(url + 'accounting/transporter/payments?token=' + token + searchString,body)
            .map((response: Response) =>response.json());
    }
    updatePaymentStatus(token,body){
        return this.http.post(url + 'accounting/transporter/update/payment?token=' + token,body)
            .map((response: Response) =>response.json());
    }
    getSundryDebtorsList(token,filter){
        return this.http.get(url+'accounting/sundry/debtors/list?token='+token + '&filter=' + filter.type)
            .map((response: Response) =>response.json());
    }
}
