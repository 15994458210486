import { Component,Input,Output,EventEmitter,ElementRef, SecurityContext,ViewChild} from '@angular/core';
import { AuthenticationService } from '../../user/services/authentication.services';
import { AppComponent } from '../../../app.component';
import { DomSanitizer} from '@angular/platform-browser'

@Component({
	moduleId : module.id,
	selector: 'user-profile',
	templateUrl: 'profile.component.html',
	styleUrls: ['profile.component.css']
})

export class ProfileComponent{
	@Input() openProfile: boolean;
	@Output() openProfileChange = new EventEmitter<boolean>();
	@Output() updatedUser = new EventEmitter<any>();
	@Output() showRequestTab = new EventEmitter<boolean>();
	
	editProfile: boolean = true;
	changePassword: boolean = false;
	imageURL: string = "";
	user: any={};
	password: any={};
	public showError: boolean = false;
	public success: boolean = false;
	public errorMessage: string = null;
	msg: any;
	errorMsg: any;
	currencies: any = ['USD','EUR','INR','CAD','HKD','MYR'];
	reportFormats: any = ['PDF','XLS'];
	pattern: any = '[0-9]{10,10}';
	patternForEmail: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	profileIcon = ".../../assets/images/profile.jpg";
	cancelIcon: any = '.../../assets/images/cancel-big-icon.png';
	constructor(
		public appComp: AppComponent,
		private authService: AuthenticationService,
		private domSanitizer: DomSanitizer
		){}

	hideModal(){
		this.openProfile = false;
		this.openProfileChange.emit(this.openProfile);
		this.appComp.cssClass = '';
		this.showRequestTab.emit(true);
	}

	openEditProfile(){
		this.editProfile = true;
		this.changePassword = false;
		this.appComp.cssClass = 'modal-open';
	}

	openChangePassword(){
		this.changePassword = true;
		this.editProfile = false;
	}

	back(){
		this.changePassword = false;
		this.editProfile = false;
	}

	profile(currentUser){
		this.showError = false;
		this.user = currentUser;
		this.imageURL = 'users/'+this.user._id+'/image?'+Math.random();				
	}

	changeOldPassword(){
		this.authService.changePassword(this.password).subscribe(
			success => {
				this.hideModal();
				this.msg=[];
				this.msg = [{severity: 'success',detail: 'Passowrd changed successfully'}]
			},
			error => {
				this.showError = true;
				this.errorMessage = error.json().message;				
			}
			
			);
	}

	updateUser() {
		console.log(this.user)
		this.authService.updateUser(this.user, this.user.token)
		.subscribe(
			user => {								
				this.openProfile = false;
				this.openProfileChange.emit(this.openProfile);
				this.user = user;
				this.updatedUser.emit(this.user);
				this.appComp.cssClass = '';
				this.msg = [];
				this.msg = [{severity: 'success',detail:'User has been updated successfully'}];
			},
			error => {
				console.log(error);
				this.errorMessage = error.json().message;
				this.errorMsg = [];
				this.errorMsg = [{severity:'error',detail: this.errorMessage}];
			}
			)
	}

	onUpload(event: any) {
		localStorage.setItem('currentUser', event.xhr.response);
		this.imageURL = 'users/'+this.user._id+'/image?'+Math.random();
	}	
	
}