import { Component, OnInit , ViewChild } from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
//import {} from 'googlemaps';
import {Message} from 'primeng/api';
import { forEach } from '@angular/router/src/utils/collection';
//import {} from 'googlemaps';
import { ListVehicleService } from './list-vehicle.service';

@Component({
  selector: 'app-list-vehicles',
  templateUrl: './list-vehicles.component.html',
  styleUrls: ['./list-vehicles.component.css']
})

export class ListVehiclesComponent implements OnInit {

    @ViewChild('map')  mapElement: any;
    map: google.maps.Map;


    lat:number = 28.7041;
    lng: number = 77.1025;

   
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    section: number = 1;

    vehicleTypeList = [];
    allVehicles: any = [];
    latFrom;
    lngFrom;
    latTo;
    lngTo;

    body= {
       from:null,
       to:null,
       vehicleType:null,
       latFrom:null,
       longFrom:null
    }

    constructor(private listVehicleService: ListVehicleService,
        private route: ActivatedRoute,
                private router: Router) {

    }

    vehicleListForm = new FormGroup({
        from: new FormControl(''),
        to: new FormControl(''),
        vehicle_type: new FormControl(''),
    });


    ngOnInit() {
        let that = this;
        let myLatLng = {lat: this.lat, lng: this.lng};
      
        const mapProperties = {
          center: new google.maps.LatLng(this.lat,this.lng),
          zoom: 8,
          mapTypeId: google.maps.MapTypeId.ROADMAP
          
     };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);


        this.vehicleTypes()      

        var from = <HTMLInputElement>document.getElementById('from');
        var to = <HTMLInputElement>document.getElementById('to');


        var options = {
            types: ['(cities)'],
            componentRestrictions: {country: 'in'}
        };

        let autocompleteFrom = new google.maps.places.Autocomplete(from, options);
        autocompleteFrom.addListener('place_changed', function () {
            let place = autocompleteFrom.getPlace();
            console.log(place);
           // that.vehicleListForm.get('from').setValue(place.address_components[0].long_name);
           that.body.from = place.address_components[0].long_name;
            that.body.latFrom = place.geometry.location.lat();
            that.body.longFrom = place.geometry.location.lng();


        })
        let autocompleteTo = new google.maps.places.Autocomplete(to, options);
        autocompleteTo.addListener('place_changed', function () {
            let place = autocompleteTo.getPlace();
            console.log(place);
            //that.vehicleListForm.get('to').setValue(place.address_components[0].long_name);
            that.body.to = place.address_components[0].long_name;
        })


        //  var autocompleteFrom = new google.maps.places.Autocomplete(from,options);
        //  let placeFrom = autocompleteFrom.getPlace();
        //
        // //  this.latFrom = placeFrom.geometry.location.lat();
        //  //this.lngFrom = placeFrom.geometry.location.lng();
        //
        //   var autocompleteTo = new google.maps.places.Autocomplete(to,options);
        //  let placeTo = autocompleteTo.getPlace();

        //  this.latTo = placeTo.geometry.location.lat();cls

        // this.lngTo = placeTo.geometry.location.lng();

    }


  vehicleTypes() {
    this.listVehicleService.getVehicleTypes().subscribe(
        res => {
            console.log(res);
            this.vehicleTypeList = res.data.vehicleOptions;
        }
    )
} 

search(){
this.listVehicleService.getVehicles(this.user.token,this.body).subscribe(
    res => {
        let that = this;
        console.log(res);
          this.section = 2;
          this.allVehicles = res.data;
          this.allVehicles.forEach(function (vehicle) {
            let marker = new google.maps.Marker({
                position: new google.maps.LatLng(parseFloat(vehicle.vehicleInfo.lat),parseFloat(vehicle.vehicleInfo.long)),
                icon: '../../../../assets/images/red-truck2.png',
                map: that.map,
                title: vehicle.transporter.name + ": " + vehicle.vehicleInfo.vehicleNumber + ":" +vehicle.price
                
            });

            var contentString =
            '<p><b>Transporter Name</b>: ' + vehicle.transporter.name + '</br>' +
            '<b>Vehicle Number</b>: ' + vehicle.vehicleInfo.vehicleNumber + '</br>' +
            '<b>Vehicle Price</b>: ' + vehicle.price + '</br>' +
            '</p>';
            var infowindow = new google.maps.InfoWindow({
             content: contentString
           });

            marker.addListener('click', function() {
             infowindow.open(this.map, marker);
           });
         });


    }
)

}


}
