import { Component, OnInit } from '@angular/core';
import {BsModalService} from 'ngx-bootstrap';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { LeadsService } from './leads.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})
export class LeadsComponent implements OnInit {

  newLead: any = {};
  currentLead: any = {};
  allLeads: any = [];
  user: any = JSON.parse(localStorage.getItem('currentUser'));
  userList: any;

  modalRef;
  successMsg: Message[] = [];

  allStatus: any = [
  	"Not Started",
	"Introductory Mail",
	"Meeting Stage",
	"RFQ Stage",
	"Converted",
	"Not Converted"
  ]

  months = ["January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"]

   constructor(
  		private modalService: BsModalService,
  		private router: Router,
        private route: ActivatedRoute,
        private ngbModalService: NgbModal,
        private leadService: LeadsService) 
   {this.newLead.task = {}}

  ngOnInit() {
  	this.listLeads();
  	this.getUserList();
  }

  addLead(){
		
		this.newLead.user = this.user._id;
		console.log(this.newLead);
		this.leadService.addLead(this.user.token, this.newLead).subscribe(
			res => {
				console.log(res);
				this.listLeads();
				this.newLead = {};
				this.successMsg.push({severity: 'success',summary:'Success',detail: "Leads added successfuly"});
				this.router.navigate(['leads']);
			}, err => {
		});
	}

	updateLead(leadIndex, status){
		
		let currentLead = this.allLeads[leadIndex];
		currentLead.lead_status = status;

		console.log(currentLead);
		this.leadService.updateLead(this.user.token, currentLead).subscribe(
			res => {
				console.log(res);
				this.successMsg.push({severity: 'success',summary:'Success',detail: "Leads updated successfuly"});
				this.listLeads();
				this.currentLead = {};
			}, err => {
		});
	}

	listLeads(){
	    this.leadService.listLeads(this.user.token).subscribe(
	      res => {
	        console.log(res);

	        this.allLeads = res.data;
	        let that = this;
	        _.each(this.allLeads, function(lead) {
	        	if(lead.tasks.length){
	        		var today = new Date();
	        		console.log(lead.tasks[lead.tasks.length-1].date, new Date(lead.tasks[lead.tasks.length-1].date))
	        		var date = new Date(lead.tasks[lead.tasks.length-1].date);
	        		var updatedDate = date.getDate() + " " + that.months[date.getMonth()] + " " + date.getFullYear();
	        		//today = today.getDate() + " " + that.months[today.getMonth()] + " " + today.getFullYear();
	        		console.log(updatedDate, today.getDate() + " " + that.months[today.getMonth()] + " " + today.getFullYear(), updatedDate == today.getDate() + " " + that.months[today.getMonth()] + " " + today.getFullYear())
	        		if(updatedDate == today.getDate() + " " + that.months[today.getMonth()] + " " + today.getFullYear()){
	        			lead.isToday = true;
	        			lead.priority = 1;
	        		}
	        		else{
	        			lead.priority = 2;
	        		}
	        		lead.next_action = lead.tasks[lead.tasks.length-1].subject + " at "+ updatedDate	
	        	}	        	
	        })
	        this.allLeads = _.sortBy(this.allLeads, function(lead) {return lead.priority});
	        console.log(this.allLeads);
	      },err => {
	      });
	}

	getCustomers() {
        this.leadService.getCustomers(this.user.token).subscribe(
            res => {
                console.log(res);
                //this.customerList = res.data;
            }, err => {
            });
    }

    editLead(leadId){
    	this.router.navigate(['leads/edit/', leadId]);
    }

    getUserList(){
	    this.leadService.listUsers(this.user.token).subscribe(
	      res => {
	        console.log(res);
	        this.userList = res.data;
	        this.userList = _.filter(this.userList, function(user){
	        	return user.role == 4 || user.role == 2
 	        })
 	        console.log(this.userList);
	      },err => {
	      });
	}
}
