import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform {
    transform(items: Array<any>, filter: any ): Array<any> {
        return items.filter(item => {
            return item.company === new RegExp(filter);
        })
    }
}