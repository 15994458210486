import {Component, Input,Directive, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AdmintransportersService } from './admintransporters.service';
// import { AppComponent } from '../../../app.component';
import * as _ from "lodash";
import { Message} from 'primeng/components/common/api';

@Component({
  selector: 'app-transporters',
  templateUrl: './transporters.component.html',
  styleUrls: ['./transporters.component.css']
})
export class TransportersComponent {

	user: any = JSON.parse(localStorage.getItem('currentUser'));
    cols: any[];
    
	userIndex: number = -1;
	public users: any = [];
	public roles: any = [];
	activeRoles: Array<any>  = [];
	showGroupTreeModal: boolean = false;
	openCreateUserModal: boolean = false;
	openUpdateUserModal: boolean = false;
	msg: any;
	errorMsg: any;
	errorMessage: string = null;
	confirmModel: boolean = false;
	currentUserIndex: number = -1;
	currencyName: any = ['USD','EUR','INR','CAD','HKD','MYR'];
	fileTypes: any = ['PDF','XLS'];
	pattern: any = '[0-9]{10,10}';
	patternForEmail: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	isSuperadmin: boolean = false;
	isAdmin: boolean = false;
	groupTree: any = [];
	canControl: boolean = false;
	selectedRoleObj: any={};
	response: boolean = false;
	confirmBox: boolean = false;
	userTodelete: any={};
	indexTodelete: number = -1;
	transporters: any = [];
	successMsg: Message[] = [];
	constructor(
		private router: Router,
		private service: AdmintransportersService
		){}

	slide: string = 'in';
	
	showRoleWrapper(){
		this.slide = "out";
	}
	displayDialog: boolean;

	roleIcon : string = '.../../../app/assets/images/roleIcon.png';
	addIcon: any = ".../../../app/assets/images/add.png";
	moreIcon : string = '.../../../app/assets/images/moreIcon.png';
	dividerIcon: any = ".../../../app/assets/images/divider.png";
	plusIcon: any = ".../../../app/assets/images/plusIcon.png";

	ngOnInit(){
		this.cols = [
            { field: 'SR.NO.', header: 'SR.NO.' },
            { field: 'Name', header: 'Name' },
            { field: 'PhoneNumber', header: 'Phone Number' },
            { field: 'Address', header: 'Address' },
            { field: 'NOOfBookings', header: 'NO. Of Bookings' }
        ];
        this.service.listTransporters(this.user.token).subscribe(
			res => {
				console.log('list transporters',res);
				let that = this;
				this.transporters = [];
				_.each(res.data, function(transporter, index){
					let temp_transporter = {};
					if(transporter.user){
						temp_transporter['SR.NO.'] = index+1;
						temp_transporter['id'] = transporter._id;
						temp_transporter['Name'] = transporter.user.company;
						temp_transporter['PhoneNumber'] = transporter.user.phone;
						temp_transporter['Address'] = transporter.address;
						temp_transporter['Status'] = transporter.isBlocked;
						temp_transporter['NOOfBookings'] = transporter.bookings || 0;
						that.transporters.push(temp_transporter);
					}
					
				})
				
				if (res.response) { 
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: res.message});
				} else {
					this.successMsg = [];
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
				}
			},err => {
			}
			)
	}
    showDetails(data) {
        console.log('row data',data);
        this.router.navigate(['admin/transporters/'+data.id+'/details']);
    }
}
