import {Component, ElementRef, EventEmitter, Output, Renderer, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NewRequestService} from './new-request.service';
import {BsModalRef} from 'ngx-bootstrap';


@Component({
    selector: 'app-vehicle-type',
    templateUrl: './vehicle-type-modal.component.html',
    styleUrls: ['./vehicle-type-modal.component.css']
})


export class VehicleTypeModalComponent {


    truckArray = [];
    truckCategoryArray = [];
    public event: EventEmitter<any> = new EventEmitter();
    modalRef: BsModalRef;
    truckTypeSelected;
    truckLabel;
constructor(private modalService: NgbModal, private service: NewRequestService, public bsModalRef: BsModalRef){

}

ngOnInit(){
    this.service.getVehicles().subscribe(
            res => {
                this.truckArray = res.data.vehicleOptions;
                console.log(this.truckArray);
            },err => {
            }
        )

}

truckType(category){
    this.truckTypeSelected = category;
    this.truckCategoryArray = this.truckArray.filter(item =>{
        return item.id == category;
    })

    console.log(this.truckCategoryArray);
}

    truckSelected(truck){
    this.truckLabel = truck;
    this.event.emit(truck);
        this.bsModalRef.hide();

    }
}