import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { AuthenticationService } from '../services/authentication.services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [AuthenticationService,MessageService]
})
export class ResetPasswordComponent implements OnInit {

  constructor(private router:Router,private messageService: MessageService,
  			private authenticationService: AuthenticationService) { }
  user: any = {};
  successMsg:  Message[] = [];
  ngOnInit() {
  }
  resetPassword() {
  	this.successMsg = [];
	this.successMsg.push({severity: 'success',summary:'Success Message',detail: 'Password Reset successfully'});
	this.router.navigate(['/signin']);
  }

}
