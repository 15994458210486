import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ProjectionService } from './projection.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-projection',
  templateUrl: './projection.component.html',
  styleUrls: ['./projection.component.css']
})
export class ProjectionComponent implements OnInit {

  	constructor(
  		private modalService: BsModalService,
  		private router: Router,
        private route: ActivatedRoute,
        private ngbModalService: NgbModal,
        private projectionService: ProjectionService
    ){ }

	groups = [];
	rows = [];

	user: any = JSON.parse(localStorage.getItem('currentUser'));
	modalRef;
  	successMsg: Message[] = [];

	projections: any = [];
	newProjection: any = {};
	customerList: any = [];
	currentProjection: any = {};

	@ViewChild('myTable') table: any;


	enableSummary = true;
	summaryPosition = 'bottom';
	loadingIndicator: boolean = true;

	months = ["January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"]

	columns = [
	    { prop: 'modified_month', summaryFunc: null, },
	    { name: 'customer_name',summaryFunc: null, },
	    { prop: 'projected_value', summaryFunc: (cells) => this.totalValue(cells) },
	   	{ name: 'percentage',summaryFunc: null, },
	    { prop: 'margin_value', summaryFunc: (cells) => this.totalValue(cells) },
	];


	ngOnInit() {
		this.getProjections();
		this.getCustomers();
	}

	openAddProjectionModal(template: TemplateRef<any>){
		this.modalRef = this.modalService.show(
		template,
		Object.assign({}, { class: 'gray modal-md' })
		);
	}

	openEditProjectionModal(template: TemplateRef<any>, projection){
		console.log(projection);
		this.currentProjection = projection;
		this.modalRef = this.modalService.show(
		template,
		Object.assign({}, { class: 'gray modal-md' })
		);
	}

	addProjection(newProjection){
		
		this.newProjection.user = this.user._id;
		console.log(this.newProjection, newProjection);
		this.projectionService.addProjection(this.user.token, this.newProjection).subscribe(
			res => {
				console.log(res);
				this.getProjections();
				this.newProjection = {};
				this.modalRef.hide();
			}, err => {
		});
	}

	updateProjection(currentProjection){
		
		this.currentProjection.user = this.user._id;
		console.log(this.currentProjection, currentProjection);
		this.projectionService.updateProjection(this.user.token, this.currentProjection).subscribe(
			res => {
				console.log(res);
				this.modalRef.hide();
				this.getProjections();				
			}, err => {
		});
	}

	getProjections(){
	    this.projectionService.listProjections(this.user.token).subscribe(
	      res => {
	        console.log(res);

	        this.projections = res.data;
	       
	        for(var i = 0 ; i < this.projections.length; i++) {
	        	let temp_obj = {};
	        	temp_obj = this.projections[i];
	        	temp_obj['modified_month'] = this.months[(new Date(this.projections[i].month)).getMonth()];
	        	temp_obj['projected_value'] = this.projections[i]['projection_value'];
	        	temp_obj['customer_name'] = this.projections[i].customer.company;
	        	temp_obj['percentage'] = this.projections[i].margin_percentage;
	        	temp_obj['margin_value'] = (this.projections[i].projection_value*this.projections[i].margin_percentage)/100;
	        	this.rows.push(temp_obj);
	        	//data.margin_value = (this.projections[i].projection_value*this.projections[i].margin_percentage)/100;
	        }
	        console.log(this.rows);
	        this.currentProjection = {};


	      },err => {
	      });
	}

	getCustomers() {
        this.projectionService.getCustomers(this.user.token).subscribe(
            res => {
                console.log(res);
                this.customerList = res.data;
            }, err => {
            });
    }

    private totalValue(cells: number[]) {
	    const filteredCells = cells.filter(cell => !!cell);
	    return filteredCells.reduce((sum, cell) => sum += cell, 0);
	}

	toggleExpandGroup(group) {
		console.log('Toggled Expand Group!', group);
		this.table.groupHeader.toggleExpandGroup(group);
	}  

	onDetailToggle(event) {
		console.log('Detail Toggled', event);
	}
}
