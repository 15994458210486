import {Component} from '@angular/core';
import {AccountsService} from '../accounts.service';

@Component({
    selector:'sundry-debtors',
    templateUrl:'./sundry-debtors.component.html'
})
export class SundryDebtorsComponent {
    user: any = JSON.parse(localStorage.getItem('currentUser'));
    list =  []
    filter = {
        type:'order_date'
    }
    constructor(private service:AccountsService){

    }

    ngOnInit(){
        this.sundryDebtors();
    }
    filterChange(){
        this.sundryDebtors();
    }



    sundryDebtors(){
        this.service.getSundryDebtorsList(this.user.token,this.filter).subscribe(res=>{
            if(res.response){
                this.list = res.data;
                console.log(res.data);
            }
        });
    }
}
