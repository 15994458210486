import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {OrderHistoryService} from './order-history.service';
import {Subject} from 'rxjs';

@Component({
    templateUrl:'./update-vehicle-status.component.html'
})

export class UpdateVehicleStatusComponent {

    user: any = JSON.parse(localStorage.getItem('currentUser'));
    misId;
    public onClose: Subject<Object>;
    body = {
        date:new Date(),
        status:null
    }
    currentDate = new Date();
    constructor(private _bsModalRef: BsModalRef,private service:OrderHistoryService){

    }
    ngOnInit(){
        this.onClose = new Subject();
    }
    cancel() {
        this._bsModalRef.hide();
    }

    vehicleStatus(){
      this.service.updateVehicleStatus(this.user.token,this.misId,this.body).subscribe(
          res =>{
              console.log(res);
              this.onClose.next({date:this.body.date,status:this.body.status,message:'Vehicle Status updated'})
              this._bsModalRef.hide();

          },error1 => {
              this.onClose.next({message:'Vehicle status not updated'})
              console.log(error1);

          }
      )
    }
}
