import { Component, OnInit } from '@angular/core';
import {BsModalService} from 'ngx-bootstrap';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { LeadsService } from '../leads.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-lead',
  templateUrl: './edit-lead.component.html',
  styleUrls: ['./edit-lead.component.css']
})
export class EditLeadComponent implements OnInit {

  newLead: any = {};
  currentLead: any = {};
  allLeads: any = [];
  user: any = JSON.parse(localStorage.getItem('currentUser'));
  leadId: any;
  modalRef;
  successMsg: Message[] = [];
  newTask: any = {};

  allStatus: any = [
  	"Not Started",
	"Introductory Mail",
	"Meeting Stage",
	"RFQ Stage",
	"Converted",
	"Not Converted"
  ]

   constructor(
  		private modalService: BsModalService,
  		private router: Router,
        private route: ActivatedRoute,
        private ngbModalService: NgbModal,
        private leadService: LeadsService) 
   {}

  ngOnInit() {
  	this.leadId = this.route.snapshot.paramMap.get('id');
  	this.leadService.getLead(this.user.token, this.leadId).subscribe(
			res => {
				console.log(res);
				this.currentLead = res.data;
			}, err => {
		});
  }

	updateLead(){
		if(this.newTask && this.newTask.subject){
			this.currentLead.tasks.push(this.newTask);
		}
		this.currentLead.user = this.user._id;
		console.log(this.currentLead);
		this.leadService.updateLead(this.user.token, this.currentLead).subscribe(
			res => {
				console.log(res);
				this.successMsg.push({severity: 'success',summary:'Success',detail: "Leads updated successfuly"});
				this.currentLead = {};
				this.router.navigate(['leads']);
			}, err => {
		});
	}

	getCustomers() {
        this.leadService.getCustomers(this.user.token).subscribe(
            res => {
                console.log(res);
                //this.customerList = res.data;
            }, err => {
            });
    }

}
