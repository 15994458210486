import { Directive, Attribute   } from '@angular/core';
import { NG_VALIDATORS,Validator,
         Validators,AbstractControl,ValidatorFn } from '@angular/forms';

@Directive({
  selector:'[appValidateEqual][formControlName],[appValidateEqual][formControl],[appValidateEqual][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidateEqualDirective, multi: true}]
})
export class ValidateEqualDirective {

  constructor(@Attribute('appValidateEqual') public validateEqual: string) { 
    console.log(".../directive");
  }
  	 validate(c: AbstractControl): { [key: string]: any } {
        // self value (e.g. retype password)
        let v = c.value;
 
        // control value (e.g. password)
        let e = c.root.get(this.validateEqual);
 
        // value not equal
        console.log("e,v",e,v);
        if (e && v && v !== e.value)  return {"compare": true};
        return null;
    }

}
