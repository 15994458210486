import {Component} from '@angular/core';
import {Message} from 'primeng/api';

@Component({
    selector:'customer-payments',
    templateUrl:'./customer-payments.component.html'
})
export class CustomerPaymentsComponent {
    successMsg: Message[] = [];
    constructor(){

    }

    ngOnInit(){

    }

    showResponse(response){
        this.successMsg = [];
        this.successMsg.push(response);
    }

}
