import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message} from 'primeng/components/common/api';
import { MessageService} from 'primeng/components/common/messageservice';
import { UserAddressService} from './user-address.service';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.css']
})
export class UserAddressComponent implements OnInit {

  constructor(private service: UserAddressService,
    private route: ActivatedRoute,
    private router: Router) { 
    this.route.queryParams.subscribe(
      res =>{
        this.detailData.query_id = res.query_id;
        this.token = res.token;
      },err =>{
        console.log('err',err);
      }
      )  
  }

  unloading = {}
  isShowAddressForm: Boolean = true;
  user: any = JSON.parse(localStorage.getItem('currentUser'));
  token: any;
  queryData: any = {};
  successMsg: Message[] = [];
  detailData: any = {
  	query_id: '',
    loading_address:'',
    loading_contact_number:'',
    unloading_address:'',
    unloading_contact_number:'',
  };

  ngOnInit() {
  	this.service.getQuery(this.token,{query_id: this.detailData.query_id}).subscribe(
      res => {
        let query = res.data;
        console.log('query',query);
        if (query) { 
          this.queryData = {
            from: query.from,
            to: query.to,
            vehicle_type:query.vehicle_type,
            date: query.date,
            no_of_vehicle:query.no_of_vehicle,
            
          }
          console.log('trans vehicle detail', this.queryData);
        }

        if(!res.response){
          this.successMsg = [];
          this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
        }
      },err => {
        console.log('err',err);
      }
      )
  }
  submitData(){
    this.service.getUserAddress(this.token,this.detailData).subscribe(
      res => {
        console.log('vehicle response',res);
        if(!res.response){
          this.isShowAddressForm = false;
          this.successMsg = [];
          this.successMsg.push({severity: 'error',summary:'Error Message',detail: res.message});
        }else{
          this.detailData.on_load_address= '';
          this.detailData.off_load_address = '';
          this.detailData.contact_number = '';
          this.successMsg = [];
          this.successMsg.push({severity: 'success',summary:'Success Message',detail: "Thanks for your response, we will get back to you shortly."});
          let that = this;
          setTimeout(function(){
            that.router.navigate(['/bookings']);    
          }, 2000);
        }
      },err => {
        console.log('err',err);
      }
      )
  }

}
