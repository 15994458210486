import { Component, OnInit} from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators,ValidatorFn,NG_VALIDATORS } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.services';
import { ValidateEqualDirective }  from '../../../common/directives/validate-equal.directive';
import * as _ from "lodash";
import {Message} from 'primeng/components/common/api';
import {MessageService} from 'primeng/components/common/messageservice';
// import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty';

@Component({
	moduleId: module.id,
	selector: "signup-app",	
	templateUrl: "signup.component.html",
	styleUrls: ["signup.component.css"],
	providers: [MessageService]
})

export class SignupComponent{
	public user: any = {};
	public errorMessage: string = null;
	public showError: boolean = false;
	// public successMsgs: string = null;
	// public showSuccess: boolean = false;
	public isOtpTabOpen: boolean = false;
	successMsg:  Message[] = [];
	errorMsg:  Message[] = [];
	email = new FormControl('', [Validators.required, Validators.email])
	
	constructor(       
		private authenticationService: AuthenticationService,
		private route: ActivatedRoute,
		private router: Router,
		private messageService: MessageService
		
	) {
		
	 }
	ngOnInit() {
		
		console.log("inside signup");
		
	}  
	getErrorMessageForEmail() {
		return this.email.hasError('email') ? 'Not a valid email' : '';
	}	

	getOTP(phone: string) {
		if(!this.user.authcode){
			this.authenticationService.getOTP(this.user.phone)
			.subscribe(
				res => {
					console.log(res);
					if(!res.response && res.message == "User already exists"){
						this.showError = true;
						this.errorMsg.push({severity: 'error',summary:'Error Message',detail: "User account already exists"});
					}
					else{
						this.isOtpTabOpen = true;					
					}
				},
				error => {
					console.log(error.json() , typeof error._body);
					this.errorMessage = error.json().message;
					// this.showError = true;
					this.errorMsg.push({severity: 'success',summary:'Success Message',detail: this.errorMessage});
				}
			);
		}
		
	}
	
	signup() {
		console.log("...this.user",this.user);
		this.user.authcode = this.user.authcode.toString();
		this.authenticationService.signup(this.user)
		.subscribe(
			user => {
				console.log(user.message);
				if (user.response) {
					this.successMsg = [];
					this.successMsg.push({severity: 'success',summary:'Success Message',detail: user.message});
					setTimeout( ()=> {
						this.router.navigate(['/signin']);
					},2000)
				}else{
					this.successMsg.push({severity: 'error',summary:'Error Message',detail: user.message});
					this.isOtpTabOpen = false;
				}
			},
			error => {
				console.log(error.json() , typeof error._body);
				this.successMsg = [];
				this.errorMessage = error.json().message;
				this.successMsg.push({severity: 'error',summary:'Error Message',detail: this.errorMessage});
				
				// this.showError = true;
			});
	}
}